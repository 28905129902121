import produce from 'immer';
import {
  CREATE_LAYOUT,
  DELETE_LAYOUT,
  COPY_LAYOUT,
} from '../layoutItemActions/layoutMapActions';
import {
  REORDER_LAYOUT_TABS,
  ADD_LAYOUT_TAB,
  REMOVE_LAYOUT_TAB,
} from '../layoutItemActions/layoutTabActions';



function ensureActiveLayoutTabExists(draft) {
  if (draft.activeLayout && !draft.layoutTabs.includes(draft.activeLayout)) {
    draft.layoutTabs.push(draft.activeLayout);
  }
}


function addLayoutTab(draft, newId) {
  if (!(newId in draft.layouts)) {
    return console.warn('Layout should already exists', newId);
  }
  if (draft.layoutTabs.includes(newId)) {
    return;
  }
  draft.layoutTabs.push(newId);

  // Set a default in case of an issue
  ensureActiveLayoutTabExists(draft);
}


function deleteTabAfterLayout(draft, layoutId) {
  const layoutTabIdx = draft.layoutTabs.findIndex(tabId => tabId === layoutId);
  if (layoutTabIdx !== -1) {
    draft.layoutTabs.splice(layoutTabIdx, 1);
  }

  // handle the case where profileMap has set activeLayout to a default,
  // but layoutTabs do not contain it
  ensureActiveLayoutTabExists(draft);
}


function removeLayoutTab(draft, layoutId) {
  if (!draft?.layouts?.[layoutId]) return;

  const tabIndex = draft.layoutTabs.findIndex(t => t === layoutId);
  if (tabIndex === -1) return;
  if (draft.layoutTabs.length === 1) return;


  draft.layoutTabs.splice(tabIndex, 1);

  // If we removed a tab that wasn't active, no need to pick a new one
  if (draft.activeLayout !== layoutId) {
    return;
  }

  // We just closed the active layout, let's pick a new one
  // Try the tab at the same index (which was previously the "right" neighbor)
  let newActiveLayout = draft.layoutTabs[tabIndex];

  // If there's no tab at tabIndex (out of range), try the left neighbor
  if (!newActiveLayout && tabIndex - 1 >= 0) {
    newActiveLayout = draft.layoutTabs[tabIndex - 1];
  }

  // If we still don't have a new layout, try the first layout in layouts
  if (!newActiveLayout) {
    const layoutKeys = Object.keys(draft.layouts);
    newActiveLayout = layoutKeys.length ? layoutKeys[0] : null;
  }

  draft.activeLayout = newActiveLayout;
}


function reorderLayoutTabs(draft, layoutOrderIds) {
  if (layoutOrderIds) {
    draft.layoutTabs = layoutOrderIds;
  }
}



const handlers = {
  [ADD_LAYOUT_TAB]: (draft, action) => addLayoutTab(draft, action.payload?.layoutId),
  [REMOVE_LAYOUT_TAB]: (draft, action) => removeLayoutTab(draft, action.payload?.layoutId),
  [REORDER_LAYOUT_TABS]: (draft, action) => reorderLayoutTabs(draft, action.payload?.layoutOrderIds),
  [CREATE_LAYOUT]: (draft, action) => addLayoutTab(draft, action.payload?.newId),
  [COPY_LAYOUT]: (draft, action) => addLayoutTab(draft, action.payload?.newId),
  [DELETE_LAYOUT]: deleteTabAfterLayout,
}


/** 
 * @type {LayoutReducer} 
 */
export function layoutTabReducer(state, action, schemaApi) {
  const handler = handlers[action.type];
  if (!handler) return state;

  return produce(state, draft => handler(draft, action, schemaApi));
}
