import {
  ButtonBase,
  makeStyles,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback, useContext } from 'react';
import { MosaicWindowContext } from 'react-mosaic-component';
import MarketingModalComponentUpgrade from 'src/app/components/MarketingModal/MarketingModalComponentsUpgrade';
import ConditionalWrapper from 'src/app/components/utility/ConditionalWrapper';
import ContainedModal from 'src/app/components/utility/ContainedModal';
import DarkTooltip from 'src/app/components/utility/DarkTooltip';
import useUserPlanPermissions from 'src/hooks/useUserPlanPermissions';
import { useLayoutNamespaceConfig, useLayoutSelector } from 'src/redux/layoutV2/base/layoutContext';
import { COMPONENT_TYPES } from 'src/redux/layoutV2/base/layoutSchema';
import { selectActiveLayoutComponentTypeCount } from 'src/redux/layoutV2/base/layoutSelectors';
import MosaicPanel from './MosaicPanel';
import MosaicPanelBody from './MosaicPanelBody';
import MosaicPanelHeader from './MosaicPanelHeader/MosaicPanelHeader';

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    backgroundColor: theme.palette.background.fixedTableHeader,
    color: theme.palette.text.primary,
    padding: '10px 5px',
    borderRadius: theme.grid.borderRadius,
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.paperAlt,
    },
    '& .MuiSvgIcon-root': {
      margin: 0,
    },
    '& p': {
      marginLeft: theme.spacing(.5),
    }
  },
  disabled: {
    '& $button': {
      opacity: 0.5,
      cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: theme.palette.background.fixedTableHeader,
      },
    }
  },
  tooltipLink: {
    fontSize: '13px !important',
    color: theme.palette.text.primary
  },
  gridRestrict: {
    maxWidth: 390,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  scroll: {
    height: '100%',
    overflowY: 'auto',
  },
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: -theme.spacing(1)
  },
  gridItem: {
    flexBasis: '50%',
    minWidth: 156,
    padding: theme.spacing(1),
    flex: 1
  },
  gridItemFullWidth: {
    flexBasis: '100% !important'
  }
}));


// function UpgradeAccountTooltip({ componentTitle, limit, children }) {
//   const classes = useStyles();
//   return (
//     <DarkTooltip
//       interactive
//       enterDelay={0}
//       leaveDelay={300}
//       title={(
//         <Link
//           to="/account/subscription/update"
//           component={RouterLink}
//           className={classes.tooltipLink}
//           underline="none"
//         >
//           Limit of {limit} {componentTitle} component per layout.<br />
//           <u>Upgrade your account</u> to increase.
//         </Link>
//       )}
//     >
//       {children}
//     </DarkTooltip>
//   );
// }


function DefaultMaxReachedTooltip({ componentTitle, children }) {
  const classes = useStyles();
  return (
    <DarkTooltip
      enterDelay={0}
      leaveDelay={0}
      title={(
        <Typography variant="body1" className={classes.tooltipLink}>
          Maximum {componentTitle} components reached<br />
        </Typography>
      )}
    >
      {children}
    </DarkTooltip>
  );
}


const permissions = [
  'toplist_layout_max_scanners',
  'toplist_layout_max_watchlists',
  'toplist_layout_max_charts',
  'toplist_layout_max__has_upgrade',
  'toplist_layout_default_max_component',
]

const emptyObj = {};


function ComponentSelectPanel({
  className,
  panelHeaderProps,
  onComponentSelectOverride,
  availableComponents,
}) {
  const classes = useStyles();
  const mosaicWindowContext = useContext(MosaicWindowContext);
  const {
    componentMap,
  } = useLayoutNamespaceConfig();
  const {
    toplist_layout_max_scanners,
    toplist_layout_max_watchlists,
    toplist_layout_max_charts,
    toplist_layout_max__has_upgrade,
    toplist_layout_default_max_component,
  } = useUserPlanPermissions(permissions);
  const componentTypeMaxAllowed = {
    [COMPONENT_TYPES.SCANNER]: toplist_layout_max_scanners,
    [COMPONENT_TYPES.CHART]: toplist_layout_max_charts,
    [COMPONENT_TYPES.WATCHLIST]: toplist_layout_max_watchlists
  };
  const componentTypeCount = useLayoutSelector(selectActiveLayoutComponentTypeCount);
  const [upgradeModalProps, setUpgradeModalProps] = React.useState(null);
  const [upgradeModalOpen, setUpgradeModalOpen] = React.useState(false);

  const openUpgradeModalWithProps = useCallback((props = emptyObj) => {
    setUpgradeModalProps(props);
    setUpgradeModalOpen(true);
  }, []);

  const closeUpgradeModal = useCallback(() => {
    setUpgradeModalOpen(false);
  }, []);


  return (
    <MosaicPanel className={className}>

      <MosaicPanelHeader
        loading={false}
        title="Select Component"
        showLinkingDropdownMenu={false}
        {...panelHeaderProps}
      />
      <MosaicPanelBody
        loading={false}
        className={clsx(classes.scroll, 'ett-mosaic-scrollbar')}
      >
        <div className={classes.gridRestrict}>
          <div className={classes.gridContainer}>
            {availableComponents.map(type => {
              const info = componentMap[type];
              const Icon = info.Icon;
              const disabledMaxReachedForPlan = (componentTypeCount?.[type] >= componentTypeMaxAllowed?.[type]) || false;
              const disabledMaxReachedDefault = (componentTypeCount?.[type] >= toplist_layout_default_max_component) || false;
              const showUpgradeModal = disabledMaxReachedForPlan && toplist_layout_max__has_upgrade;
              const showDefaultMaxTooltip = (disabledMaxReachedForPlan || disabledMaxReachedDefault) && !showUpgradeModal;

              const handleAddComponent = () => {
                if (showDefaultMaxTooltip || showUpgradeModal) return;
                const args = { type, overrides: { title: info.title } };
                onComponentSelectOverride
                  ? onComponentSelectOverride(args)
                  : mosaicWindowContext && mosaicWindowContext.mosaicWindowActions.replaceWithNew(args);
              };

              const handleOpenModal = () => {
                openUpgradeModalWithProps({
                  componentName: info.title,
                  componentType: type,
                  componentLimit: componentTypeMaxAllowed[type],
                  ComponentIcon: Icon,
                  suggestedPlanLevel: toplist_layout_max__has_upgrade
                });
              }

              return (
                <ConditionalWrapper
                  key={type}
                  condition={showDefaultMaxTooltip}
                  wrapper={(children) => <DefaultMaxReachedTooltip componentTitle={info.title}>{children}</DefaultMaxReachedTooltip>}
                >
                  <div
                    key={type}
                    className={clsx(
                      classes.gridItem,
                      info?.fullwidth && classes.gridItemFullWidth,
                      showDefaultMaxTooltip && classes.disabled
                    )}
                  >
                    <ButtonBase
                      role="button"
                      className={classes.button}
                      aria-label="Add Component"
                      aria-disabled={showDefaultMaxTooltip}
                      onClick={showUpgradeModal
                        ? () => handleOpenModal()
                        : () => handleAddComponent(info.title)}
                    >
                      <Icon />
                      <Typography>{info.title}</Typography>
                    </ButtonBase>
                  </div>
                </ConditionalWrapper>
              );
            })}
          </div>
        </div>
      </MosaicPanelBody>

      <ContainedModal
        disablePortal={false}
        open={upgradeModalOpen}
        width={450}
        sharpBorder
        blur
        onClose={closeUpgradeModal}
      >
        {Boolean(upgradeModalProps) && (
          <MarketingModalComponentUpgrade
            {...upgradeModalProps}
            onClose={closeUpgradeModal}
          />
        )}
      </ContainedModal>

    </MosaicPanel >
  );
}

export default ComponentSelectPanel;
