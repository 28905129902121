// import Scanner from 'src/app/layout/Scanner';
// import Chart from 'src/app/layout/Chart';
// import Keystats from 'src/app/layout/KeyStats';
// import News from 'src/app/layout/News';
// import Watchlist from 'src/app/layout/Watchlist';
// import Historical from 'src/app/layout/Historical';
// import PlaceholderComponent from 'src/app/layout/shared/PlaceholderComponent';
// import ComponentSelectPanel from 'src/app/layout/shared/ComponentSelectPanel';
import {
  NewsIcon,
  TopListsIcon,
  LayoutIcon,
  HistoryIcon,
  GraphsidewaysIcon,
  OverviewIcon,
  WatchlistIcon
} from 'src/theme/EdgeIcons';
import { TRACKER_ACTIONS } from 'src/app/components/grid/topListScanner/dataSource/dataSource';
import { CLIENT_IDS, DEFAULT_LAYOUT_ID, DEFAULT_TEMPLATE_ID } from 'src/app/ChartingLibrary/constants';
import {
  LINK_COLORS,
  COMPONENT_TYPES,
  CHART_DATE_INITIALIZATION_TYPES,
  WATCHLIST_MANUAL_ORDER,
  defaultNewsCategories,
  newsOnlyNewsCategories,
  secOnlyNewsCategories,
  SchemaApi
} from '../base/layoutSchema';
import { PROFILE_CONFIG } from './profileConfig';
import { EXPRESSION_NAMESPACE } from '../base/layoutExpressionConfig';
import { lazy } from 'react';


/**  @typedef {import('../base/layoutSchema.js').ComponentMapItem} ComponentMapItem */
/**  @typedef {import('../base/layoutSchema.js').Layout} Layout */
/**  @typedef {import('../base/layoutSchema.js').MosaicNode} MosaicNode */


export const TOPLIST_NAMESPACE = 'toplist';


/**
 * List of which COMPONENT_TYPES are available for users to select
 *
 * @readonly
 * @type {COMPONENT_TYPES[]}
 **/
export const SELECT_PANEL_COMPONENTS = [
  COMPONENT_TYPES.SCANNER,
  COMPONENT_TYPES.WATCHLIST,
  COMPONENT_TYPES.NEWS,
  COMPONENT_TYPES.KEYSTATS,
  COMPONENT_TYPES.HISTORICAL,
  COMPONENT_TYPES.CHART,
  // COMPONENT_TYPES.DILUTION
];


/**
 * @readonly
 * @type {{string: ComponentMapItem}} COMPONENT_MAP
 */
export const COMPONENT_MAP = {
  [COMPONENT_TYPES.SELECT]: {
    title: 'Select Component',
    Component: lazy(() => import('src/app/layout/shared/ComponentSelectPanel')),
    availableComponents: SELECT_PANEL_COMPONENTS
  },
  [COMPONENT_TYPES.SCANNER]: {
    title: 'Scanner',
    Icon: TopListsIcon,
    expressionContextKey: EXPRESSION_NAMESPACE.REALTIME,
    profileConfigMeta: {
      filterProfileConfig: PROFILE_CONFIG.SCANNER_FILTERS,
      columnProfileConfig: PROFILE_CONFIG.SCANNER_COLUMNS,
    },
    intercom: {
      test: 9731176,
      prod: 9734004,
    },
    Component: lazy(() => import('src/app/layout/Scanner')),
  },
  [COMPONENT_TYPES.WATCHLIST]: {
    title: 'Watchlist',
    Icon: WatchlistIcon,
    expressionContextKey: EXPRESSION_NAMESPACE.REALTIME,
    profileConfigMeta: {
      watchlistProfileConfig: PROFILE_CONFIG.WATCHLIST_ROWS,
      columnProfileConfig: PROFILE_CONFIG.SCANNER_COLUMNS,
    },
    intercom: {
      test: 9757263,
      prod: 9726518,
    },
    Component: lazy(() => import('src/app/layout/Watchlist'))
  },
  [COMPONENT_TYPES.NEWS]: {
    title: 'News',
    Icon: NewsIcon,
    expressionContextKey: EXPRESSION_NAMESPACE.REALTIME,
    profileConfigMeta: {
      filterProfileConfig: PROFILE_CONFIG.SCANNER_FILTERS,
      columnProfileConfig: PROFILE_CONFIG.NEWS_COLUMNS,
    },
    intercom: {
      test: 9757266,
      prod: 9726414,
    },
    Component: lazy(() => import('src/app/layout/News/TopListNews')),
    noLinkAllowed: true
  },
  [COMPONENT_TYPES.KEYSTATS]: {
    title: 'Key Stats',
    Icon: OverviewIcon,
    profileConfigMeta: {
      keystatsProfileConfig: PROFILE_CONFIG.KEY_STATS
    },
    intercom: {
      test: 9757261,
      prod: 9726502,
    },
    Component: lazy(() => import('src/app/layout/KeyStats')),
  },
  [COMPONENT_TYPES.HISTORICAL]: {
    title: 'Ticker Stats',
    Icon: HistoryIcon,
    expressionContextKey: EXPRESSION_NAMESPACE.HISTORY_COMP,
    profileConfigMeta: {
      filterProfileConfig: PROFILE_CONFIG.HISTORY_FILTERS,
      columnProfileConfig: PROFILE_CONFIG.HISTORY_COLUMNS,
      aggregateProfileConfig: PROFILE_CONFIG.HISTORY_AGGREGATES
    },
    intercom: {
      test: 9757262,
      prod: 9731071,
    },
    Component: lazy(() => import('src/app/layout/Historical')),
  },
  [COMPONENT_TYPES.CHART]: {
    title: 'Chart',
    Icon: GraphsidewaysIcon,
    intercom: {
      test: 9757260,
      prod: 9728665,
    },
    Component: lazy(() => import('src/app/layout/connectors/TopListChartConnector')),
  },
};




export const LAYOUT_TEMPLATES = [
  {
    id: 'Default',
    name: 'Default',
  },
  {
    id: 'news',
    name: 'News',
  },
  {
    id: 'overview',
    name: 'Overview',
  }
];




class ToplistLayoutSchemaApi extends SchemaApi {

  /**
   * Create an individual component
   * @param {keyof COMPONENT_TYPES} type
   * @param {object} overrides
   * @returns {{component: object}}
   **/
  _createComponent = (type, overrides = {}) => {
    let component = {};

    switch (type) {
      case COMPONENT_TYPES.SELECT: {
        component = {};
        break;
      }
      case COMPONENT_TYPES.SCANNER: {
        component = {
          order: 'desc',
          orderby: 'session_chg_p',
          audioEnabled: false,
          trackerOpen: false,
          scannerHeight: 50,
          trackerHeight: 50,
          trackerOptions: { [TRACKER_ACTIONS.added]: true, [TRACKER_ACTIONS.removed]: false },
          ...this.addDefaultComponentProfileIds(type, [
            'filterProfileConfig',
            'columnProfileConfig'
          ])
        };
        break;
      }
      case COMPONENT_TYPES.NEWS: {
        component = {
          categories: defaultNewsCategories,
          link: LINK_COLORS.none.name,
          searchKeywords: [],
          linkedStateClearedAt: new Date().getTime(),
          ...this.addDefaultComponentProfileIds(type, [
            'filterProfileConfig',
            'columnProfileConfig'
          ])
        };
        break;
      }
      case COMPONENT_TYPES.KEYSTATS: {
        component = {
          financialsPeriod: 'quarterly',
          [PROFILE_CONFIG.KEY_STATS.idKey]: PROFILE_CONFIG.KEY_STATS.defaultProfileId,
          ...this.addDefaultComponentProfileIds(type, [
            'keystatsProfileConfig',
          ])
        };
        break;
      }
      case COMPONENT_TYPES.HISTORICAL: {
        component = {
          currentTab: "stats",
          recordsOrder: 'desc',
          recordsOrderby: 'day0_date',
          ...this.addDefaultComponentProfileIds(type, [
            'filterProfileConfig',
            'columnProfileConfig',
            'aggregateProfileConfig',
          ])
        };
        break;
      }
      case COMPONENT_TYPES.CHART: {
        component = {
          selectedTemplateId: DEFAULT_TEMPLATE_ID,
          interval: '1D',
          dateInitializationType: CHART_DATE_INITIALIZATION_TYPES.LINKED_DATE
        };
        break;
      }
      case COMPONENT_TYPES.WATCHLIST: {
        component = {
          order: 'desc',
          orderby: WATCHLIST_MANUAL_ORDER,
          ...this.addDefaultComponentProfileIds(type, [
            'watchlistProfileConfig',
            'columnProfileConfig'
          ])
        };
        break;
      }
      case COMPONENT_TYPES.DILUTION: {
        component = {
          title: 'DILUTION'
        };
        break;
      }
      default: {
        throw Error('Invalid component type');
      }
    }

    return {
      component: {
        type,
        link: LINK_COLORS.white.name,
        ...component,
        ...overrides
      }
    };

  }

  /**
   * Create a new layout
   * @param {string} [templateId]
   * @param {object} overrides
   * @returns {{layout: Layout, components: {string: object}}}
   **/
  createLayout = (templateId = null, overrides = {}) => {
    let layout;
    switch (templateId) {
      case 'news': {
        layout = this._createNewsLayout(overrides);
        break;
      }
      case 'overview': {
        layout = this._createOverviewLayout(overrides);
        break;
      }
      case 'Default':
      default: {
        layout = this._createDefaultLayout(overrides);
      }
    }

    const t = new Date().getTime();
    layout.createdAt = t;
    layout.updatedAt = t;

    if (overrides.predefined) {
      layout.components = Object.entries(layout.components).reduce((acc, [id, comp]) => {
        return {
          ...acc,
          [id]: {
            ...comp,
            predefined: true,
          }
        }
      }, {});
    }

    return layout;
  }


  _createDefaultLayout = (overrides) => {
    const scannerId = this.generateId();
    const newsId = this.generateId();
    const chartId = this.generateId();
    const keystatsId = this.generateId();
    const historicalId = this.generateId();

    const components = {
      [scannerId]: this._createComponent(COMPONENT_TYPES.SCANNER).component,
      [chartId]: this._createComponent(COMPONENT_TYPES.CHART).component,
      [keystatsId]: this._createComponent(COMPONENT_TYPES.KEYSTATS).component,
      [historicalId]: this._createComponent(COMPONENT_TYPES.HISTORICAL).component,
      [newsId]: this._createComponent(COMPONENT_TYPES.NEWS).component,
    }

    const layout = {
      name: 'New Layout',
      currentNode: {
        first: scannerId,
        second: {
          first: {
            first: chartId,
            second: newsId,
            direction: 'row',
            splitPercentage: 50
          },
          second: {
            first: keystatsId,
            second: historicalId,
            direction: 'row',
            splitPercentage: 50
          },
          splitPercentage: 50,
          direction: 'column'
        },
        direction: 'row',
        splitPercentage: 40,
      },
      ...overrides
    };

    return {
      layout,
      components,
    };
  }



  _createNewsLayout = (overrides) => {
    const newsOnlyId = this.generateId();
    const secOnlyId = this.generateId();

    const components = {
      [newsOnlyId]: this._createComponent(COMPONENT_TYPES.NEWS, {
        categories: newsOnlyNewsCategories,
      }).component,
      [secOnlyId]: this._createComponent(COMPONENT_TYPES.NEWS, {
        categories: secOnlyNewsCategories
      }).component,
    }

    const layout = {
      name: 'New Layout',

      currentNode: {
        first: newsOnlyId,
        second: secOnlyId,
        direction: 'row',
        splitPercentage: 50,
      },
      ...overrides
    };

    return {
      layout,
      layoutId: this.generateId(),
      components
    };

  }

  _createOverviewLayout = (overrides) => {
    const chartId = this.generateId()
    const newsId = this.generateId()
    const keystatsId = this.generateId()
    const historicalId = this.generateId()

    const components = {
      [chartId]: this._createComponent(COMPONENT_TYPES.CHART).component,
      [keystatsId]: this._createComponent(COMPONENT_TYPES.KEYSTATS).component,
      [historicalId]: this._createComponent(COMPONENT_TYPES.HISTORICAL).component,
      [newsId]: this._createComponent(COMPONENT_TYPES.NEWS, { link: LINK_COLORS.white.name }).component
    }

    const layout = {
      name: 'Overview',
      currentNode: {
        first: {
          first: chartId,
          second: newsId,
          direction: 'column',
          splitPercentage: 50
        },
        second: {
          first: keystatsId,
          second: historicalId,
          direction: 'column',
          splitPercentage: 50
        },
        direction: 'row',
        splitPercentage: 50,
      },
      ...overrides
    };

    return {
      layout,
      layoutId: this.generateId(),
      components
    };

  }
}


export const ToplistSchemaApi = new ToplistLayoutSchemaApi({
  profileConfig: PROFILE_CONFIG,
  layoutTemplates: LAYOUT_TEMPLATES,
  componentMap: COMPONENT_MAP
});
