import React from 'react';
import PropTypes from 'prop-types'
import clsx from 'clsx';
import MosiacLoadingOverlay from './MosaicLoadingOverlay';
import {
  makeStyles
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    margin: theme.spacing(.5),
    marginTop: 0,
    borderRadius: theme.grid.borderRadius,
    overflow: 'hidden',
  },
  content: {
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.grid.borderRadius,
    overflow: 'auto',
    transition: [['opacity', '300ms', 'ease']],
    opacity: 1
  },
  loading: {
    opacity: 0,
    pointerEvents: 'none'
  }
}));


const MosaicPanelBody = React.forwardRef(({
  className,
  contentClassName,
  loading,
  transparentOverlay,
  children,
  ...rest
}, ref) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(className, classes.root, 'panel-body')}
      ref={ref}
      {...rest}
    >
      <div className={clsx(
        classes.content,
        contentClassName,
        (loading && !transparentOverlay) && classes.loading
      )}>
        {children}
      </div>
      <MosiacLoadingOverlay show={loading} transparent={true} />
    </div>
  );
});


MosaicPanelBody.propTypes = {
  clasName: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.any
}


MosaicPanelBody.defaultProps = {
  loading: true,
}


MosaicPanelBody.displayName = 'MosaicPanelBody';

export default MosaicPanelBody;

