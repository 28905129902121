import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import rootReducer from 'src/redux/store/rootReducer';
import getLayoutHistoryMiddleware from '../layoutV2/history/makeMiddleware';
import getLayoutToplistMiddleware from '../layoutV2/toplist/makeMiddleware';


const silenceActions = new Set([
  '@layout-watchlist/request__fetch-watchlist',
  '@layout-watchlist/fetch-watchlist'
])


const loggerMiddleware = createLogger({
  predicate: (getState, action) => !silenceActions.has(action.type)
});


export function configureStore(preloadedState = {}) {
  // userNamespacedLocalStorageMiddleware.addSlices(localStorageSliceConfig);

  const middlewares = [
    thunkMiddleware,
  ];

  middlewares.push(getLayoutToplistMiddleware().middleware);
  middlewares.push(getLayoutHistoryMiddleware().middleware);


  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(loggerMiddleware);
  }

  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);


  // const exprSlice = userNamespacedLocalStorageMiddleware.getSlice('expressions');
  //
  // if (!exprSlice || !exprSlice?.databaseKey || !exprSlice?.getStorageKey) {
  //   throw Error('Missing Expressions localstorage definition');
  // }

  return createStore(
    rootReducer,
    preloadedState,
    composedEnhancers
  );
}
