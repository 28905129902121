import _set from 'lodash/set';

/**
 * { a: { b: { c: 1 } } } => a-b-c: 1
 *
 * @param {object} obj
 * @param {string} prefix
 * @returns {object}
 */
function flattenObject(obj, prefix = '') {
  let result = {};
  Object.keys(obj).forEach(key => {
    const value = obj[key];
    const newKey = prefix ? `${prefix}-${key}` : key;
    // Only include if the value is a string or number
    if (typeof value === 'object' && value !== null) {
      Object.assign(result, flattenObject(value, newKey));
    } else if (typeof value === 'string' || typeof value === 'number') {
      result[newKey] = value;
    }
  });
  return result;
}


/**
 * Adds all theme.palette variables to the MuiCssBaseline overrides, to be 
 * inserted as normal CSS variables in the <head> of the document.
 *
 * Don't apply this to the entire theme, just the specific dark/light palette.
 * If you have global config that you want inserted, do it manually in the 
 * baseTheme.
 * 
 * @param {object} theme
 * @returns {object} theme
 */
export default function insertBaselineCssVariablesFromTheme(theme) {
  const flattenedPalette = flattenObject(theme.palette);

  for (const key in flattenedPalette) {
    const variableName = `--palette-${key}`;
    _set(
      theme,
      ['overrides', 'MuiCssBaseline', '@global', ':root', variableName],
      flattenedPalette[key]
    )
  }

  return theme;
}


