import {
  STRUCTURAL_TYPES,
  STRUCTURAL_CATEGORIES,
  STRUCTURAL_TYPE_RULES,
  getNodeTypeCategory,
  FILTER_FORM_TYPES,
  PROFILE_TYPES_NEEDING_INSERTED_ROOT_NODE
} from 'src/app/slicedForm/mapping/mappingDirections/index';
import _mapValues from 'lodash/mapValues';
import { unflattenPathsToObject } from '../flatpath.js';

/**  @typedef {import('./index.js').FormStruct} FormStruct */


const decideNodeType = (node) => {
  if (!node || !(typeof node === 'object')) return null
  return node?.type;
}

/**
 * Works on an individual profile
 * @param {ProfileStruct} profile
 * @param {{string: object}} entities
 * @param {ValueOf<FILTER_FORM_TYPES>} formType
 * @returns {ProfileStruct}
 */
function denormalizeProfileContent(
  [profileId, profile],
  entities,
  formType
) {
  const contentKeyword = formType;
  if (!contentKeyword) {
    throw new Error('formType is required, set it inside profileToForm');
  }


  if (contentKeyword === FILTER_FORM_TYPES.COLUMN) {
    // Columns require no mapping
    return profile
  }

  const { root, ...rest } = profile;

  const recombinedConent = (function recurse(node) {
    const type = decideNodeType(node);
    const category = getNodeTypeCategory(type);
    const treeKey = STRUCTURAL_TYPE_RULES[type]?.treeKey;

    switch (category) {
      case STRUCTURAL_CATEGORIES.GROUP_WITHOUT_ENTITY: {
        return {
          [treeKey]: node.tree.map(recurse)
        };
      }
      case STRUCTURAL_CATEGORIES.GROUP_WITH_ENTITY: {
        return {
          ...unflattenPathsToObject(_mapValues(entities[node.id], e => e?.val)),
          [treeKey]: node.tree.map(recurse)
        };
      }
      case STRUCTURAL_CATEGORIES.ENTITY: {
        return unflattenPathsToObject(_mapValues(entities[node.id], e => e?.val));
      }
      default: {
        throw new Error(`Unknown node category "${category}<${type}>", node ${JSON.stringify(node)}}`);
      }
    }
  })(root);

  // Remove the useless ROOT node for aggregates
  const finalContent = PROFILE_TYPES_NEEDING_INSERTED_ROOT_NODE.includes(contentKeyword)
    ? recombinedConent[STRUCTURAL_TYPES.ROOT]
    : recombinedConent;

  return {
    ...rest,
    [contentKeyword]: finalContent
  }
}




/**
 * Single function for mapping all types of profiles from SlicedForm back into regular denormalized state.
 * Note: It probably makes sense to seperate these out, but right now this is fine.
 * @param {object} state
 * @param {{string: ProfileStruct}} state.profileMap
 * @param {{string: import('../../utility/arborHelpers.js').TreeItem}} state.profileTree
 * @param {}
 * @param {ValueOf<FILTER_FORM_TYPES>} formType
 * @returns {{Object}}
 */
export default function formToProfile({
  profileMap,
  profileTree,
  entities,
  formType,
  activeProfile,
  expressions,
  content,
  ...rest
}) {
  // Recombine the profiles
  const recombinedProfiles = Object.entries(profileMap).reduce((acc, profile) => {
    const id = profile[0];
    const combined = denormalizeProfileContent(profile, entities, formType);
    return { ...acc, [id]: combined };
  }, {});

  // Remove the ID and label from the profileTree.items
  // NOTE: Moved to SchemaApi
  // const items = Object.entries(profileTree.items).reduce(unreduceTreeItems, {});

  const out = {
    activeProfile,
    profileMap: recombinedProfiles,
    expressions
  }

  if (profileTree) {
    out.profileTree = {
      openState: profileTree.openState,
      items: profileTree.items,
    }
  }

  return out;
}
