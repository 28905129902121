import TickerHoverMenu from 'src/app/components/tables/TickerHoverMenu';
import { HISTORY_SEARCH_SYNONYMS, mapSynonymsToHistory } from './historyColumnFunctions';
import { countryOptions } from 'src/constants/countryOptions';

export const HISTORY_INPUT_GROUPS = [
  { name: 'allmetrics', label: 'All Metrics' },
  { name: 'fundamentals', label: 'Fundamentals' },
  { name: 'day0Metrics', label: 'Day 1 Metrics' },
  { name: 'day1Metrics', label: 'Day 2 Metrics' },
  { name: 'otherDayMetrics', label: 'Other Day Metrics' },
  { name: 'dailyIndicators', label: 'Daily Indicators' },
  { name: 'hourlyMetrics', label: 'Hourly Metrics' },
];

const SsrOptions = [
  { name: '1', label: 'Yes' },
  { name: '0', label: 'No' }
];

export const shareTypeOptions = [
  { name: 'CS', label: 'Common Stock' },
  { name: 'WT', label: 'Warrant' },
  { name: 'ETF', label: 'ETF' }
];


export const shareGroupOptions = [
  { name: 'CS', label: 'Common Stock' },
  { name: 'ET', label: 'Exchange Traded' },
  { name: 'PS', label: 'Preferred Stock' },
  { name: 'WT', label: 'Warrant' }
]

export const exchangeOptions = [
  { name: 'ARCA', label: 'ARCA' },
  { name: 'BATS', label: 'BATS' },
  { name: 'BATY', label: 'BATY' },
  { name: 'NASDAQ', label: 'NASDAQ' },
  { name: 'NYSE', label: 'NYSE' },
  { name: 'NYSEMKT', label: 'NYSEMKT' },
];

const historyColumns = [
  {
    name: 'ticker',
    label: 'Ticker',
    hardFormat: true,
    sticky: true,
    hasActiveIndicator: true,
    HoverMenu: TickerHoverMenu,
    input: 'ticker',
    group: 'fundamentals'
  },
  {
    name: 'day0_date',
    label: 'Day 1 Date',
    filterFormLabel: 'Date Range',
    pinned: 'left',
    width: 104,
    hardFormat: true,
    suppressSizeToFit: true,
    suppressAutoSize: true,
    sticky: true,
    required: true,
    input: 'dateRange',
    group: 'fundamentals'
  },
  {
    name: 'day0_open',
    label: 'Day 1 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_high',
    label: 'Day 1 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_low',
    label: 'Day 1 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_close',
    label: 'Day 1 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_vol',
    label: 'Day 1 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_vw',
    label: 'Day 1 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_range',
    label: 'Day 1 Range',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_chg',
    label: 'Day 1 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_chg_pct',
    label: 'Day 1 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_chg2',
    label: 'Day 1 Chg2',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_chg2_pct',
    label: 'Day 1 Chg2 %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_chg3',
    label: 'Day 1 Chg3',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_chg3_pct',
    label: 'Day 1 Chg3 %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_chg4',
    label: 'Day 1 Chg4',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_chg4_pct',
    label: 'Day 1 Chg4 %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_chg5',
    label: 'Day 1 Chg5',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_chg5_pct',
    label: 'Day 1 Chg5 %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_gap',
    label: 'Day 1 Gap',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_gap_pct',
    label: 'Day 1 Gap %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_ret_open',
    label: 'Day 1 Ret Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_ret_open_pct',
    label: 'Day 1 Ret Open %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_open_ssr',
    label: 'Day 1 Open SSR',
    input: 'singleSelect',
    group: 'day0Metrics',
    options: SsrOptions
  },
  {
    name: 'day0_open_high_pct',
    label: 'Day 1 Open High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_open_low_pct',
    label: 'Day 1 Open Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_high_low_pct',
    label: 'Day 1 High Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_close_high_pct',
    label: 'Day 1 Close High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_close_low_pct',
    label: 'Day 1 Close Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_range_close_pct',
    label: 'Day 1 Range Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_dollar_vol',
    label: 'Day 1 Dollar Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_consec_green',
    label: 'Day 1 Consec Green',
    input: 'compare',
    numberType: 'integer',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_consec_red',
    label: 'Day 1 Consec Red',
    input: 'compare',
    numberType: 'integer',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_consec_gap_up',
    label: 'Day 1 Consec Gap Up',
    input: 'compare',
    numberType: 'integer',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_consec_gap_down',
    label: 'Day 1 Consec Gap Down',
    input: 'compare',
    numberType: 'integer',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day_m5_date',
    label: 'Day -5 Date',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m5_open',
    label: 'Day -5 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m5_high',
    label: 'Day -5 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m5_low',
    label: 'Day -5 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m5_close',
    label: 'Day -5 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m5_vol',
    label: 'Day -5 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'otherDayMetrics',
    integer: true
  },
  {
    name: 'day_m5_vw',
    label: 'Day -5 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m5_range',
    label: 'Day -5 Range',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m5_chg',
    label: 'Day -5 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m5_chg_pct',
    label: 'Day -5 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m5_gap',
    label: 'Day -5 Gap',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m5_gap_pct',
    label: 'Day -5 Gap %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m5_ret_open',
    label: 'Day -5 Ret Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m5_ret_open_pct',
    label: 'Day -5 Ret Open %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m5_high_low_pct',
    label: 'Day -5 High Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m4_date',
    label: 'Day -4 Date',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m4_open',
    label: 'Day -4 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m4_high',
    label: 'Day -4 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m4_low',
    label: 'Day -4 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m4_close',
    label: 'Day -4 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m4_vol',
    label: 'Day -4 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'otherDayMetrics',
    integer: true
  },
  {
    name: 'day_m4_vw',
    label: 'Day -4 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m4_range',
    label: 'Day -4 Range',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m4_chg',
    label: 'Day -4 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m4_chg_pct',
    label: 'Day -4 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m4_gap',
    label: 'Day -4 Gap',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m4_gap_pct',
    label: 'Day -4 Gap %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m4_ret_open',
    label: 'Day -4 Ret Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m4_ret_open_pct',
    label: 'Day -4 Ret Open %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m4_high_low_pct',
    label: 'Day -4 High Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m3_date',
    label: 'Day -3 Date',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m3_open',
    label: 'Day -3 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m3_high',
    label: 'Day -3 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m3_low',
    label: 'Day -3 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m3_close',
    label: 'Day -3 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m3_vol',
    label: 'Day -3 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'otherDayMetrics',
    integer: true
  },
  {
    name: 'day_m3_vw',
    label: 'Day -3 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m3_range',
    label: 'Day -3 Range',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m3_chg',
    label: 'Day -3 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m3_chg_pct',
    label: 'Day -3 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m3_gap',
    label: 'Day -3 Gap',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m3_gap_pct',
    label: 'Day -3 Gap %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m3_ret_open',
    label: 'Day -3 Ret Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m3_ret_open_pct',
    label: 'Day -3 Ret Open %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m3_high_low_pct',
    label: 'Day -3 High Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m2_date',
    label: 'Day -2 Date',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m2_open',
    label: 'Day -2 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m2_high',
    label: 'Day -2 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m2_low',
    label: 'Day -2 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m2_close',
    label: 'Day -2 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m2_vol',
    label: 'Day -2 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'otherDayMetrics',
    integer: true
  },
  {
    name: 'day_m2_vw',
    label: 'Day -2 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m2_range',
    label: 'Day -2 Range',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m2_chg',
    label: 'Day -2 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m2_chg_pct',
    label: 'Day -2 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m2_gap',
    label: 'Day -2 Gap',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m2_gap_pct',
    label: 'Day -2 Gap %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m2_ret_open',
    label: 'Day -2 Ret Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m2_ret_open_pct',
    label: 'Day -2 Ret Open %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m2_high_low_pct',
    label: 'Day -2 High Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m2_open_ssr',
    label: 'Day -2 Open SSR',
    input: 'singleSelect',
    group: 'otherDayMetrics',
    options: SsrOptions
  },
  {
    name: 'day_m1_date',
    label: 'Day -1 Date',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m1_open',
    label: 'Day -1 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m1_high',
    label: 'Day -1 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m1_low',
    label: 'Day -1 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m1_close',
    label: 'Day -1 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m1_vol',
    label: 'Day -1 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'otherDayMetrics',
    integer: true
  },
  {
    name: 'day_m1_vw',
    label: 'Day -1 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m1_range',
    label: 'Day -1 Range',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m1_chg',
    label: 'Day -1 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m1_chg_pct',
    label: 'Day -1 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m1_gap',
    label: 'Day -1 Gap',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m1_gap_pct',
    label: 'Day -1 Gap %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m1_ret_open',
    label: 'Day -1 Ret Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m1_ret_open_pct',
    label: 'Day -1 Ret Open %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m1_high_low_pct',
    label: 'Day -1 High Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m1_dollar_vol',
    label: 'Day -1 Dollar Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'otherDayMetrics',
    integer: true
  },
  {
    name: 'day_m1_open_ssr',
    label: 'Day -1 Open SSR',
    input: 'singleSelect',
    group: 'otherDayMetrics',
    options: SsrOptions
  },
  {
    name: 'day1_date',
    label: 'Day 2 Date',
    group: 'day1Metrics'
  },
  {
    name: 'day1_open',
    label: 'Day 2 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day1Metrics'
  },
  {
    name: 'day1_high',
    label: 'Day 2 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day1Metrics'
  },
  {
    name: 'day1_low',
    label: 'Day 2 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day1Metrics'
  },
  {
    name: 'day1_close',
    label: 'Day 2 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day1Metrics'
  },
  {
    name: 'day1_vol',
    label: 'Day 2 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day1Metrics',
    integer: true
  },
  {
    name: 'day1_vw',
    label: 'Day 2 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day1Metrics'
  },
  {
    name: 'day1_range',
    label: 'Day 2 Range',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day1Metrics'
  },
  {
    name: 'day1_chg',
    label: 'Day 2 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day1Metrics',
    numberHighlighting: true
  },
  {
    name: 'day1_chg_pct',
    label: 'Day 2 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day1Metrics',
    numberHighlighting: true
  },
  {
    name: 'day1_gap',
    label: 'Day 2 Gap',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day1Metrics',
    numberHighlighting: true
  },
  {
    name: 'day1_gap_pct',
    label: 'Day 2 Gap %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day1Metrics',
    numberHighlighting: true
  },
  {
    name: 'day1_ret_open',
    label: 'Day 2 Ret Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day1Metrics',
    numberHighlighting: true
  },
  {
    name: 'day1_ret_open_pct',
    label: 'Day 2 Ret Open %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day1Metrics',
    numberHighlighting: true
  },
  {
    name: 'day1_high_low_pct',
    label: 'Day 2 High Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day1Metrics',
    numberHighlighting: true
  },
  {
    name: 'day1_dollar_vol',
    label: 'Day 2 Dollar Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day1Metrics',
    integer: true
  },
  {
    name: 'day1_open_ssr',
    label: 'Day 2 Open SSR',
    input: 'singleSelect',
    group: 'day1Metrics',
    options: SsrOptions
  },
  {
    name: 'day2_date',
    label: 'Day 3 Date',
    group: 'otherDayMetrics'
  },
  {
    name: 'day2_open',
    label: 'Day 3 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day2_high',
    label: 'Day 3 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day2_low',
    label: 'Day 3 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day2_close',
    label: 'Day 3 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day2_vol',
    label: 'Day 3 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'otherDayMetrics',
    integer: true
  },
  {
    name: 'day2_vw',
    label: 'Day 3 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day2_range',
    label: 'Day 3 Range',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day2_chg',
    label: 'Day 3 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day2_chg_pct',
    label: 'Day 3 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day2_gap',
    label: 'Day 3 Gap',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day2_gap_pct',
    label: 'Day 3 Gap %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day2_ret_open',
    label: 'Day 3 Ret Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day2_ret_open_pct',
    label: 'Day 3 Ret Open %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day2_high_low_pct',
    label: 'Day 3 High Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day2_open_ssr',
    label: 'Day 3 Open SSR',
    input: 'singleSelect',
    group: 'otherDayMetrics',
    options: SsrOptions
  },
  {
    name: 'day3_date',
    label: 'Day 4 Date',
    group: 'otherDayMetrics'
  },
  {
    name: 'day3_open',
    label: 'Day 4 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day3_high',
    label: 'Day 4 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day3_low',
    label: 'Day 4 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day3_close',
    label: 'Day 4 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day3_vol',
    label: 'Day 4 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'otherDayMetrics',
    integer: true
  },
  {
    name: 'day3_vw',
    label: 'Day 4 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day3_range',
    label: 'Day 4 Range',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day3_chg',
    label: 'Day 4 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day3_chg_pct',
    label: 'Day 4 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day3_gap',
    label: 'Day 4 Gap',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day3_gap_pct',
    label: 'Day 4 Gap %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day3_ret_open',
    label: 'Day 4 Ret Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day3_ret_open_pct',
    label: 'Day 4 Ret Open %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day3_high_low_pct',
    label: 'Day 4 High Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day4_date',
    label: 'Day 5 Date',
    group: 'otherDayMetrics'
  },
  {
    name: 'day4_open',
    label: 'Day 5 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day4_high',
    label: 'Day 5 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day4_low',
    label: 'Day 5 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day4_close',
    label: 'Day 5 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day4_vol',
    label: 'Day 5 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'otherDayMetrics',
    integer: true
  },
  {
    name: 'day4_vw',
    label: 'Day 5 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day4_range',
    label: 'Day 5 Range',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day4_chg',
    label: 'Day 5 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day4_chg_pct',
    label: 'Day 5 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day4_gap',
    label: 'Day 5 Gap',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day4_gap_pct',
    label: 'Day 5 Gap %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day4_ret_open',
    label: 'Day 5 Ret Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day4_ret_open_pct',
    label: 'Day 5 Ret Open %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day4_high_low_pct',
    label: 'Day 5 High Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day5_date',
    label: 'Day 6 Date',
    group: 'otherDayMetrics'
  },
  {
    name: 'day5_open',
    label: 'Day 6 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day5_high',
    label: 'Day 6 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day5_low',
    label: 'Day 6 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day5_close',
    label: 'Day 6 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day5_vol',
    label: 'Day 6 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'otherDayMetrics',
    integer: true
  },
  {
    name: 'day5_vw',
    label: 'Day 6 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day5_range',
    label: 'Day 6 Range',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day5_chg',
    label: 'Day 6 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day5_chg_pct',
    label: 'Day 6 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day5_gap',
    label: 'Day 6 Gap',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day5_gap_pct',
    label: 'Day 6 Gap %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day5_ret_open',
    label: 'Day 6 Ret Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day5_ret_open_pct',
    label: 'Day 6 Ret Open %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day5_high_low_pct',
    label: 'Day 6 High Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_pm_vol',
    label: 'Day 1 PM Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_pm_high',
    label: 'Day 1 PM High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_pm_high_time',
    label: 'Day 1 PM High Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_pm_high_pct',
    label: 'Day 1 PM High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_pm_vol_to_hod',
    label: 'Day 1 PM Vol To Hod',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_pm_low',
    label: 'Day 1 PM Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_pm_low_time',
    label: 'Day 1 PM Low Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_pm_vol_to_lod',
    label: 'Day 1 PM Vol To Lod',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_pm_low_after_high',
    label: 'Day 1 PM Low After High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_pm_low_after_high_time',
    label: 'Day 1 PM Low After High Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_pm_low_before_high',
    label: 'Day 1 PM Low Before High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_pm_low_before_high_time',
    label: 'Day 1 PM Low Before High Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_pm_high_after_low',
    label: 'Day 1 PM High After Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_pm_high_after_low_time',
    label: 'Day 1 PM High After Low Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_pm_high_before_low',
    label: 'Day 1 PM High Before Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_pm_high_before_low_time',
    label: 'Day 1 PM High Before Low Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_pm_high_m15_vol',
    label: 'Day 1 PM High M15 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_pm_high_m10_vol',
    label: 'Day 1 PM High M10 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_pm_high_m05_vol',
    label: 'Day 1 PM High M05 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_pm_high_p15_vol',
    label: 'Day 1 PM High P15 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_pm_high_p10_vol',
    label: 'Day 1 PM High P10 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_pm_high_p05_vol',
    label: 'Day 1 PM High P05 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_pm_low_m15_vol',
    label: 'Day 1 PM Low M15 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_pm_low_m10_vol',
    label: 'Day 1 PM Low M10 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_pm_low_m05_vol',
    label: 'Day 1 PM Low M05 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_pm_low_p15_vol',
    label: 'Day 1 PM Low P15 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_pm_low_p10_vol',
    label: 'Day 1 PM Low P10 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_pm_low_p05_vol',
    label: 'Day 1 PM Low P05 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_pm_start_time',
    label: 'Day 1 PM Start Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_pm_open_range_pct',
    label: 'Day 1 PM Open Range %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_pm_pullback_open_j_pct',
    label: 'Day 1 PM Pullback Open %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_high_time',
    label: 'Day 1 High Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_vol_to_hod',
    label: 'Day 1 Vol To Hod',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_low_time',
    label: 'Day 1 Low Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_vol_to_lod',
    label: 'Day 1 Vol To Lod',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_low_after_high',
    label: 'Day 1 Low After High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_low_after_high_time',
    label: 'Day 1 Low After High Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_low_before_high',
    label: 'Day 1 Low Before High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_low_before_high_time',
    label: 'Day 1 Low Before High Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_high_after_low',
    label: 'Day 1 High After Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_high_after_low_time',
    label: 'Day 1 High After Low Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_high_before_low',
    label: 'Day 1 High Before Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_high_before_low_time',
    label: 'Day 1 High Before Low Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_high_m15_vol',
    label: 'Day 1 High M15 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_high_m10_vol',
    label: 'Day 1 High M10 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_high_m05_vol',
    label: 'Day 1 High M05 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_high_p15_vol',
    label: 'Day 1 High P15 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_high_p10_vol',
    label: 'Day 1 High P10 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_high_p05_vol',
    label: 'Day 1 High P05 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_low_m15_vol',
    label: 'Day 1 Low M15 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_low_m10_vol',
    label: 'Day 1 Low M10 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_low_m05_vol',
    label: 'Day 1 Low M05 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_low_p15_vol',
    label: 'Day 1 Low P15 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_low_p10_vol',
    label: 'Day 1 Low P10 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_low_p05_vol',
    label: 'Day 1 Low P05 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_ah_vol',
    label: 'Day 1 AH Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_ah_high',
    label: 'Day 1 AH High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_ah_high_time',
    label: 'Day 1 AH High Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_ah_vol_to_hod',
    label: 'Day 1 AH Vol To Hod',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_ah_low',
    label: 'Day 1 AH Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_ah_low_time',
    label: 'Day 1 AH Low Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_ah_vol_to_lod',
    label: 'Day 1 AH Vol To Lod',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_ah_close',
    label: 'Day 1 AH Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_ah_chg',
    label: 'Day 1 AH Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_ah_chg_pct',
    label: 'Day 1 AH Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day0Metrics',
    numberHighlighting: true
  },
  {
    name: 'day0_ah_low_after_high',
    label: 'Day 1 AH Low After High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_ah_low_after_high_time',
    label: 'Day 1 AH Low After High Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_ah_low_before_high',
    label: 'Day 1 AH Low Before High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_ah_low_before_high_time',
    label: 'Day 1 AH Low Before High Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_ah_high_after_low',
    label: 'Day 1 AH High After Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_ah_high_after_low_time',
    label: 'Day 1 AH High After Low Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_ah_high_before_low',
    label: 'Day 1 AH High Before Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day0Metrics'
  },
  {
    name: 'day0_ah_high_before_low_time',
    label: 'Day 1 AH High Before Low Time',
    input: 'time',
    numberType: 'time',
    group: 'day0Metrics'
  },
  {
    name: 'day0_ah_high_m15_vol',
    label: 'Day 1 AH High M15 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_ah_high_m10_vol',
    label: 'Day 1 AH High M10 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_ah_high_m05_vol',
    label: 'Day 1 AH High M05 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_ah_high_p15_vol',
    label: 'Day 1 AH High P15 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_ah_high_p10_vol',
    label: 'Day 1 AH High P10 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_ah_high_p05_vol',
    label: 'Day 1 AH High P05 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_ah_low_m15_vol',
    label: 'Day 1 AH Low M15 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_ah_low_m10_vol',
    label: 'Day 1 AH Low M10 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_ah_low_m05_vol',
    label: 'Day 1 AH Low M05 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_ah_low_p15_vol',
    label: 'Day 1 AH Low P15 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_ah_low_p10_vol',
    label: 'Day 1 AH Low P10 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_ah_low_p05_vol',
    label: 'Day 1 AH Low P05 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day0Metrics',
    integer: true
  },
  {
    name: 'day0_spike_5min_max',
    label: 'Day 1 Spike 5Min Max',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_spike_5min_max_pct',
    label: 'Day 1 Spike 5Min Max %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_spike_5min_max_time',
    label: 'Day 1 Spike 5Min Max Time',
    input: 'time',
    numberType: 'time',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_spike_5min_max_vol',
    label: 'Day 1 Spike 5Min Max Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_spike_5min_max_vw',
    label: 'Day 1 Spike 5Min Max VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_drop_5min_min',
    label: 'Day 1 Drop 5Min Min',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_drop_5min_min_pct',
    label: 'Day 1 Drop 5Min Min %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_drop_5min_min_time',
    label: 'Day 1 Drop 5Min Min Time',
    input: 'time',
    numberType: 'time',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_drop_5min_min_vol',
    label: 'Day 1 Drop 5Min Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_drop_5min_min_vw',
    label: 'Day 1 Drop 5Min Min VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_spike_10min_max',
    label: 'Day 1 Spike 10Min Max',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_spike_10min_max_pct',
    label: 'Day 1 Spike 10Min Max %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_spike_10min_max_time',
    label: 'Day 1 Spike 10Min Max Time',
    input: 'time',
    numberType: 'time',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_spike_10min_max_vol',
    label: 'Day 1 Spike 10Min Max Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_spike_10min_max_vw',
    label: 'Day 1 Spike 10Min Max VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_drop_10min_min',
    label: 'Day 1 Drop 10Min Min',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_drop_10min_min_pct',
    label: 'Day 1 Drop 10Min Min %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_drop_10min_min_time',
    label: 'Day 1 Drop 10Min Min Time',
    input: 'time',
    numberType: 'time',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_drop_10min_min_vol',
    label: 'Day 1 Drop 10Min Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_drop_10min_min_vw',
    label: 'Day 1 Drop 10Min Min VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_spike_15min_max',
    label: 'Day 1 Spike 15Min Max',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_spike_15min_max_pct',
    label: 'Day 1 Spike 15Min Max %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_spike_15min_max_time',
    label: 'Day 1 Spike 15Min Max Time',
    input: 'time',
    numberType: 'time',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_spike_15min_max_vol',
    label: 'Day 1 Spike 15Min Max Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_spike_15min_max_vw',
    label: 'Day 1 Spike 15Min Max VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_drop_15min_min',
    label: 'Day 1 Drop 15Min Min',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_drop_15min_min_pct',
    label: 'Day 1 Drop 15Min Min %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_drop_15min_min_time',
    label: 'Day 1 Drop 15Min Min Time',
    input: 'time',
    numberType: 'time',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_drop_15min_min_vol',
    label: 'Day 1 Drop 15Min Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_drop_15min_min_vw',
    label: 'Day 1 Drop 15Min Min VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_spike_30min_max',
    label: 'Day 1 Spike 30Min Max',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_spike_30min_max_pct',
    label: 'Day 1 Spike 30Min Max %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_spike_30min_max_time',
    label: 'Day 1 Spike 30Min Max Time',
    input: 'time',
    numberType: 'time',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_spike_30min_max_vol',
    label: 'Day 1 Spike 30Min Max Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_spike_30min_max_vw',
    label: 'Day 1 Spike 30Min Max VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_drop_30min_min',
    label: 'Day 1 Drop 30Min Min',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_drop_30min_min_pct',
    label: 'Day 1 Drop 30Min Min %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_drop_30min_min_time',
    label: 'Day 1 Drop 30Min Min Time',
    input: 'time',
    numberType: 'time',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_drop_30min_min_vol',
    label: 'Day 1 Drop 30Min Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_drop_30min_min_vw',
    label: 'Day 1 Drop 30Min Min VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_spike_60min_max',
    label: 'Day 1 Spike 60Min Max',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_spike_60min_max_pct',
    label: 'Day 1 Spike 60Min Max %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_spike_60min_max_time',
    label: 'Day 1 Spike 60Min Max Time',
    input: 'time',
    numberType: 'time',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_spike_60min_max_vol',
    label: 'Day 1 Spike 60Min Max Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_spike_60min_max_vw',
    label: 'Day 1 Spike 60Min Max VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_drop_60min_min',
    label: 'Day 1 Drop 60Min Min',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_drop_60min_min_pct',
    label: 'Day 1 Drop 60Min Min %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_drop_60min_min_time',
    label: 'Day 1 Drop 60Min Min Time',
    input: 'time',
    numberType: 'time',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_drop_60min_min_vol',
    label: 'Day 1 Drop 60Min Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_drop_60min_min_vw',
    label: 'Day 1 Drop 60Min Min VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day_m1_ah_high',
    label: 'Day -1 AH High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m1_ah_high_time',
    label: 'Day -1 AH High Time',
    input: 'time',
    numberType: 'time',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m1_ah_low',
    label: 'Day -1 AH Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m1_ah_low_time',
    label: 'Day -1 AH Low Time',
    input: 'time',
    numberType: 'time',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m1_ah_close',
    label: 'Day -1 AH Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics'
  },
  {
    name: 'day_m1_ah_vol',
    label: 'Day -1 AH Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'otherDayMetrics',
    integer: true
  },
  {
    name: 'day_m1_ah_chg',
    label: 'Day -1 AH Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day_m1_ah_chg_pct',
    label: 'Day -1 AH Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'otherDayMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_pm_high',
    label: 'Day 2 PM High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day1Metrics'
  },
  {
    name: 'day1_pm_high_time',
    label: 'Day 2 PM High Time',
    input: 'time',
    numberType: 'time',
    group: 'day1Metrics'
  },
  {
    name: 'day1_pm_high_pct',
    label: 'Day 2 PM High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day1Metrics',
    numberHighlighting: true
  },
  {
    name: 'day1_pm_low',
    label: 'Day 2 PM Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'day1Metrics'
  },
  {
    name: 'day1_pm_low_time',
    label: 'Day 2 PM Low Time',
    input: 'time',
    numberType: 'time',
    group: 'day1Metrics'
  },
  {
    name: 'day1_pm_vol',
    label: 'Day 2 PM Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'day1Metrics',
    integer: true
  },
  {
    name: 'day1_open_high_pct',
    label: 'Day 2 Open High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day1Metrics',
    numberHighlighting: true
  },
  {
    name: 'day1_open_low_pct',
    label: 'Day 2 Open Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day1Metrics',
    numberHighlighting: true
  },
  {
    name: 'day1_close_high_pct',
    label: 'Day 2 Close High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day1Metrics',
    numberHighlighting: true
  },
  {
    name: 'day1_close_low_pct',
    label: 'Day 2 Close Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day1Metrics',
    numberHighlighting: true
  },
  {
    name: 'day1_range_close_pct',
    label: 'Day 2 Range Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'day1Metrics',
    numberHighlighting: true
  },
  {
    name: 'day1_high_time',
    label: 'Day 2 High Time',
    input: 'time',
    numberType: 'time',
    group: 'day1Metrics'
  },
  {
    name: 'day1_low_time',
    label: 'Day 2 Low Time',
    input: 'time',
    numberType: 'time',
    group: 'day1Metrics'
  },
  {
    name: 'day1_vol_to_hod',
    label: 'Day 2 Vol To Hod',
    input: 'compare',
    numberType: 'volume',
    group: 'day1Metrics',
    integer: true
  },
  {
    name: 'day1_vol_to_lod',
    label: 'Day 2 Vol To Lod',
    input: 'compare',
    numberType: 'volume',
    group: 'day1Metrics',
    integer: true
  },
  {
    name: 'day0_h_0400_0430_open',
    label: 'Day 1 H 400 430 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0400_0430_high',
    label: 'Day 1 H 400 430 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0400_0430_low',
    label: 'Day 1 H 400 430 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0400_0430_close',
    label: 'Day 1 H 400 430 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0400_0430_vol',
    label: 'Day 1 H 400 430 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_0400_0430_vw',
    label: 'Day 1 H 400 430 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0400_0430_chg',
    label: 'Day 1 H 400 430 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0400_0430_chg_pct',
    label: 'Day 1 H 400 430 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0400_0500_open',
    label: 'Day 1 H 400 500 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0400_0500_high',
    label: 'Day 1 H 400 500 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0400_0500_low',
    label: 'Day 1 H 400 500 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0400_0500_close',
    label: 'Day 1 H 400 500 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0400_0500_vol',
    label: 'Day 1 H 400 500 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_0400_0500_vw',
    label: 'Day 1 H 400 500 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0400_0500_chg',
    label: 'Day 1 H 400 500 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0400_0500_chg_pct',
    label: 'Day 1 H 400 500 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0430_0500_open',
    label: 'Day 1 H 430 500 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0430_0500_high',
    label: 'Day 1 H 430 500 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0430_0500_low',
    label: 'Day 1 H 430 500 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0430_0500_close',
    label: 'Day 1 H 430 500 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0430_0500_vol',
    label: 'Day 1 H 430 500 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_0430_0500_vw',
    label: 'Day 1 H 430 500 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0430_0500_chg',
    label: 'Day 1 H 430 500 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0430_0500_chg_pct',
    label: 'Day 1 H 430 500 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0500_0530_open',
    label: 'Day 1 H 500 530 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0500_0530_high',
    label: 'Day 1 H 500 530 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0500_0530_low',
    label: 'Day 1 H 500 530 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0500_0530_close',
    label: 'Day 1 H 500 530 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0500_0530_vol',
    label: 'Day 1 H 500 530 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_0500_0530_vw',
    label: 'Day 1 H 500 530 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0500_0530_chg',
    label: 'Day 1 H 500 530 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0500_0530_chg_pct',
    label: 'Day 1 H 500 530 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0500_0600_open',
    label: 'Day 1 H 500 600 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0500_0600_high',
    label: 'Day 1 H 500 600 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0500_0600_low',
    label: 'Day 1 H 500 600 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0500_0600_close',
    label: 'Day 1 H 500 600 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0500_0600_vol',
    label: 'Day 1 H 500 600 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_0500_0600_vw',
    label: 'Day 1 H 500 600 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0500_0600_chg',
    label: 'Day 1 H 500 600 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0500_0600_chg_pct',
    label: 'Day 1 H 500 600 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0530_0600_open',
    label: 'Day 1 H 530 600 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0530_0600_high',
    label: 'Day 1 H 530 600 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0530_0600_low',
    label: 'Day 1 H 530 600 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0530_0600_close',
    label: 'Day 1 H 530 600 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0530_0600_vol',
    label: 'Day 1 H 530 600 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_0530_0600_vw',
    label: 'Day 1 H 530 600 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0530_0600_chg',
    label: 'Day 1 H 530 600 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0530_0600_chg_pct',
    label: 'Day 1 H 530 600 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0600_0630_open',
    label: 'Day 1 H 600 630 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0600_0630_high',
    label: 'Day 1 H 600 630 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0600_0630_low',
    label: 'Day 1 H 600 630 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0600_0630_close',
    label: 'Day 1 H 600 630 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0600_0630_vol',
    label: 'Day 1 H 600 630 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_0600_0630_vw',
    label: 'Day 1 H 600 630 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0600_0630_chg',
    label: 'Day 1 H 600 630 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0600_0630_chg_pct',
    label: 'Day 1 H 600 630 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0600_0700_open',
    label: 'Day 1 H 600 700 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0600_0700_high',
    label: 'Day 1 H 600 700 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0600_0700_low',
    label: 'Day 1 H 600 700 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0600_0700_close',
    label: 'Day 1 H 600 700 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0600_0700_vol',
    label: 'Day 1 H 600 700 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_0600_0700_vw',
    label: 'Day 1 H 600 700 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0600_0700_chg',
    label: 'Day 1 H 600 700 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0600_0700_chg_pct',
    label: 'Day 1 H 600 700 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0630_0700_open',
    label: 'Day 1 H 630 700 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0630_0700_high',
    label: 'Day 1 H 630 700 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0630_0700_low',
    label: 'Day 1 H 630 700 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0630_0700_close',
    label: 'Day 1 H 630 700 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0630_0700_vol',
    label: 'Day 1 H 630 700 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_0630_0700_vw',
    label: 'Day 1 H 630 700 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0630_0700_chg',
    label: 'Day 1 H 630 700 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0630_0700_chg_pct',
    label: 'Day 1 H 630 700 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0700_0730_open',
    label: 'Day 1 H 700 730 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0700_0730_high',
    label: 'Day 1 H 700 730 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0700_0730_low',
    label: 'Day 1 H 700 730 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0700_0730_close',
    label: 'Day 1 H 700 730 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0700_0730_vol',
    label: 'Day 1 H 700 730 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_0700_0730_vw',
    label: 'Day 1 H 700 730 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0700_0730_chg',
    label: 'Day 1 H 700 730 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0700_0730_chg_pct',
    label: 'Day 1 H 700 730 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0700_0800_open',
    label: 'Day 1 H 700 800 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0700_0800_high',
    label: 'Day 1 H 700 800 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0700_0800_low',
    label: 'Day 1 H 700 800 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0700_0800_close',
    label: 'Day 1 H 700 800 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0700_0800_vol',
    label: 'Day 1 H 700 800 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_0700_0800_vw',
    label: 'Day 1 H 700 800 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0700_0800_chg',
    label: 'Day 1 H 700 800 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0700_0800_chg_pct',
    label: 'Day 1 H 700 800 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0730_0800_open',
    label: 'Day 1 H 730 800 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0730_0800_high',
    label: 'Day 1 H 730 800 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0730_0800_low',
    label: 'Day 1 H 730 800 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0730_0800_close',
    label: 'Day 1 H 730 800 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0730_0800_vol',
    label: 'Day 1 H 730 800 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_0730_0800_vw',
    label: 'Day 1 H 730 800 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0730_0800_chg',
    label: 'Day 1 H 730 800 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0730_0800_chg_pct',
    label: 'Day 1 H 730 800 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0800_0830_open',
    label: 'Day 1 H 800 830 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0800_0830_high',
    label: 'Day 1 H 800 830 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0800_0830_low',
    label: 'Day 1 H 800 830 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0800_0830_close',
    label: 'Day 1 H 800 830 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0800_0830_vol',
    label: 'Day 1 H 800 830 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_0800_0830_vw',
    label: 'Day 1 H 800 830 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0800_0830_chg',
    label: 'Day 1 H 800 830 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0800_0830_chg_pct',
    label: 'Day 1 H 800 830 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0800_0900_open',
    label: 'Day 1 H 800 900 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0800_0900_high',
    label: 'Day 1 H 800 900 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0800_0900_low',
    label: 'Day 1 H 800 900 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0800_0900_close',
    label: 'Day 1 H 800 900 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0800_0900_vol',
    label: 'Day 1 H 800 900 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_0800_0900_vw',
    label: 'Day 1 H 800 900 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0800_0900_chg',
    label: 'Day 1 H 800 900 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0800_0900_chg_pct',
    label: 'Day 1 H 800 900 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0830_0900_open',
    label: 'Day 1 H 830 900 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0830_0900_high',
    label: 'Day 1 H 830 900 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0830_0900_low',
    label: 'Day 1 H 830 900 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0830_0900_close',
    label: 'Day 1 H 830 900 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0830_0900_vol',
    label: 'Day 1 H 830 900 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_0830_0900_vw',
    label: 'Day 1 H 830 900 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0830_0900_chg',
    label: 'Day 1 H 830 900 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0830_0900_chg_pct',
    label: 'Day 1 H 830 900 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0900_0930_open',
    label: 'Day 1 H 900 930 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0900_0930_high',
    label: 'Day 1 H 900 930 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0900_0930_low',
    label: 'Day 1 H 900 930 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0900_0930_close',
    label: 'Day 1 H 900 930 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0900_0930_vol',
    label: 'Day 1 H 900 930 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_0900_0930_vw',
    label: 'Day 1 H 900 930 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0900_0930_chg',
    label: 'Day 1 H 900 930 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0900_0930_chg_pct',
    label: 'Day 1 H 900 930 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0900_1000_open',
    label: 'Day 1 H 900 1000 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0900_1000_high',
    label: 'Day 1 H 900 1000 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0900_1000_low',
    label: 'Day 1 H 900 1000 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0900_1000_close',
    label: 'Day 1 H 900 1000 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0900_1000_vol',
    label: 'Day 1 H 900 1000 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_0900_1000_vw',
    label: 'Day 1 H 900 1000 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0900_1000_chg',
    label: 'Day 1 H 900 1000 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0900_1000_chg_pct',
    label: 'Day 1 H 900 1000 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0930_1000_open',
    label: 'Day 1 H 930 1000 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0930_1000_high',
    label: 'Day 1 H 930 1000 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0930_1000_low',
    label: 'Day 1 H 930 1000 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0930_1000_close',
    label: 'Day 1 H 930 1000 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0930_1000_vol',
    label: 'Day 1 H 930 1000 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_0930_1000_vw',
    label: 'Day 1 H 930 1000 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_0930_1000_chg',
    label: 'Day 1 H 930 1000 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_0930_1000_chg_pct',
    label: 'Day 1 H 930 1000 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1000_1030_open',
    label: 'Day 1 H 1000 1030 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1000_1030_high',
    label: 'Day 1 H 1000 1030 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1000_1030_low',
    label: 'Day 1 H 1000 1030 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1000_1030_close',
    label: 'Day 1 H 1000 1030 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1000_1030_vol',
    label: 'Day 1 H 1000 1030 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1000_1030_vw',
    label: 'Day 1 H 1000 1030 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1000_1030_chg',
    label: 'Day 1 H 1000 1030 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1000_1030_chg_pct',
    label: 'Day 1 H 1000 1030 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1000_1100_open',
    label: 'Day 1 H 1000 1100 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1000_1100_high',
    label: 'Day 1 H 1000 1100 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1000_1100_low',
    label: 'Day 1 H 1000 1100 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1000_1100_close',
    label: 'Day 1 H 1000 1100 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1000_1100_vol',
    label: 'Day 1 H 1000 1100 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1000_1100_vw',
    label: 'Day 1 H 1000 1100 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1000_1100_chg',
    label: 'Day 1 H 1000 1100 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1000_1100_chg_pct',
    label: 'Day 1 H 1000 1100 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1030_1100_open',
    label: 'Day 1 H 1030 1100 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1030_1100_high',
    label: 'Day 1 H 1030 1100 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1030_1100_low',
    label: 'Day 1 H 1030 1100 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1030_1100_close',
    label: 'Day 1 H 1030 1100 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1030_1100_vol',
    label: 'Day 1 H 1030 1100 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1030_1100_vw',
    label: 'Day 1 H 1030 1100 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1030_1100_chg',
    label: 'Day 1 H 1030 1100 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1030_1100_chg_pct',
    label: 'Day 1 H 1030 1100 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1100_1130_open',
    label: 'Day 1 H 1100 1130 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1100_1130_high',
    label: 'Day 1 H 1100 1130 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1100_1130_low',
    label: 'Day 1 H 1100 1130 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1100_1130_close',
    label: 'Day 1 H 1100 1130 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1100_1130_vol',
    label: 'Day 1 H 1100 1130 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1100_1130_vw',
    label: 'Day 1 H 1100 1130 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1100_1130_chg',
    label: 'Day 1 H 1100 1130 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1100_1130_chg_pct',
    label: 'Day 1 H 1100 1130 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1100_1200_open',
    label: 'Day 1 H 1100 1200 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1100_1200_high',
    label: 'Day 1 H 1100 1200 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1100_1200_low',
    label: 'Day 1 H 1100 1200 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1100_1200_close',
    label: 'Day 1 H 1100 1200 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1100_1200_vol',
    label: 'Day 1 H 1100 1200 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1100_1200_vw',
    label: 'Day 1 H 1100 1200 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1100_1200_chg',
    label: 'Day 1 H 1100 1200 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1100_1200_chg_pct',
    label: 'Day 1 H 1100 1200 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1130_1200_open',
    label: 'Day 1 H 1130 1200 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1130_1200_high',
    label: 'Day 1 H 1130 1200 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1130_1200_low',
    label: 'Day 1 H 1130 1200 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1130_1200_close',
    label: 'Day 1 H 1130 1200 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1130_1200_vol',
    label: 'Day 1 H 1130 1200 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1130_1200_vw',
    label: 'Day 1 H 1130 1200 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1130_1200_chg',
    label: 'Day 1 H 1130 1200 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1130_1200_chg_pct',
    label: 'Day 1 H 1130 1200 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1200_1230_open',
    label: 'Day 1 H 1200 1230 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1200_1230_high',
    label: 'Day 1 H 1200 1230 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1200_1230_low',
    label: 'Day 1 H 1200 1230 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1200_1230_close',
    label: 'Day 1 H 1200 1230 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1200_1230_vol',
    label: 'Day 1 H 1200 1230 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1200_1230_vw',
    label: 'Day 1 H 1200 1230 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1200_1230_chg',
    label: 'Day 1 H 1200 1230 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1200_1230_chg_pct',
    label: 'Day 1 H 1200 1230 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1200_1300_open',
    label: 'Day 1 H 1200 1300 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1200_1300_high',
    label: 'Day 1 H 1200 1300 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1200_1300_low',
    label: 'Day 1 H 1200 1300 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1200_1300_close',
    label: 'Day 1 H 1200 1300 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1200_1300_vol',
    label: 'Day 1 H 1200 1300 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1200_1300_vw',
    label: 'Day 1 H 1200 1300 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1200_1300_chg',
    label: 'Day 1 H 1200 1300 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1200_1300_chg_pct',
    label: 'Day 1 H 1200 1300 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1230_1300_open',
    label: 'Day 1 H 1230 1300 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1230_1300_high',
    label: 'Day 1 H 1230 1300 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1230_1300_low',
    label: 'Day 1 H 1230 1300 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1230_1300_close',
    label: 'Day 1 H 1230 1300 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1230_1300_vol',
    label: 'Day 1 H 1230 1300 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1230_1300_vw',
    label: 'Day 1 H 1230 1300 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1230_1300_chg',
    label: 'Day 1 H 1230 1300 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1230_1300_chg_pct',
    label: 'Day 1 H 1230 1300 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1300_1330_open',
    label: 'Day 1 H 1300 1330 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1300_1330_high',
    label: 'Day 1 H 1300 1330 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1300_1330_low',
    label: 'Day 1 H 1300 1330 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1300_1330_close',
    label: 'Day 1 H 1300 1330 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1300_1330_vol',
    label: 'Day 1 H 1300 1330 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1300_1330_vw',
    label: 'Day 1 H 1300 1330 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1300_1330_chg',
    label: 'Day 1 H 1300 1330 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1300_1330_chg_pct',
    label: 'Day 1 H 1300 1330 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1300_1400_open',
    label: 'Day 1 H 1300 1400 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1300_1400_high',
    label: 'Day 1 H 1300 1400 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1300_1400_low',
    label: 'Day 1 H 1300 1400 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1300_1400_close',
    label: 'Day 1 H 1300 1400 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1300_1400_vol',
    label: 'Day 1 H 1300 1400 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1300_1400_vw',
    label: 'Day 1 H 1300 1400 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1300_1400_chg',
    label: 'Day 1 H 1300 1400 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1300_1400_chg_pct',
    label: 'Day 1 H 1300 1400 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1330_1400_open',
    label: 'Day 1 H 1330 1400 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1330_1400_high',
    label: 'Day 1 H 1330 1400 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1330_1400_low',
    label: 'Day 1 H 1330 1400 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1330_1400_close',
    label: 'Day 1 H 1330 1400 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1330_1400_vol',
    label: 'Day 1 H 1330 1400 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1330_1400_vw',
    label: 'Day 1 H 1330 1400 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1330_1400_chg',
    label: 'Day 1 H 1330 1400 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1330_1400_chg_pct',
    label: 'Day 1 H 1330 1400 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1400_1430_open',
    label: 'Day 1 H 1400 1430 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1400_1430_high',
    label: 'Day 1 H 1400 1430 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1400_1430_low',
    label: 'Day 1 H 1400 1430 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1400_1430_close',
    label: 'Day 1 H 1400 1430 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1400_1430_vol',
    label: 'Day 1 H 1400 1430 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1400_1430_vw',
    label: 'Day 1 H 1400 1430 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1400_1430_chg',
    label: 'Day 1 H 1400 1430 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1400_1430_chg_pct',
    label: 'Day 1 H 1400 1430 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1400_1500_open',
    label: 'Day 1 H 1400 1500 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1400_1500_high',
    label: 'Day 1 H 1400 1500 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1400_1500_low',
    label: 'Day 1 H 1400 1500 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1400_1500_close',
    label: 'Day 1 H 1400 1500 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1400_1500_vol',
    label: 'Day 1 H 1400 1500 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1400_1500_vw',
    label: 'Day 1 H 1400 1500 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1400_1500_chg',
    label: 'Day 1 H 1400 1500 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1400_1500_chg_pct',
    label: 'Day 1 H 1400 1500 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1430_1500_open',
    label: 'Day 1 H 1430 1500 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1430_1500_high',
    label: 'Day 1 H 1430 1500 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1430_1500_low',
    label: 'Day 1 H 1430 1500 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1430_1500_close',
    label: 'Day 1 H 1430 1500 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1430_1500_vol',
    label: 'Day 1 H 1430 1500 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1430_1500_vw',
    label: 'Day 1 H 1430 1500 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1430_1500_chg',
    label: 'Day 1 H 1430 1500 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1430_1500_chg_pct',
    label: 'Day 1 H 1430 1500 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1500_1530_open',
    label: 'Day 1 H 1500 1530 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1500_1530_high',
    label: 'Day 1 H 1500 1530 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1500_1530_low',
    label: 'Day 1 H 1500 1530 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1500_1530_close',
    label: 'Day 1 H 1500 1530 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1500_1530_vol',
    label: 'Day 1 H 1500 1530 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1500_1530_vw',
    label: 'Day 1 H 1500 1530 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1500_1530_chg',
    label: 'Day 1 H 1500 1530 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1500_1530_chg_pct',
    label: 'Day 1 H 1500 1530 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1500_1600_open',
    label: 'Day 1 H 1500 1600 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1500_1600_high',
    label: 'Day 1 H 1500 1600 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1500_1600_low',
    label: 'Day 1 H 1500 1600 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1500_1600_close',
    label: 'Day 1 H 1500 1600 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1500_1600_vol',
    label: 'Day 1 H 1500 1600 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1500_1600_vw',
    label: 'Day 1 H 1500 1600 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1500_1600_chg',
    label: 'Day 1 H 1500 1600 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1500_1600_chg_pct',
    label: 'Day 1 H 1500 1600 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1530_1600_open',
    label: 'Day 1 H 1530 1600 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1530_1600_high',
    label: 'Day 1 H 1530 1600 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1530_1600_low',
    label: 'Day 1 H 1530 1600 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1530_1600_close',
    label: 'Day 1 H 1530 1600 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1530_1600_vol',
    label: 'Day 1 H 1530 1600 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1530_1600_vw',
    label: 'Day 1 H 1530 1600 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1530_1600_chg',
    label: 'Day 1 H 1530 1600 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1530_1600_chg_pct',
    label: 'Day 1 H 1530 1600 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1600_1630_open',
    label: 'Day 1 H 1600 1630 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1600_1630_high',
    label: 'Day 1 H 1600 1630 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1600_1630_low',
    label: 'Day 1 H 1600 1630 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1600_1630_close',
    label: 'Day 1 H 1600 1630 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1600_1630_vol',
    label: 'Day 1 H 1600 1630 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1600_1630_vw',
    label: 'Day 1 H 1600 1630 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1600_1630_chg',
    label: 'Day 1 H 1600 1630 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1600_1630_chg_pct',
    label: 'Day 1 H 1600 1630 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1600_1700_open',
    label: 'Day 1 H 1600 1700 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1600_1700_high',
    label: 'Day 1 H 1600 1700 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1600_1700_low',
    label: 'Day 1 H 1600 1700 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1600_1700_close',
    label: 'Day 1 H 1600 1700 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1600_1700_vol',
    label: 'Day 1 H 1600 1700 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1600_1700_vw',
    label: 'Day 1 H 1600 1700 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1600_1700_chg',
    label: 'Day 1 H 1600 1700 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1600_1700_chg_pct',
    label: 'Day 1 H 1600 1700 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1630_1700_open',
    label: 'Day 1 H 1630 1700 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1630_1700_high',
    label: 'Day 1 H 1630 1700 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1630_1700_low',
    label: 'Day 1 H 1630 1700 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1630_1700_close',
    label: 'Day 1 H 1630 1700 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1630_1700_vol',
    label: 'Day 1 H 1630 1700 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1630_1700_vw',
    label: 'Day 1 H 1630 1700 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1630_1700_chg',
    label: 'Day 1 H 1630 1700 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1630_1700_chg_pct',
    label: 'Day 1 H 1630 1700 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1700_1730_open',
    label: 'Day 1 H 1700 1730 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1700_1730_high',
    label: 'Day 1 H 1700 1730 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1700_1730_low',
    label: 'Day 1 H 1700 1730 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1700_1730_close',
    label: 'Day 1 H 1700 1730 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1700_1730_vol',
    label: 'Day 1 H 1700 1730 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1700_1730_vw',
    label: 'Day 1 H 1700 1730 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1700_1730_chg',
    label: 'Day 1 H 1700 1730 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1700_1730_chg_pct',
    label: 'Day 1 H 1700 1730 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1700_1800_open',
    label: 'Day 1 H 1700 1800 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1700_1800_high',
    label: 'Day 1 H 1700 1800 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1700_1800_low',
    label: 'Day 1 H 1700 1800 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1700_1800_close',
    label: 'Day 1 H 1700 1800 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1700_1800_vol',
    label: 'Day 1 H 1700 1800 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1700_1800_vw',
    label: 'Day 1 H 1700 1800 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1700_1800_chg',
    label: 'Day 1 H 1700 1800 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1700_1800_chg_pct',
    label: 'Day 1 H 1700 1800 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1730_1800_open',
    label: 'Day 1 H 1730 1800 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1730_1800_high',
    label: 'Day 1 H 1730 1800 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1730_1800_low',
    label: 'Day 1 H 1730 1800 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1730_1800_close',
    label: 'Day 1 H 1730 1800 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1730_1800_vol',
    label: 'Day 1 H 1730 1800 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1730_1800_vw',
    label: 'Day 1 H 1730 1800 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1730_1800_chg',
    label: 'Day 1 H 1730 1800 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1730_1800_chg_pct',
    label: 'Day 1 H 1730 1800 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1800_1830_open',
    label: 'Day 1 H 1800 1830 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1800_1830_high',
    label: 'Day 1 H 1800 1830 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1800_1830_low',
    label: 'Day 1 H 1800 1830 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1800_1830_close',
    label: 'Day 1 H 1800 1830 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1800_1830_vol',
    label: 'Day 1 H 1800 1830 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1800_1830_vw',
    label: 'Day 1 H 1800 1830 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1800_1830_chg',
    label: 'Day 1 H 1800 1830 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1800_1830_chg_pct',
    label: 'Day 1 H 1800 1830 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1800_1900_open',
    label: 'Day 1 H 1800 1900 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1800_1900_high',
    label: 'Day 1 H 1800 1900 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1800_1900_low',
    label: 'Day 1 H 1800 1900 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1800_1900_close',
    label: 'Day 1 H 1800 1900 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1800_1900_vol',
    label: 'Day 1 H 1800 1900 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1800_1900_vw',
    label: 'Day 1 H 1800 1900 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1800_1900_chg',
    label: 'Day 1 H 1800 1900 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1800_1900_chg_pct',
    label: 'Day 1 H 1800 1900 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1830_1900_open',
    label: 'Day 1 H 1830 1900 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1830_1900_high',
    label: 'Day 1 H 1830 1900 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1830_1900_low',
    label: 'Day 1 H 1830 1900 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1830_1900_close',
    label: 'Day 1 H 1830 1900 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1830_1900_vol',
    label: 'Day 1 H 1830 1900 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1830_1900_vw',
    label: 'Day 1 H 1830 1900 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1830_1900_chg',
    label: 'Day 1 H 1830 1900 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1830_1900_chg_pct',
    label: 'Day 1 H 1830 1900 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1900_1930_open',
    label: 'Day 1 H 1900 1930 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1900_1930_high',
    label: 'Day 1 H 1900 1930 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1900_1930_low',
    label: 'Day 1 H 1900 1930 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1900_1930_close',
    label: 'Day 1 H 1900 1930 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1900_1930_vol',
    label: 'Day 1 H 1900 1930 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1900_1930_vw',
    label: 'Day 1 H 1900 1930 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1900_1930_chg',
    label: 'Day 1 H 1900 1930 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1900_1930_chg_pct',
    label: 'Day 1 H 1900 1930 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1900_2000_open',
    label: 'Day 1 H 1900 2000 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1900_2000_high',
    label: 'Day 1 H 1900 2000 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1900_2000_low',
    label: 'Day 1 H 1900 2000 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1900_2000_close',
    label: 'Day 1 H 1900 2000 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1900_2000_vol',
    label: 'Day 1 H 1900 2000 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1900_2000_vw',
    label: 'Day 1 H 1900 2000 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1900_2000_chg',
    label: 'Day 1 H 1900 2000 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1900_2000_chg_pct',
    label: 'Day 1 H 1900 2000 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1930_2000_open',
    label: 'Day 1 H 1930 2000 Open',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1930_2000_high',
    label: 'Day 1 H 1930 2000 High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1930_2000_low',
    label: 'Day 1 H 1930 2000 Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1930_2000_close',
    label: 'Day 1 H 1930 2000 Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1930_2000_vol',
    label: 'Day 1 H 1930 2000 Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_h_1930_2000_vw',
    label: 'Day 1 H 1930 2000 VW',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_h_1930_2000_chg',
    label: 'Day 1 H 1930 2000 Chg',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_h_1930_2000_chg_pct',
    label: 'Day 1 H 1930 2000 Chg %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_1min_high',
    label: 'Day 1 1Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_1min_high_pct',
    label: 'Day 1 1Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_1min_low',
    label: 'Day 1 1Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_1min_low_pct',
    label: 'Day 1 1Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_1min_close',
    label: 'Day 1 1Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_1min_close_pct',
    label: 'Day 1 1Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_1min_vol',
    label: 'Day 1 1Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_2min_high',
    label: 'Day 1 2Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_2min_high_pct',
    label: 'Day 1 2Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_2min_low',
    label: 'Day 1 2Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_2min_low_pct',
    label: 'Day 1 2Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_2min_close',
    label: 'Day 1 2Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_2min_close_pct',
    label: 'Day 1 2Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_2min_vol',
    label: 'Day 1 2Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_3min_high',
    label: 'Day 1 3Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_3min_high_pct',
    label: 'Day 1 3Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_3min_low',
    label: 'Day 1 3Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_3min_low_pct',
    label: 'Day 1 3Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_3min_close',
    label: 'Day 1 3Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_3min_close_pct',
    label: 'Day 1 3Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_3min_vol',
    label: 'Day 1 3Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_4min_high',
    label: 'Day 1 4Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_4min_high_pct',
    label: 'Day 1 4Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_4min_low',
    label: 'Day 1 4Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_4min_low_pct',
    label: 'Day 1 4Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_4min_close',
    label: 'Day 1 4Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_4min_close_pct',
    label: 'Day 1 4Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_4min_vol',
    label: 'Day 1 4Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_5min_high',
    label: 'Day 1 5Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_5min_high_pct',
    label: 'Day 1 5Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_5min_low',
    label: 'Day 1 5Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_5min_low_pct',
    label: 'Day 1 5Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_5min_close',
    label: 'Day 1 5Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_5min_close_pct',
    label: 'Day 1 5Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_5min_vol',
    label: 'Day 1 5Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_10min_high',
    label: 'Day 1 10Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_10min_high_pct',
    label: 'Day 1 10Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_10min_low',
    label: 'Day 1 10Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_10min_low_pct',
    label: 'Day 1 10Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_10min_close',
    label: 'Day 1 10Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_10min_close_pct',
    label: 'Day 1 10Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_10min_vol',
    label: 'Day 1 10Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_15min_high',
    label: 'Day 1 15Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_15min_high_pct',
    label: 'Day 1 15Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_15min_low',
    label: 'Day 1 15Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_15min_low_pct',
    label: 'Day 1 15Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_15min_close',
    label: 'Day 1 15Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_15min_close_pct',
    label: 'Day 1 15Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_15min_vol',
    label: 'Day 1 15Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_30min_high',
    label: 'Day 1 30Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_30min_high_pct',
    label: 'Day 1 30Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_30min_low',
    label: 'Day 1 30Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_30min_low_pct',
    label: 'Day 1 30Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_30min_close',
    label: 'Day 1 30Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_30min_close_pct',
    label: 'Day 1 30Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_30min_vol',
    label: 'Day 1 30Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_60min_high',
    label: 'Day 1 60Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_60min_high_pct',
    label: 'Day 1 60Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_60min_low',
    label: 'Day 1 60Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_60min_low_pct',
    label: 'Day 1 60Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_60min_close',
    label: 'Day 1 60Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_60min_close_pct',
    label: 'Day 1 60Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_60min_vol',
    label: 'Day 1 60Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_90min_high',
    label: 'Day 1 90Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_90min_high_pct',
    label: 'Day 1 90Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_90min_low',
    label: 'Day 1 90Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_90min_low_pct',
    label: 'Day 1 90Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_90min_close',
    label: 'Day 1 90Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_90min_close_pct',
    label: 'Day 1 90Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_90min_vol',
    label: 'Day 1 90Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_120min_high',
    label: 'Day 1 120Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_120min_high_pct',
    label: 'Day 1 120Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_120min_low',
    label: 'Day 1 120Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_120min_low_pct',
    label: 'Day 1 120Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_120min_close',
    label: 'Day 1 120Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_120min_close_pct',
    label: 'Day 1 120Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_120min_vol',
    label: 'Day 1 120Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_150min_high',
    label: 'Day 1 150Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_150min_high_pct',
    label: 'Day 1 150Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_150min_low',
    label: 'Day 1 150Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_150min_low_pct',
    label: 'Day 1 150Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_150min_close',
    label: 'Day 1 150Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_150min_close_pct',
    label: 'Day 1 150Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_150min_vol',
    label: 'Day 1 150Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_180min_high',
    label: 'Day 1 180Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_180min_high_pct',
    label: 'Day 1 180Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_180min_low',
    label: 'Day 1 180Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_180min_low_pct',
    label: 'Day 1 180Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_180min_close',
    label: 'Day 1 180Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_180min_close_pct',
    label: 'Day 1 180Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_180min_vol',
    label: 'Day 1 180Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_210min_high',
    label: 'Day 1 210Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_210min_high_pct',
    label: 'Day 1 210Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_210min_low',
    label: 'Day 1 210Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_210min_low_pct',
    label: 'Day 1 210Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_210min_close',
    label: 'Day 1 210Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_210min_close_pct',
    label: 'Day 1 210Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_210min_vol',
    label: 'Day 1 210Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_240min_high',
    label: 'Day 1 240Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_240min_high_pct',
    label: 'Day 1 240Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_240min_low',
    label: 'Day 1 240Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_240min_low_pct',
    label: 'Day 1 240Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_240min_close',
    label: 'Day 1 240Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_240min_close_pct',
    label: 'Day 1 240Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_240min_vol',
    label: 'Day 1 240Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_270min_high',
    label: 'Day 1 270Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_270min_high_pct',
    label: 'Day 1 270Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_270min_low',
    label: 'Day 1 270Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_270min_low_pct',
    label: 'Day 1 270Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_270min_close',
    label: 'Day 1 270Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_270min_close_pct',
    label: 'Day 1 270Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_270min_vol',
    label: 'Day 1 270Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_300min_high',
    label: 'Day 1 300Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_300min_high_pct',
    label: 'Day 1 300Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_300min_low',
    label: 'Day 1 300Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_300min_low_pct',
    label: 'Day 1 300Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_300min_close',
    label: 'Day 1 300Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_300min_close_pct',
    label: 'Day 1 300Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_300min_vol',
    label: 'Day 1 300Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_330min_high',
    label: 'Day 1 330Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_330min_high_pct',
    label: 'Day 1 330Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_330min_low',
    label: 'Day 1 330Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_330min_low_pct',
    label: 'Day 1 330Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_330min_close',
    label: 'Day 1 330Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_330min_close_pct',
    label: 'Day 1 330Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_330min_vol',
    label: 'Day 1 330Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day0_360min_high',
    label: 'Day 1 360Min High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_360min_high_pct',
    label: 'Day 1 360Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_360min_low',
    label: 'Day 1 360Min Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_360min_low_pct',
    label: 'Day 1 360Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_360min_close',
    label: 'Day 1 360Min Close',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'hourlyMetrics'
  },
  {
    name: 'day0_360min_close_pct',
    label: 'Day 1 360Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_360min_vol',
    label: 'Day 1 360Min Vol',
    input: 'compare',
    numberType: 'volume',
    group: 'hourlyMetrics',
    integer: true
  },
  {
    name: 'day1_1min_high_pct',
    label: 'Day 2 1Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_1min_low_pct',
    label: 'Day 2 1Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_1min_close_pct',
    label: 'Day 2 1Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_2min_high_pct',
    label: 'Day 2 2Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_2min_low_pct',
    label: 'Day 2 2Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_2min_close_pct',
    label: 'Day 2 2Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_3min_high_pct',
    label: 'Day 2 3Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_3min_low_pct',
    label: 'Day 2 3Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_3min_close_pct',
    label: 'Day 2 3Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_4min_high_pct',
    label: 'Day 2 4Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_4min_low_pct',
    label: 'Day 2 4Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_4min_close_pct',
    label: 'Day 2 4Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_5min_high_pct',
    label: 'Day 2 5Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_5min_low_pct',
    label: 'Day 2 5Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_5min_close_pct',
    label: 'Day 2 5Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_15min_high_pct',
    label: 'Day 2 15Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_15min_low_pct',
    label: 'Day 2 15Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_15min_close_pct',
    label: 'Day 2 15Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_30min_high_pct',
    label: 'Day 2 30Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_30min_low_pct',
    label: 'Day 2 30Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_30min_close_pct',
    label: 'Day 2 30Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_60min_high_pct',
    label: 'Day 2 60Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_60min_low_pct',
    label: 'Day 2 60Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_60min_close_pct',
    label: 'Day 2 60Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_90min_high_pct',
    label: 'Day 2 90Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_90min_low_pct',
    label: 'Day 2 90Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_90min_close_pct',
    label: 'Day 2 90Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_120min_high_pct',
    label: 'Day 2 120Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_120min_low_pct',
    label: 'Day 2 120Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_120min_close_pct',
    label: 'Day 2 120Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_150min_high_pct',
    label: 'Day 2 150Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_150min_low_pct',
    label: 'Day 2 150Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_150min_close_pct',
    label: 'Day 2 150Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_180min_high_pct',
    label: 'Day 2 180Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_180min_low_pct',
    label: 'Day 2 180Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_180min_close_pct',
    label: 'Day 2 180Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_210min_high_pct',
    label: 'Day 2 210Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_210min_low_pct',
    label: 'Day 2 210Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_210min_close_pct',
    label: 'Day 2 210Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_240min_high_pct',
    label: 'Day 2 240Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_240min_low_pct',
    label: 'Day 2 240Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_240min_close_pct',
    label: 'Day 2 240Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_270min_high_pct',
    label: 'Day 2 270Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_270min_low_pct',
    label: 'Day 2 270Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_270min_close_pct',
    label: 'Day 2 270Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_300min_high_pct',
    label: 'Day 2 300Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_300min_low_pct',
    label: 'Day 2 300Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_300min_close_pct',
    label: 'Day 2 300Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_330min_high_pct',
    label: 'Day 2 330Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_330min_low_pct',
    label: 'Day 2 330Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_330min_close_pct',
    label: 'Day 2 330Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_360min_high_pct',
    label: 'Day 2 360Min High %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_360min_low_pct',
    label: 'Day 2 360Min Low %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day1_360min_close_pct',
    label: 'Day 2 360Min Close %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'hourlyMetrics',
    numberHighlighting: true
  },
  {
    name: 'day0_ema_9',
    label: 'Day 1 EMA 9',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day0_ema_9_dist_pct',
    label: 'Day 1 EMA 9 Dist %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'dailyIndicators',
    numberHighlighting: true
  },
  {
    name: 'day0_ema_21',
    label: 'Day 1 EMA 21',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day0_ema_21_dist_pct',
    label: 'Day 1 EMA 21 Dist %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'dailyIndicators',
    numberHighlighting: true
  },
  {
    name: 'day0_ema_50',
    label: 'Day 1 EMA 50',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day0_ema_50_dist_pct',
    label: 'Day 1 EMA 50 Dist %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'dailyIndicators',
    numberHighlighting: true
  },
  {
    name: 'day0_ema_72',
    label: 'Day 1 EMA 72',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day0_ema_72_dist_pct',
    label: 'Day 1 EMA 72 Dist %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'dailyIndicators',
    numberHighlighting: true
  },
  {
    name: 'day0_ema_89',
    label: 'Day 1 EMA 89',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day0_ema_89_dist_pct',
    label: 'Day 1 EMA 89 Dist %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'dailyIndicators',
    numberHighlighting: true
  },
  {
    name: 'day0_ema_100',
    label: 'Day 1 EMA 100',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day0_ema_100_dist_pct',
    label: 'Day 1 EMA 100 Dist %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'dailyIndicators',
    numberHighlighting: true
  },
  {
    name: 'day0_ema_200',
    label: 'Day 1 EMA 200',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day0_ema_200_dist_pct',
    label: 'Day 1 EMA 200 Dist %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'dailyIndicators',
    numberHighlighting: true
  },
  {
    name: 'day0_adtv_7',
    label: 'Day 1 ADTV 7',
    input: 'compare',
    numberType: 'volume',
    group: 'dailyIndicators',
    integer: true
  },
  {
    name: 'day0_adtv_14',
    label: 'Day 1 ADTV 14',
    input: 'compare',
    numberType: 'volume',
    group: 'dailyIndicators',
    integer: true
  },
  {
    name: 'day0_adtv_30',
    label: 'Day 1 ADTV 30',
    input: 'compare',
    numberType: 'volume',
    group: 'dailyIndicators',
    integer: true
  },
  {
    name: 'day0_adtv_60',
    label: 'Day 1 ADTV 60',
    input: 'compare',
    numberType: 'volume',
    group: 'dailyIndicators',
    integer: true
  },
  {
    name: 'day0_rvol_5',
    label: 'Day 1 RVOL 5',
    input: 'compare',
    numberType: 'volume',
    group: 'dailyIndicators',
    integer: true
  },
  {
    name: 'day0_atr_7',
    label: 'Day 1 ATR 7',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day0_atr_14',
    label: 'Day 1 ATR 14',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day0_atr_30',
    label: 'Day 1 ATR 30',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day0_atr_60',
    label: 'Day 1 ATR 60',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day_252_high',
    label: '1 Year High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day_252_low',
    label: '1 Year Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day_252_range',
    label: '1 Year Range %',
    pre: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'dailyIndicators'
  },
  {
    name: 'day_126_high',
    label: '6 Month High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day_126_low',
    label: '6 Month Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day_126_range',
    label: '6 Month Range %',
    pre: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'dailyIndicators'
  },
  {
    name: 'day_63_high',
    label: '3 Month High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day_63_low',
    label: '3 Month Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day_63_range',
    label: '3 Month Range %',
    pre: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'dailyIndicators'
  },
  {
    name: 'day_21_high',
    label: '30 Day High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day_21_low',
    label: '30 Day Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day_21_range',
    label: '30 Day Range %',
    pre: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'dailyIndicators'
  },
  {
    name: 'day_5_high',
    label: '1 Week High',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day_5_low',
    label: '1 Week Low',
    pre: '$',
    input: 'compare',
    numberType: 'currency',
    group: 'dailyIndicators'
  },
  {
    name: 'day_5_range',
    label: '1 Week Range %',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'dailyIndicators'
  },
  // {
  //   name: 'share_type',
  //   label: 'Share Type',
  //   input: 'singleSelect',
  //   group: 'fundamentals',
  //   options: shareTypeOptions
  // },
  {
    name: 'share_group',
    label: 'Share Group',
    input: 'singleSelect',
    group: 'fundamentals',
    options: shareGroupOptions
  },
  {
    name: 'exchange',
    label: 'Exchange',
    group: 'fundamentals',
    input: 'singleSelect',
    options: exchangeOptions
  },
  {
    name: 'sector',
    label: 'Sector',
    group: 'fundamentals'
  },
  {
    name: 'industry',
    label: 'Industry',
    group: 'fundamentals'
  },
  {
    name: 'ipo_date',
    label: 'IPO Date',
    hardFormat: true,
    group: 'fundamentals',
    input: 'dateCompare'
  },
  // {
  //   name: 'home_country',
  //   label: 'Country',
  //   group: 'fundamentals',
  //   input: 'singleSelect',
  //   options: countryOptions
  // },
  {
    name: 'country',
    label: 'Country',
    group: 'fundamentals',
    input: 'singleSelect',
    options: countryOptions
  },
  {
    name: 'sharesfloat',
    label: 'Float',
    input: 'compare',
    numberType: 'integer',
    group: 'fundamentals',
    integer: true
  },
  {
    name: 'sharesoutstanding',
    label: 'Shares Outstanding',
    input: 'compare',
    numberType: 'integer',
    group: 'fundamentals',
    integer: true
  },
  {
    name: 'percentinstitutions',
    label: 'Inst. Own',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'fundamentals'
  },
  {
    name: 'percentinsiders',
    label: 'Insider Own',
    post: '%',
    input: 'compare',
    numberType: 'percentage',
    group: 'fundamentals'
  },
  {
    name: 'marketcap',
    label: 'Market Cap',
    input: 'compare',
    numberType: 'integer',
    group: 'fundamentals',
    integer: true
  },
  {
    name: 'halts_count',
    label: 'Halt Count',
    input: 'compare',
    numberType: 'integer',
    group: 'fundamentals',
    integer: true
  },
  {
    name: 'first_halt',
    label: 'First Halt Time',
    input: 'time',
    numberType: 'time',
    group: 'fundamentals'
  },
  {
    name: 'last_halt',
    label: 'Last Halt Time',
    input: 'time',
    numberType: 'time',
    group: 'fundamentals'
  }
];

export const HISTORY_COLUMNS = mapSynonymsToHistory(historyColumns, HISTORY_SEARCH_SYNONYMS).map(col => { return { ...col, align: 'center' }; });
