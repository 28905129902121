
import {
  Box,
  IconButton,
  Typography,
  lighten,
  makeStyles,
  useTheme
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '&::after': {
      // little vertical seperator to the right of element
      content: "''",
      opacity: 1,
      backgroundColor: theme.palette.background.lightBorder,
      transition: 'opacity 150ms',
      position: 'absolute',
      height: '30%',
      width: 2,
      right: 1,
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '&::before': {
      // This exists only to cover up the previous elements 
      // seperator when active/hovered hovered.
      content: "''",
      zIndex: 1,
      opacity: 0,
      backgroundColor: theme.palette.background.c.paper[500],
      transition: 'opacity 150ms',
      position: 'absolute',
      height: '42%',
      width: 2,
      left: -2,
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '&.--active': {
      '&:hover': {
        '& $tabItemContentContainer': {
          backgroundColor: lighten(theme.palette.secondary.main, .04),
        },
      },
      '& $tabItemContentContainer': {
        backgroundColor: theme.palette.secondary.main
      },
      '&::after': {
        opacity: 0,
      },
      '&::before': {
        opacity: 1
      }
    },
    '&:hover': {
      '& $tabItemContentContainer': {
        backgroundColor: theme.palette.action.hover600,
      },
      '&::after': {
        opacity: 0,
      },
      '&::before': {
        opacity: 1
      },
      '&:has($removeButton:hover)': {
        '&:not(.--active) $tabItemContentContainer': {
          backgroundColor: 'transparent'
        },
        '&.--active $tabItemContentContainer': {
          backgroundColor: theme.palette.secondary.main
        }
      },
    },
    '&.--dragging': {
      '& $tabItemContentContainer': {
        backgroundColor: theme.palette.action.hover600,
      },
      '&::after': {
        opacity: 0,
      },
      '&::before': {
        opacity: 1
      },
    }
  },
  tabItemContentContainer: {
    transition: 'background-color 150ms',
    backgroundColor: 'transparent',
    borderRadius: theme.grid.borderRadius,
    padding: [[theme.spacing(.75), theme.spacing(1.25)]],
  },
  tabItemTextContainer: {
    overflowX: 'hidden',
    '-webkit-mask-image': 'linear-gradient(to right, black 80%, transparent 100%)',
    maskImage: 'linear-gradient(to bottom, black 80%, transparent 100%)',
    whiteSpace: 'nowrap'
  },
  title: {
    padding: 0,
    margin: 0,
    fontSize: 14,
    lineHeight: '20px',
    display: 'inline',
  },
  subtitle: {
    padding: 0,
    margin: 0,
    paddingLeft: 6,
    fontSize: 14,
    lineHeight: '20px',
    display: 'inline'
  },
  removeButton: {
    fontSize: 16,
    backgroundColor: 'transparent',
    transition: 'background-color 150ms',
    padding: 1,
    color: theme.palette.text.primary,
    opacity: 1,
    zIndex: 999,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    }
  },
}));



const getItemStyle = (snapshot, draggableStyle) => {
  const styleDefaults = {
    userSelect: 'none',
    opacity: snapshot.isDragging ? .6 : 1,
    ...draggableStyle
  };
  if (draggableStyle?.transform) {
    const axisLockX = `${draggableStyle.transform.split(',')
      .shift()}, 0px)`;
    return {
      ...styleDefaults,
      transform: axisLockX,
    };
  }
  return styleDefaults;
};

const getItemClasses = (snapshot) => {
  if (snapshot.isDragging) {
    return '--dragging'
  }
}



/**
 * @typedef {object} TabItem
 * @param {string} key
 * @param {string} title
 * @param {string} subtitle
 */


/**
 * @param {object} props
 * @param {string} props.className
 * @param {TabItem} props.item
 * @param {number} props.index
 * @param {boolean} props.active
 * @param {boolean} props.isDragDisabled
 * @param {function(TabItem)} props.onClick
 * @param {function(TabItem)} props.onRemove
 * @param {function(TabItem)} props.onReorder
 */
function TabsBarTabItem({
  className,
  item,
  index,
  active,
  isDragDisabled,
  onClick,
  onRemove,
}) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Draggable
      key={item.key}
      draggableId={item.key}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <Box
          className={clsx(
            className,
            classes.root,
            active && '--active',
            getItemClasses(snapshot)
          )}
          role="tab"
          tabIndex="0"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={() => onClick({ ...item })}
          ref={provided.innerRef}
          style={getItemStyle(snapshot, provided.draggableProps.style, theme)}
        >
          <Box
            className={classes.tabItemContentContainer}
            display="flex"
            flexWrap="nowrap"
          >
            <Box
              className={clsx(
                classes.tabItemTextContainer,
                classes.maskText
              )}
              flex="1"
            >
              <Typography variant="h5" color="textPrimary" className={classes.title}>{item.title}</Typography>
              {Boolean(item.subtitle) && <Typography variant="h6" color="textSecondary" className={classes.subtitle}>{item.subtitle}</Typography>}
            </Box>

            {Boolean(onRemove) && (
              <IconButton
                className={clsx(
                  classes.removeButton,
                )}
                size="small"
                aria-label="remove"
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove({ ...item })
                }}
              >
                <ClearIcon fontSize="inherit" />
              </IconButton>
            )}
          </Box>
        </Box>
      )}
    </Draggable>
  );
}


export default TabsBarTabItem;
