import React, { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _noop from 'lodash/noop';
import useParameterizedSelector from 'src/hooks/useParameterizedSelector';
import { addExpressionGridProperties, removeExpressionGridProperties } from './layoutExpressionConfig';
import {
  selectComponentUiById,
  selectComponentById,
  selectProfilesByComponent,
  makeSelectExpressionList
} from './layoutSelectors';
import * as layoutActions from './layoutActions';
import { bindNamespaceActionCreators } from './bindActionNamespace';
import { FormReferencesArgumentProvider } from 'src/app/slicedForm/shared/context/FormReferencesProvider';

/** @typedef {import('./reducerUtils.js').ValueOf} ValueOf*/
/** @typedef {import('./layoutExpressionConfig.js').ExpressionColDef} ExpressionColDef*/
/** @typedef {import('./layoutExpressionConfig.js').ExpressionPayload} ExpressionPayload*/
/** @typedef {import('./reducerUtils.js').ProfileSettings} ProfileSettings */
/** @typedef {import('./layoutSchema.js').ProfileStruct} ProfileStruct */
/** @typedef {import('./boundLayoutActions.jsdoc').BoundLayoutActionCreators} BoundLayoutActionCreators */
/** @typedef {import('./layoutSchema.js').ComponentMapItem} ComponentMapItem */
/** @typedef {import('./layoutSchema.js').SchemaApi} SchemaApi */
/** @typedef {import('./layoutSchema').ComponentMapItem} ComponentMapItem */
/** @typedef {import('./layoutSchema').ProfileConfigItem} ProfileConfigItem */

// Incorrect import direction here, but I can't faff about with types forever
/** @typedef {import('../toplist/boundWatchlistActions.jsdoc').BoundWatchlistActionCreators} BoundWatchlistActionCreators */
/** @typedef {import('./layoutItemActions/boundLayoutMapActions.jsdoc').BoundLayoutMapActionCreators} BoundLayoutMapActionCreators */
/** @typedef {import('./layoutItemActions/boundLayoutTabActions.jsdoc').BoundLayoutTabActionCreators} BoundLayoutTabActionCreators */
/** @typedef {import('./layoutItemActions/boundLayoutTreeActions.jsdoc').BoundLayoutTreeActionCreators} BoundLayoutTreeActionCreators */


const layoutNamespaceInitialState = {};
const layoutNamespaceApiInitialState = {};
const expressionNamespaceInitialState = {};
const componentInitialState = {};

const LayoutNamespaceContext = createContext(layoutNamespaceInitialState);
export const LayoutNamespaceApiContext = createContext(layoutNamespaceApiInitialState);
const LayoutExpressionNamespaceContext = createContext(expressionNamespaceInitialState);
const ComponentContext = createContext(componentInitialState);

/**
 * Settings global to each Layout namespace
 * @typedef {object} LayoutNamespaceProviderProps
 * @property {string} namespace
 * @property {{string: ComponentMapItem}} componentMap
 * @property {SchemaApi} schemaApi
 * @property {{string: ProfileSettings}} profileConfig - Not needed anymore, but we'll keep it. Profile config is now inside ComponentContext
 * @property {{profiles: function, expressions: function}} referencedLayoutEntitySelectors - Selector functions indexed by profileConfig.listKey, to get mapping of all profileIds -> layout/components
 * @property {Array.<{name: string, id: string}>} layoutTemplates - Needed for root Mosaic and creating new layouts
 */


/**
 * WARN: ALL VALUES SHOULD BE STATIC. Performance will be a problem otherwise.
 *
 * The root of a particular Namespace, like TopList or History.
 *
 * Provides its name and bound actions to all children. Provides 
 * differences between layouts like PROFILE_CONFIG, COMPONENT_MAP, etc.
 *
 * @param {React.PropsWithChildren<LayoutNamespaceProviderProps>} props
 */
export function LayoutNamespaceProvider({
  schemaApi,
  namespace,
  componentMap,
  referencedLayoutEntitySelectors,
  profileConfig,
  layoutTemplates,
  children,
}) {
  const namespaceValue = useMemo(() => ({
    namespace,
    schemaApi,
    componentMap,
    profileConfig,
    referencedLayoutEntitySelectors,
    layoutTemplates
  }), [
    namespace,
    schemaApi,
    componentMap,
    profileConfig,
    referencedLayoutEntitySelectors,
    layoutTemplates
  ]);
  const apiValue = useMemo(() => bindNamespaceActionCreators(layoutActions, namespace, schemaApi), [namespace, schemaApi]);

  return (
    <LayoutNamespaceContext.Provider value={namespaceValue}>
      <LayoutNamespaceApiContext.Provider value={apiValue}>
        {children}
      </LayoutNamespaceApiContext.Provider>
    </LayoutNamespaceContext.Provider>
  )
}


/** 
 * Returns all of our ActionCreators with the namespace attached.
 * The type hinting contains all *possible* action creators. A sub-set may be available
 * to you based on your Provider.
 * @returns {BoundLayoutActionCreators & Partial<BoundWatchlistActionCreators> & Partial<BoundLayoutMapActionCreators> & Partial<BoundLayoutTabActionCreators> & Partial<BoundLayoutTreeActionCreators>} CombinedBoundActionCreators
 */
export function useLayoutApi() {
  const api = useContext(LayoutNamespaceApiContext);
  if (api === layoutNamespaceApiInitialState) {
    throw new Error('useLayoutApi must be used within a LayoutNamespaceProvider');
  }
  return api;
}


/**
 * Gets Namespace-wide configuration objects
 * @returns {LayoutNamespaceProviderProps}
 */
export function useLayoutNamespaceConfig() {
  return useContext(LayoutNamespaceContext);
}


/**
 * Like useSelector, but bound to the current namespace context.
 * Allows you to pass reusable localized selector functions.
 * @example
 * <LayoutNamespaceProvider namespace="toplist">
 *   <Component />
 * </LayoutNamespaceProvider>
 *  
 * // Component.js
 * const components = useLayoutSelector(state => state.components)
 * // Actual selector that gets called:
 * state => state.toplist.components
 *
 * @param {function} selector
 */
export function useLayoutSelector(selector) {
  const ctx = useContext(LayoutNamespaceContext);
  return useSelector(state => selector(state[ctx.namespace]));
}


/**
 * Like useParameterizedSelector, but bound to the current namespace.
 * Caches the resulting value on the provided argument.
 * If multiple arguments must be passed, use a single object. Make sure to memo
 * that object if needed.
 *
 * @param {function} makeSelector
 * @param {*} argument
 *
 * @example
 * const makeSelector = () => createSelector(
 *  [state => state.something, (_, id) => id],
 *  (something, id) => something[id]
 * )
 * // Component wrapped in <LayoutNamespaceProvider namespace="toplist">
 * const Component = ({ id }) => {
 *  const value = useParameterizedLayoutSelector(makeSelector, id);
 * }
 * //
 */
export function useParameterizedLayoutSelector(makeSelector, argument) {
  const memoizedSelector = useMemo(() => {
    const selector = makeSelector();
    return state => selector(state, argument);
  }, [argument]);

  return useLayoutSelector(memoizedSelector);
}


/**
 * Sets the Expression namespace for all child components.
 * When using useLayoutExpression, this namespace will be used.
 *
 * Seperate from LayoutNamespaceProvider because expressions use a different
 * namespace entirely, and its relative to the component, not layout.
 * Toplist Ticker Stasts and History Records may use the same Expression
 * namespace.
 * 
 * Provide a null value to disable expressions.
 *
 * @param {React.PropsWithChildren<{namespace: string}>} props
 */
export function LayoutExpressionNamespaceProvider({ children, namespace }) {
  const value = useMemo(() => ({ namespace }), [namespace]);

  return (
    <LayoutExpressionNamespaceContext.Provider value={value}>
      {children}
    </LayoutExpressionNamespaceContext.Provider>
  )
}


/**
 * Select expressions and payload maker based on Context namespace.
 * makeExpressionPayload simply attaches the namespace to the payload.
 * @returns {[ExpressionColDef[], function(ExpressionColDef[]): ExpressionPayload, string]}
 */
export function useLayoutExpressions() {
  const { namespace } = useContext(LayoutExpressionNamespaceContext);
  const expressions = useParameterizedLayoutSelector(makeSelectExpressionList, namespace);

  const makeExpressionPayload = useMemo(() => {
    if (!namespace) {
      return _noop;
    }
    return (expressions) => {
      return {
        namespace,
        expressions: expressions.map(removeExpressionGridProperties)
      }
    }
  }, [namespace]);

  return useMemo(() => {
    return [
      expressions.map(addExpressionGridProperties),
      makeExpressionPayload,
      namespace
    ]
  }, [expressions, makeExpressionPayload, namespace])
}



export function ComponentProvider({
  children,
  layoutId,
  componentId,
  componentType,
  profileConfigMeta,
  intercom,
}) {
  const value = useMemo(() => ({
    layoutId,
    componentId,
    componentType,
    profileConfigMeta: profileConfigMeta || {},
    intercom: intercom || {}
  }), [
    layoutId,
    componentId,
    componentType,
    profileConfigMeta,
    intercom
  ]);

  return (
    <ComponentContext.Provider value={value}>
      {children}
    </ComponentContext.Provider>
  )
}



/**
 * Simplifies connecting a Component to FormReferencesArgument context. The context
 * itself should be unaware of Layout stuff, so we can do that mapping here.
 *
 * Wrap this around each form.
 *
 * Provides Expression and Profile reference warning data
 *
 * @param {object} props
 * @param {ProfileConfigItem} props.profileConfigItem
 * @param {*} props.children
 */
export function ComponentFormReferencesArgumentProviderConnector({
  profileConfigItem,
  children,
}) {
  const { componentId } = useComponent();
  const { referencedLayoutEntitySelectors } = useLayoutNamespaceConfig();

  return (
    <FormReferencesArgumentProvider
      componentId={componentId}
      profileListKey={profileConfigItem.listKey}
      profileGroupLabel={profileConfigItem.label}
      globalProfileReferencesSelector={referencedLayoutEntitySelectors[profileConfigItem.metaKey].profiles}
      globalExpressionReferenceSelector={referencedLayoutEntitySelectors[profileConfigItem.metaKey].expressions}
    >
      {children}
    </FormReferencesArgumentProvider>
  )
}



/** @typedef {import('./layoutSchema.js').ProfileConfigMeta} ProfileConfigMeta */

/**
 * Get a component's layoutId and componentId
 * @returns {{layoutId: string, componentId: string, componentType: string, profileConfigMeta: ProfileConfigMeta}}
 */
export function useComponent() {
  return useContext(ComponentContext);
}


/**
 * Get a component's data
 * @returns {object}
 */
export function useComponentData() {
  const { componentId } = useContext(ComponentContext);
  if (!componentId) {
    throw new Error('useLayoutComponent must be used within a LayoutComponentProvider');
  }
  return useLayoutSelector(selectComponentById(componentId));
}


/**
 * Special Profiles which are scoped to components, not global.
 * For history page.
 * @returns {{string: ProfileStruct}}
 */
export function useComponentProfiles() {
  const { componentId } = useContext(ComponentContext);
  if (!componentId) {
    throw new Error('useLayoutComponent must be used within a LayoutComponentProvider');
  }
  return useLayoutSelector(selectProfilesByComponent(componentId))
}


/**
 * Get a component's UI state. Does not get persisted to DB / localstorage.
 * @returns {object}
 */
export function useLayoutComponentUI() {
  const { componentId } = useContext(ComponentContext);
  if (!componentId) {
    throw new Error('useLayoutComponent must be used within a LayoutComponentProvider');
  }
  return useLayoutSelector(selectComponentUiById(componentId))
}


