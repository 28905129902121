import { getLeaves } from "react-mosaic-component";
import { createSelector } from "reselect";

/** @typedef {import("./layoutSchema").Layout} Layout */
/** @typedef {import('src/app/slicedForm/shared/reducers/profileTreeReducer.js').TreeItem} TreeItem */

/*
 * Selectors are LOCALIZED to namespace. We have hooks that are in charge of globalizing them.
 * So if you have:
 * {
 *  history: createLayoutReducer('history'),
 *  toplist: createLayoutReducer('toplist'),
 * },
 *
 * then these selectors just look like:
 * localState => localState.components
 *
 * it does NOT take state.history or state.toplist into account.
*/

const emptyObj = {};
const emptyArray = [];

export const selectIsInitialized = state => state.isFetching.initial;

/** @param {string} listKey */
export const selectProfileMapByKey = listKey => state => state.profileMap[listKey];

/** @param {string} listKey */
export const selectProfileTreeByKey = listKey => state => state.profileTree[listKey];

/** @param {string} listKey */
/** @returns {function(string): function} */
export const selectProfileByTypeAndID = listKey => id => state => state.profileMap[listKey]?.[id] || emptyObj;

/** @param {string} id */
export const selectComponentById = id => state => state.components[id] || emptyObj;

/** @param {string} id */
export const selectProfilesByComponent = id => state => {
  return state?.profilesByComponent?.[id] || emptyObj;
}

/** @param {string} id */
export const selectComponentUiById = id => state => state.ui.components[id] || emptyObj;

/** @param {string} id */
export const selectComponentLinkColorById = componentId => state => {
  return componentId !== 'zero-state' ? state.components[componentId].link : null;
};

export const selectComponents = state => state.components;

export const selectLayouts = state => state.layouts;

export const selectLayoutTabs = state => state.layoutTabs;

export const selectLayoutTreeItems = state => state.layoutTree.items;

export const selectLayoutTreeOpenState = state => state.layoutTree.openState;

export const selectLayoutTreeNewItemIndicators = state => state.ui.layoutTree.newItemIndicators || emptyArray;

export const selectShowComponentSelectOverlay = state => state.ui.showComponentSelectOverlay;

/** @param {string} color */
export const selectLinkDataByColor = color => state => state.links?.[color] || emptyObj;

/** @param {string} color */
export const selectLayoutLinkDataByColor = color => state => state.linksByLayout[state.activeLayout]?.[color] || emptyObj;

export const selectActiveLayoutId = state => state.activeLayout;

/**
 * Only applies on Desktop, on mobile we do not want the sidebar to persist open.
 * @param {object} state
 * @returns {boolean}
 */
export const selectLayoutDrawerOpen = state => state.globalSettings.layoutDrawerOpen;




/**
 * @param {object} state
 * @returns {{activeLayoutId: string, activeLayout: Layout}}
 */
export const selectActiveLayout = createSelector(
  [selectLayouts, selectActiveLayoutId],
  (layouts, activeLayoutId) => {
    return {
      activeLayoutId: activeLayoutId,
      activeLayout: layouts[activeLayoutId]
    }
  }
)

/**
 * Returns the component data for a Scoped component.
 * (aka hard-coded, unique, 1 per layout. Think history_filters)
 *
 * @param {string} componentType
 * @retuns {string|undefined}
 */
export const makeSelectScopedComponentIdByType = () => createSelector(
  [
    selectLayouts,
    selectActiveLayoutId,
    (_, componentType) => componentType
  ],
  (layouts, activeLayoutId, componentType) => {
    if (!layouts || !activeLayoutId || !componentType) return undefined;

    const activeLayout = layouts[activeLayoutId];
    return activeLayout?.scopedComponentIds?.[componentType];
  }
)



export const selectActiveLayoutComponents = createSelector(
  [selectActiveLayout, selectComponents],
  (activeLayout, components) => {
    if (!activeLayout || !components) return [];
    return getLeaves(activeLayout.activeLayout.currentNode).map(id => components?.[id]).filter(Boolean);
  }
)

/**
 * Count the number of components of each type for the current activeLayout
 */
export const selectActiveLayoutComponentTypeCount = createSelector(
  [selectActiveLayoutComponents],
  (components) => {
    return components.reduce((acc, component) => {
      return {
        ...acc,
        [component.type]: (acc[component.type] || 0) + 1
      };
    }, {});
  }
);


/**
 * Returns expressions ordered by the ordering array.
 * You probably don't need to interact with this. Use useLayoutExpressions instead.
 * @example
 * const expressions = useParameterizedSelector(makeSelectExpressionList, 'historyFull');
 */
export const makeSelectExpressionList = () => createSelector(
  [
    state => state.expressions,
    state => state.orderings.expressions,
    (_, namepsace) => namepsace
  ],
  (expressions, orderings, namespace) => {
    if (!namespace) return [];
    return orderings[namespace].map(id => expressions[namespace][id]);
  }
)


