import * as Sentry from '@sentry/react';
import _cloneDeep from 'lodash/cloneDeep';
import _isObject from 'lodash/isObject';
import { current, isDraft } from 'immer';
import { STRUCTURAL_TYPE_RULES, STRUCTURAL_TYPES, } from 'src/app/slicedForm/mapping/mappingDirections/index';
import { generateSimpleFilenameVerson } from 'src/utils/generateProfileFilenameVersion';
import shortUuid from 'short-uuid';
import {
  BOOLEAN_OPS,
  VALUE_TYPES,
  defaultFilterEntity,
  defaultAggregateEntity,
  defaultStatEntity,
} from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import {
  getActiveProfile,
} from 'src/app/slicedForm/shared/reducers/profileMapReducer';
import {
  addLeaf,
  createEntityAndLeaf,
  deleteLeaf,
  getParentLeaf,
  getAllDescendants,
  getLeaf,
  copyEntity,
  replaceLeaf,
  createEntityValues,
  createEntityVal,
  getEntityVal,
  createEntityId,
} from 'src/app/slicedForm/mapping/formTreeManipulation';

/**
 * @typedef {import('src/app/slicedForm/utility/filterTimeSliceFunctions.js').Slice} Slice
 */


/**
 * Helpers to actually do the reducer modification, in cases where logic is shared between actions.
 **/



window.printprox = (el) => {
  console.log(isDraft(el) ? current(el) : el);
};



export const ENTITY_PATHS_CAN_CONTAIN_EXPRESSIONS = [
  'left.column',
  'right[0].column',
  'right[1].column',
  'target.column'
];


export const STRUCTURAL_TYPES_CAN_CONTAIN_EXPRESSIONS = [
  STRUCTURAL_TYPES.FILTER,
  STRUCTURAL_TYPES.CONDITIONAL_AGG,
];


export const isValidNumer = (val) => typeof val === 'number';


export const exists = (val) => {
  return val !== undefined && val !== null;
}


export const validateEntity = (entities, id) => {

  const getVal = (path) => {
    return getEntityVal(entities[id]?.[path]);
  }


  const errors = {};
  const entity = entities[id];

  if (!entity) {
    return;
  }

  // left
  if (getVal('left.type') === VALUE_TYPES.value && !exists(getVal('left.value'))) {
    errors['left.column'] = 'Value required'
  }
  else if (getVal('left.type') === VALUE_TYPES.column && !getVal('left.column')) {
    errors['left.column'] = 'Column required';
  }

  // right
  let rights = getVal('operator') === BOOLEAN_OPS.BTW
    ? ['0', '1']
    : ['0'];

  for (const idx of rights) {
    if (getVal(`right[${idx}].type`) === VALUE_TYPES.value && !exists(getVal(`right[${idx}].value`))) {
      errors[`right[${idx}].value`] = 'Value required';
    } else if (getVal(`right[${idx}].type`) === VALUE_TYPES.value && Array.isArray(getVal(`right[${idx}].value`)) && !getVal(`right[${idx}].value`).length) {
      errors[`right[${idx}].value`] = 'Value required';
    } else if (getVal(`right[${idx}].type`) === VALUE_TYPES.column && !getVal(`right[${idx}].column`)) {
      errors[`right[${idx}].column`] = 'Column required';
    }
  }

  // slice
  if ('startTime' in entity && !getVal('startTime')) {
    errors['startTime'] = 'Time required';
  }

  Object.keys(entity).forEach(path => {
    if (!(path in errors) && entity[path]?.err) {
      entity[path].err = null;
    }
  });
  Object.keys(errors).forEach(errPath => {
    if (entity[errPath]) {
      entity[errPath].err = errors[errPath];
    } else {
      entity[errPath] = { err: errors[errPath] };
    }
  })
}


/**
 * Delete an entity and all its children, and resolve any parent group changes.
 * @param {string} id - the entity ID to delete
 * @param {string} groupId - the parent group ID
 * @param {object} entities - the draft entities
 * @param {object} root - the draft root
 **/
export function doDeleteEntityAndResolveParents(
  id,
  groupId,
  entities,
  root,
) {
  /**
   * When a child group is modified, that might require changes to the parent group.
   * So on and so forth up the tree.
   * @param {Leaf} parent - the parent group to check
   **/
  const recursivlyModifyParentsIfNeeded = (parent) => {
    // Do not modify the AND/ROOT group. It's the root.
    if (!_isObject(parent) || !parent?.type || parent.type === STRUCTURAL_TYPES.ROOT) return;

    const { tree, id: gId, type: gType } = parent;

    const nextParent = getParentLeaf(root, gId);
    const rules = STRUCTURAL_TYPE_RULES?.[gType] || {};

    const { minChildren, onDeleteModifyChildren } = rules;

    let hasChanged = false;

    if (tree.length >= minChildren) return; // no modify

    if (tree?.length === 0) {
      // If there's no elements in the group, delete it.
      deleteLeaf(root, gId);
      delete entities[gId];
      hasChanged = true;
    } else {
      /*
      If there's only 1 element left in an SLICE/OR group,
      delete the group and move the element up. A single element is invalid for
      those two group types.
      */
      const childToKeep = tree[0];

      replaceLeaf(
        root,
        gId,
        childToKeep
      );

      if (onDeleteModifyChildren && onDeleteModifyChildren.length) {
        onDeleteModifyChildren.forEach(({ type, prop }) => {
          if (type === 'delete_prop') {
            delete entities[childToKeep.id][prop];
          }
        })
      }

      if (childToKeep?.type === STRUCTURAL_TYPES.FILTER) {
        validateEntity(entities, childToKeep.id)
      }

      hasChanged = true;
    }

    if (hasChanged) {
      // We modified the parent group's children, so check again.
      // This does mean profiles previously in invalid state will remain invalid, 
      // until user deletes child. Whatever.
      recursivlyModifyParentsIfNeeded(nextParent);
    }

  }

  const parentGroup = getLeaf(root, groupId);

  // If the entity is the first child of a SLICE_GROUP,
  // we need to delete the entire group. This is a UX decision.
  //
  // Different from above, because the group can have any number of elements. If the user 
  // deletes the first one, delete them all

  if (parentGroup && parentGroup?.type === STRUCTURAL_TYPES.SLICE_GROUP && parentGroup?.tree?.length) {
    const firstChild = parentGroup.tree[0];

    if (firstChild.id === id) {
      // delete group, and all children (including the current ID)
      const parentParent = getParentLeaf(root, groupId);
      const deletedGroup = deleteLeaf(root, groupId);

      delete entities[groupId];
      for (const childNode of getAllDescendants(deletedGroup)) {
        delete entities[childNode.id];
      }

      // The parent OR group might be invalid now
      recursivlyModifyParentsIfNeeded(parentParent);

      return;
    }
  }

  // delete leaf
  const deletedLeaf = deleteLeaf(root, id);
  if (!deletedLeaf) {
    console.warn(`Could not delete leaf ${id}`);
    return;
  }

  delete entities[id];

  // delete child entities
  for (const childNode of getAllDescendants(deletedLeaf)) {
    delete entities[childNode.id];
  }

  // Recurse up the tree to see if deletions/changes need to be made
  recursivlyModifyParentsIfNeeded(parentGroup);
}




/**
 * When profiles are copied, we need to also copy all entities/nodes.
 * Then we need to replace all the old entitiy IDs with the copied ones.
 * @param {Object} entities (immer object)
 * @param {Object} root (immer object)
 */
export function doCopyAndReferenceEntities(entities, root, removeErrors = true) {
  const recurse = (node) => {
    if (node?.id) {
      let newId;
      if (entities?.[node.id]) {
        // copy an entity for FILTER nodes
        const { id, entity } = copyEntity(entities[node.id], removeErrors);
        newId = id;

        if ('conditional_agg' in entity) {
          // Aggs have their own persistent ID. We probably don't want to copy it, make a new one.
          // Technically copying it woudldn't hurt, but would be confusing to debug.
          entity['name'] = createEntityVal(shortUuid.generate());
        }

        entities[newId] = entity;
        validateEntity(entities, newId);
      } else {
        // generate an ID for STRUCTURAL nodes
        newId = createEntityId();
      }
      node.id = newId;
    }
    if (node?.tree) {
      node.tree.forEach(recurse);
    }
  }

  recurse(root);
}


/**
 * Add a blank SLICE filter to an existing group. Called after CREATE_SLICE_GROUP
 * to give the user another input, and during CREATE_SLICE as the main behavior.
 * @param {Object} draft
 * @param {Object} action
 * @returns {boolean} - success or failure
 */
export function doCreateEmptySlice(draft, action) {
  // Add a new blank node to the given SLICE_GROUP.
  const { groupId, data = {} } = action.payload;

  const profile = getActiveProfile(draft);
  if (!profile) return false;

  const sliceGroupLeaf = getLeaf(profile.root, groupId);
  if (!sliceGroupLeaf) {
    console.warn('No slice group leaf for id', groupId);
    return false;
  }


  const prevSliceLeaf = sliceGroupLeaf.tree?.[sliceGroupLeaf.tree.length - 1];

  const prevSliceEntity = draft.entities?.[prevSliceLeaf?.id];
  if (!prevSliceEntity) {
    console.warn(`No first slice node found for group ${groupId}`);
    return false;
  }

  // copy first slice settings
  const newEntityValues = _cloneDeep(prevSliceEntity);
  newEntityValues.startTime = createEntityVal(null);


  const { id: newFilterId, entity: newFilterEntity, leaf: newFilterLeaf } = createEntityAndLeaf({
    type: STRUCTURAL_TYPES.FILTER,
    values: newEntityValues
  });

  const success = addLeaf(
    profile.root,
    groupId,
    newFilterLeaf,
  );

  if (success) {
    draft.entities[newFilterId] = newFilterEntity;
    validateEntity(draft.entities, newFilterId);
  } else {
    console.warn('Failed to add leaf', newFilterId, groupId);
  }

  return success;
}

/**
 * Create a new OR_GROUP
 * @param {Object} profile - the draft active profile
 * @param {Object[]} entities - the draft entities
 * @param {Leaf} filterLeaf - the leaf of the filter to be grouped
 * @returns {(Leaf|undefined)} - The new OR group leaf (if created)
 */
export function doCreateOrGroup(
  profile,
  entities,
  filterLeaf
) {
  // Place the given ID node into a new OR group as the first element.
  const { id: groupId, entity: groupEntity, leaf: groupLeaf } = createEntityAndLeaf({
    type: STRUCTURAL_TYPES.OR,
    tree: [filterLeaf]
  });

  const success = replaceLeaf(
    profile.root,
    filterLeaf.id,
    groupLeaf
  );

  if (!success) {
    console.warn('Failed to replace leaf', filterLeaf.id, groupId);
  }

  return groupLeaf;
}


export function doSwapFromSliceToRegular(id, parentLeaf, profile, entities) {
  // We are updating to a column that doesn't allow slices, but we're in a slice group.
  const childToKeep = parentLeaf.tree[0]; // same entity as action.payload.id
  const childrenToDelete = parentLeaf.tree.slice(1);

  // slice group children never have any descendants, but that might change in the future
  let allDescendantsToDelete = []
  for (const child of childrenToDelete) {
    allDescendantsToDelete.push(child, ...getAllDescendants(child));
  }

  // We don't need deleteLeaf(), because we already know all leaves live inside parent slice group.
  // Replace the slice group with the first filter.
  const success = replaceLeaf(
    profile.root,
    parentLeaf.id,
    childToKeep
  );

  if (success) {
    // Delete all the children
    for (const child of allDescendantsToDelete) {
      delete entities[child.id];
    }
    delete entities[childToKeep.id]['startTime']
    validateEntity(entities, childToKeep.id)
  } else {
    Sentry.captureException(`doSwapFromSliceToRegular, Failed to replace leaf ${parentLeaf.id} with ${childToKeep.id}. Slice group deletion failed.`);
  }
}




/**
 * If right[0].value > right[1].value, swap the values.
 * This might not belong here. Validation feels like a UI decision. But it would mean
 * creating a new BETWEEN component that holds and dispatches the values temporarily. Not sure its 
 * worth the effort.
 * @param {Object} entities 
 * @param {string} id 
 * @returns  {bool} true if swapped
 */
export const swapBetweenValuesIfNeeded = (entities, id) => {
  const entity = entities?.[id];
  if (!entity) return false;
  if (!getEntityVal(entity?.['operator']) === BOOLEAN_OPS.BTW) return;
  if (!isValidNumer(getEntityVal(entity?.['right[0].value'])) || !isValidNumer(getEntityVal(entity?.['right[1].value']))) return false;

  if (entity['right[0].value'].val <= entity['right[1].value'].val) return false;

  const larger = entity['right[0].value'].val;
  const smaller = entity['right[1].value'].val;
  entities[id]['right[0].value'].val = smaller;
  entities[id]['right[1].value'].val = larger;
}


/**
 * Create a FILTER, and add it to the given groupId as a child.
 * @param {Object} profile - the draft active profile
 * @param {Object[]} entities - the draft entities
 * @param {string} groupId - the leaf of the filter to be grouped
 * @param {Object} [data=defalutFilterEntity] - arbitrary node data for the new filter. Nested, not flat.
 * @returns {(Leaf|undefined)} - The new filter leaf (if created)
 **/
export function doCreateFilter(
  profile,
  entities,
  groupId,
  data = defaultFilterEntity
) {
  if (groupId === 'ROOT') {
    // Performance enhancement, allows components to avoid selecting groupId
    groupId = profile.root.id;
  }

  const { id: newId, entity: newEntity, leaf: newLeaf } = createEntityAndLeaf({
    type: STRUCTURAL_TYPES.FILTER,
    values: { ...createEntityValues(data) }
  });

  const success = addLeaf(
    profile.root,
    groupId,
    newLeaf
  );

  if (success) {
    entities[newId] = newEntity;
    validateEntity(entities, newId);
    return newLeaf;
  }
}


/**
 * Create an AGGREGATE, and add it to the given groupId as a child.
 * @param {Object} profile - the draft active profile
 * @param {Object[]} entities - the draft entities
 * @param {string} groupId - the leaf of the filter to be grouped
 * @param {Object} [data=defalutAggregateEntity] - arbitrary node data for the new filter. Nested, not flat.
 * @returns {(Leaf|undefined)} - The new filter leaf (if created)
 */
export function doCreateConditionalAgg(
  profile,
  entities,
  groupId,
  data = defaultAggregateEntity
) {
  if (groupId === 'ROOT') {
    // Performance enhancement, allows components to avoid selecting groupId
    // UNUSED. Aggs not valid here at the moment.
    groupId = profile.root.id;
  }

  const { tree, ...rest } = data;

  const { id: newId, entity: newEntity, leaf: newLeaf } = createEntityAndLeaf({
    type: STRUCTURAL_TYPES.CONDITIONAL_AGG,
    values: createEntityValues({
      name: shortUuid.generate(), // For associating queries with labels. Seperate from entity ID. Can be overriden by data arg.
      ...rest
    }),
    tree
  });

  const success = addLeaf(
    profile.root,
    groupId,
    newLeaf
  );

  if (success) {
    entities[newId] = newEntity;
    return newLeaf;
  }
}



/**
 * Create a keystats STAT, and add it to the given groupId as a child.
 * @param {Object} profile - the draft active profile
 * @param {Object[]} entities - the draft entities
 * @param {string} groupId - the leaf of the filter to be grouped
 * @param {Object} [data=defalutAggregateEntity] - arbitrary node data for the new filter. Nested, not flat.
 * @returns {(Leaf|undefined)} - The new filter leaf (if created)
 */
export function doCreateStat(
  profile,
  entities,
  groupId,
  data = defaultStatEntity
) {
  if (groupId === 'ROOT') {
    return undefined
  }

  const { id: newId, entity: newEntity, leaf: newLeaf } = createEntityAndLeaf({
    type: STRUCTURAL_TYPES.STAT,
    values: createEntityValues({
      ...data
    }),
  });

  const success = addLeaf(
    profile.root,
    groupId,
    newLeaf
  );

  if (success) {
    entities[newId] = newEntity;
    return newLeaf;
  }
}


/**
 * Create a VISUAL_GROUP, and add it to the given groupId as a child.
 * @param {Object} profile - the draft active profile
 * @param {Object[]} entities - the draft entities
 * @param {string} groupId - the leaf of the filter to be grouped
 * @param {Object} [data=defalutAggregateEntity] - arbitrary node data for the new filter. Nested, not flat.
 * @returns {(Leaf|undefined)} - The new filter leaf (if created)
 */
export function doCreateVisualGroup(
  profile,
  entities,
  groupId,
  label
) {
  const groupLeaf = getLeaf(profile.root, groupId);

  const siblingLabels = groupLeaf.tree.map(({ id }) => getEntityVal(entities[id]?.label)).filter(Boolean);

  const newLabel = generateSimpleFilenameVerson(label, siblingLabels);

  const { id: newId, entity: newEntity, leaf: newLeaf } = createEntityAndLeaf({
    type: STRUCTURAL_TYPES.VISUAL_GROUP,
    values: { ...createEntityValues({ label: newLabel }) },
    tree: []
  });

  const success = addLeaf(
    profile.root,
    groupId,
    newLeaf
  );

  if (success) {
    entities[newId] = newEntity;
    return newLeaf;
  }
}


