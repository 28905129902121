import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core'; // package is implicitly available, ignore the linter warning.
import '@ag-grid-community/styles/ag-grid.css';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { LicenseManager as ChartLicenseManager } from "ag-charts-enterprise";
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider,
  CssBaseline
} from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import 'react-mosaic-component/react-mosaic-component.css';
import { Router } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import 'src/app/components/css/ag-theme-ett.css';
import 'src/app/components/css/datepicker-theme-ett.css';
import 'src/app/components/css/mosaic-theme-ett.css';
import 'src/app/components/DrillDownMenu/DrillDownMenu.css'; // I can't put this in my component, I don't know why.
import ErrorPage from 'src/app/components/ErrorPage';
import ConditionalWrapper from 'src/app/components/utility/ConditionalWrapper';
import Auth from 'src/components/Auth';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import Routes from 'src/Routes';
import SnackbarNotifications from 'src/SnackbarNotifications';
import { createTheme } from 'src/theme';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/swiper.min.css';

LicenseManager.setLicenseKey('CompanyName=Edge to Trade,LicensedApplication=Edge to Trade,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-035489,SupportServicesEnd=2_December_2023_[v2]_MTcwMTQ3NTIwMDAwMA==bdf9be395ec266626f93d81c71114f06');


export const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins] });

console.debug(ChartLicenseManager); // dummy, this must be imported and nothing else. This is just preventing linter from removing it.

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  ClientSideRowModelModule,
  RowGroupingModule,
  MenuModule,
  // CsvExportModule
]);

const intercomAutoBootProps = {
  hideDefaultLauncher: true
};

const useStyles = makeStyles((theme) => {
  return createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      },
    },
  });
});


const useSnackbarStyles = makeStyles((theme) => ({
  snackbarRoot: {
    '& > div': {
      fontSize: '.85rem'
    }
  },
  snackbarError: {
    backgroundColor: theme.palette.snackbar.error,
  },
  snackbarSuccess: {
    backgroundColor: theme.palette.snackbar.success,
  },
}));



/** allows us to use theme */
function SnackbarWrapperProvider({ children }) {
  const classes = useSnackbarStyles();

  return (
    <SnackbarProvider
      maxSnack={1}
      classes={{
        root: classes.snackbarRoot,
        variantError: classes.snackbarError,
        variantSuccess: classes.snackbarSuccess,
      }}
    >
      <SnackbarNotifications />
      {children}
    </SnackbarProvider>
  )
}



function App() {
  const classes = useStyles();
  const { settings } = useSettings();


  return (
    <ThemeProvider theme={createTheme(settings)}>
      <CssBaseline />
      <ConditionalWrapper
        condition={process.env.REACT_APP_USERS_LAMBDA_STAGE !== 'local'}
        wrapper={children => <Sentry.ErrorBoundary fallback={<ErrorPage />}>{children}</Sentry.ErrorBoundary>}
      >
        <StylesProvider jss={jss}>
          <IntercomProvider
            appId={process.env.REACT_APP_INTERCOM_WORKSPACE_APP_ID}
            autoBoot
            autoBootProps={intercomAutoBootProps}
          >
            <SnackbarWrapperProvider>
              <Router history={history}>
                <Auth>
                  <ScrollReset />
                  <DndProvider backend={HTML5Backend} key={1} debugMode={true}>
                    <Routes />
                  </DndProvider>
                  {/* <GoogleAnalytics /> */}
                  {/* <CookiesNotification /> */}
                  {/* <SettingsNotification /> */}
                </Auth>
              </Router>
            </SnackbarWrapperProvider>
          </IntercomProvider>
        </StylesProvider>
      </ConditionalWrapper>
    </ThemeProvider>
  );
}

export default App;


