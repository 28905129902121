import { createSelector } from 'reselect';
import { current, isDraft } from 'immer';
import { FILTER_FORM_TYPES, STRUCTURAL_TYPES, } from 'src/app/slicedForm/mapping/mappingDirections/index';
import { getMarketStartString } from '../../utility/filterTimeSliceFunctions';
import {
  AGGREGATES,
  allowedAggregatesForInputType,
  BOOLEAN_OPS,
  DATE_TYPES,
  ROLLING_DATE_OPS,
  VALUE_TYPES,
} from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import {
  getActiveProfile,
  selectActiveProfile,
  COPY_PROFILE,
  ADD_PROFILE
} from '../../shared/reducers/profileMapReducer';
import {
  createEntityAndLeaf,
  getParentLeaf,
  getAllDescendants,
  getLeaf,
  replaceLeaf,
  getSliceChildrenLeaves,
  createEntityValues,
  createEntityVal,
  getEntityVal,
} from 'src/app/slicedForm/mapping/formTreeManipulation';
import {
  doDeleteEntityAndResolveParents,
  swapBetweenValuesIfNeeded,
  validateEntity,
  doCopyAndReferenceEntities,
  doCreateEmptySlice,
  doCreateOrGroup,
  doSwapFromSliceToRegular,
  doCreateFilter,
  doCreateConditionalAgg,
  doCreateStat,
  doCreateVisualGroup,
  STRUCTURAL_TYPES_CAN_CONTAIN_EXPRESSIONS,
  ENTITY_PATHS_CAN_CONTAIN_EXPRESSIONS,

} from './filterReducerHelpers';
import { DELETE_EXPRESSION } from '../../shared/reducers/expressionReducer';

/**
 * @typedef {import('src/app/slicedForm/utility/filterTimeSliceFunctions.js').Slice} Slice
 */




/**
 * State revolves around two structures. 
 *  1) A flat map of entities to attributes
 *    { id_1: { 'left.column': 'col1', 'right[0].column' = 'col2', 'right[1].column': 'col3' }}
 *  2) A recursive tree structure of leaf nodes
 *  { id: 'root', tree: [{ id: 'child1', tree: [{ id: 'child2' }] }] }
 * 
 * Leafs refer to entities to get thier data. The tree specifies rendering order and location.
 * 
 * Lots of these updates are very manual, and somewhat painful. I'm not sure if we've chosen the wrong structure,
 * or if thats how tree manipulation feels when you can't use object references like a normal tree. All updates 
 * must operate on serializable data, effecitvely we're manipulating JSON.
 * 
 * The hard part is that these GROUPS feel simple, but hide a huge amount of structural complexity. SLICE_GROUPS and OR groups and AND groups
 * all have different rules. Creating a GROUP might actually have 3 or 4 context-dependant rules, that aren't obvious to a programmer.
 * 
 * example: 
 *  Click the OR button. What happens?
 *    - Is the parent an AND? 
 *        1) Replace the clicked FILTER with a new OR
 *        2) Add the clicked FILTER as a child of the new OR
 *        3) Add a new empty FILTER as the second child of the OR
 *    - Is the parent an OR?
 *        1) Add a new empty FILTER as next child of OR
 *    - Is the parent a SLICE?
 *       - Is the grandparent an AND?
 *          1) Replace the entire SLICE_GROUP node with an OR
 *          2) Add the SLICE_GROUP as the first child of the OR
 *          3) Add a new empty FILTER as the second child of the OR, but not the SLICE
 *       - Is the grandparent an OR?
 *          1) Create a blank filter in the OR, but not the SLICE
 *          
 * 
 * One potential update would be to store things in a truly flat structure. Just entities, with child_ids (and maybe parent_id).
 * I'm not sure this would make updating easier, tree manipulation isn't really the hard part. It would be more
 * performant however, since modifying tree structure wouldn't cause a full re-render of the form.
 * 
 * As it stands, these structural re-renders aren't that bad. Updating filter values is fast, and thats what matters.
 */


/** Take the given FILTER, and wrap it within a new SLICE_GROUP */
export const CREATE_SLICE_GROUP = '@form-filter/CREATE_SLICE_GROUP';
/** Add another child to an existing SLICE_GROUP */
export const CREATE_SLICE = '@form-filter/CREATE_SLICE';
/** Take the given FILTER, and wrap within a new OR group */
export const CREATE_OR_GROUP = '@form-filter/CREATE_OR_GROUP';
/** Add a new filter to the parent group. Can be AND or OR. */
export const CREATE_FILTER = '@form-filter/CREATE_FILTER';
/** Add a new label, group element (around Aggregates) **/
export const CREATE_VISUAL_GROUP = '@form-filter/CREATE_VISUAL_GROUP';
/** Add a new Agg Group/Entity to the parent */
export const CREATE_CONDITIONAL_AGG = '@form-filter/CREATE_CONDITIONAL_AGG';
/** Keystats object */
export const CREATE_STAT = '@form-filter/CREATE_STAT';
/** Delete an entity and its descendants, from both the tree and the entities. */
export const DELETE_ENTITY = '@form-filter/DELETE_ENTITY';
/** Reorders leaves within a given groupId's tree */
export const REORDER_LEAF = '@form-filter/REORDER_LEAF';
/** Move a node from one leaf at index A to new leaf at index B. */
export const REPARENT_LEAF = '@form-filter/REPARENT_LEAF';

export const UPDATE_ENTITY_VALUE = '@form-filter/UPDATE_ENTITY_VALUE';



function filterReducer(draft, action) {

  switch (action.type) {

    /**
     * DUPLICATE ACTION. SEE profileReducer.js
     * We need to copy entities, otherwise two profiles can modify eachother.
     */
    case ADD_PROFILE: {
      const { id, newId } = action.payload;

      if (!newId) {
        console.warn(new Error('filterReducer.ADD_PROFILE requires newId'));
        return draft;
      }

      const newProfile = draft.profileMap[newId];
      if (!newProfile) {
        console.warn('filterReducer.ADD_PROFILE could not find newId profile');
        return draft;
      }

      doCopyAndReferenceEntities(draft.entities, newProfile.root, false);

      break;
    }

    /**
     * DUPLICATE ACTION. SEE profileReducer.js
     * We need to copy entities, otherwise two profiles can modify eachother.
     */
    case COPY_PROFILE: {
      const { id, name, newId } = action.payload;

      if (!newId) {
        console.warn(new Error('filterReducer.COPY_PROFILE requires newId'));
        return draft;
      }

      const newProfile = draft.profileMap[newId];
      if (!newProfile) {
        console.warn('filterReducer.COPY_PROFILE could not find newId profile');
        return draft;
      }

      doCopyAndReferenceEntities(draft.entities, newProfile.root, false);

      break;
    }


    /**
     * DUPLICATE ACTION. SEE expressionReducer.js
     * Find all references in all profiles and delete. 
     * Bear in mind groups must be resolved like DELETE_ENTITY.
     **/
    case DELETE_EXPRESSION: {
      const expressionName = action.payload;
      if (!expressionName) return;

      Object.values(draft.profileMap).forEach((profile) => {
        if (profile.predefined) return;

        const filterLeaves = getAllDescendants(profile.root).filter(leaf => {
          return STRUCTURAL_TYPES_CAN_CONTAIN_EXPRESSIONS.includes(leaf.type)
        });


        const leavesWithExpr = filterLeaves.filter(leaf => {
          const entity = draft?.entities?.[leaf.id] || {};
          return ENTITY_PATHS_CAN_CONTAIN_EXPRESSIONS.some(path => getEntityVal(entity?.[path]) === expressionName);
        });

        leavesWithExpr.forEach(leaf => {
          const groupLeaf = getParentLeaf(profile.root, leaf.id);
          console.debug('DELETE_EXP INSIDE slicedForm Filters: ATTEMPTING DELETE:', expressionName, profile.name, current(leaf), current(draft.entities[leaf.id]));
          doDeleteEntityAndResolveParents(leaf.id, groupLeaf?.id, draft.entities, profile.root);
        });

      });

      break;
    }

    /*
     This is absolutely miserable.
     I can't believe how much I hate this.

     The only alternative is to batch multiple updates inside a useEntity.setValues([{ id, path, value, options }, ...])
     This moves all complexity to the component instead.
     Problems are:
       validation and ref tracking becomes rediculous. 
       Can't traverse the tree from the component.
       Can't easily deal with tree modifications/slices. Whats the parent? Whats the order?
         Something like, every component would need a reference to all its slice siblings at all times.
         Seems real bad.
    */
    case UPDATE_ENTITY_VALUE: {
      const {
        id,
        path,
        value,
        colDef,
        columnDefaults,
        removeFalsy = false,
        defaultDateValue,
      } = action.payload;

      const profile = getActiveProfile(draft);
      const parentLeaf = getParentLeaf(profile.root, id);
      const isSliceGroup = parentLeaf.type === STRUCTURAL_TYPES.SLICE_GROUP;

      let idsToUpdate = [id];
      let pathValues = { [path]: { val: value, removeFalsy } }

      const pathsUniqueToSlice = [
        'right[0].value',
        'right[0].column',
        'right[1].value',
        'right[1].column',
        'startTime'
      ]

      // Conditional modifications to the tree
      if (isSliceGroup) {
        if (path === 'left.column' && colDef && !colDef?.timeSlice) {
          // Delete slice group and children. Lift first child up
          doSwapFromSliceToRegular(id, parentLeaf, profile, draft.entities)
        } else {
          // New column has slices. must propagate value to slice children.
          if (!pathsUniqueToSlice.includes(path)) {
            idsToUpdate = parentLeaf.tree.map(leaf => leaf.id);
          }
        }
      }

      if (path === 'left.column') {
        // The caller wants to reset the right column to the default.
        if (columnDefaults && Object.keys(columnDefaults)) {
          const newPaths = createEntityValues(columnDefaults);
          Object.entries(newPaths).forEach(([path, value]) => {
            pathValues[path] = value;
          })
        } else {
          // Remove right.column for safety, we don't know if the comparison is valid without a lot
          // of work
          pathValues['right[0].column'] = { val: null };
          pathValues['right[1].column'] = { val: null };
        }
      }

      if (path === 'right[0].type') {
        // Sync the type
        pathValues['right[1].type'] = { val: value }
      }

      if (path === 'operator' && value === BOOLEAN_OPS.BTW && getEntityVal(draft.entities[id]?.operator) !== BOOLEAN_OPS.BTW) {
        // Sync the type between the two right values
        pathValues['right[1].type'] = { val: getEntityVal(draft.entities[id]?.['right[0].type']) }
      }

      if (path === 'dateType') {
        const currentOperator = draft.entities[id]?.['operator'];

        // enforce that the operator is valid, according to DATE_TYPE
        const valid_rolling_ops = Object.keys(ROLLING_DATE_OPS);
        const valid_date_ops = Object.keys(BOOLEAN_OPS);

        if (value === DATE_TYPES.ROLLING && !valid_rolling_ops.includes(currentOperator)) {
          pathValues['operator'] = { val: Object.keys(ROLLING_DATE_OPS)[0] };
        }
        else if (value === DATE_TYPES.DATE && !valid_date_ops.includes(currentOperator)) {
          pathValues['operator'] = { val: BOOLEAN_OPS.EQ };
        }
        // Set right value to whatever default the component wants
        pathValues['right[0].value'] = { val: defaultDateValue };
      }

      const hasChanged = (path, val) => {
        return getEntityVal(draft.entities[id]?.[path]) !== val;
      }

      if ('conditional_agg' in draft.entities?.[id]) {
        // AGGREGATE STUFF

        // NOTE: Nevermind this stuff. We need columnDef around for agg type validation.
        // Perform this mapping in profileToQuery, or maybe even backend.
        //
        // IF CNT->OTHER, make type column
        // IF OTHER->CNT, make type value
        // if (path === 'conditional_agg' && hasChanged(path, value)) {
        //   if (value === AGGREGATES.CNT) {
        //     pathValues['target.type'] = createEntityVal(VALUE_TYPES.value);
        //     pathValues['target.value'] = createEntityVal(1);
        //   } else {
        //     pathValues['target.type'] = createEntityVal(VALUE_TYPES.column);
        //     delete pathValues['target.value'];
        //   }
        // }

        console.log(path, value, colDef);

        if (path === 'target.column' && hasChanged(path, value) && colDef) {
          // Make sure if we switch the target, we change the AGG if its not allowed
          const agg = getEntityVal(draft.entities[id]?.['conditional_agg']);
          const allowedAggs = allowedAggregatesForInputType(colDef?.input);

          if (!(agg in allowedAggs)) {
            pathValues['conditional_agg'] = createEntityVal(allowedAggs?.[0]);
          }

          // Change the label, since its very confusing if it remains the old column name.
          // NOTE: This does mean the user loses thier custom label. I think worth it.
          pathValues['label'] = createEntityVal(colDef?.label || colDef?.name || 'New Aggregate');
        }
      }

      // Actual update statement
      idsToUpdate.forEach(_id => {
        Object.entries(pathValues).forEach(([path, { val, removeFalsy }]) => {
          if (!draft.entities[_id]?.[path]) {
            draft.entities[_id][path] = { val: null };
          }
          if (!val && removeFalsy) {
            delete draft.entities[_id][path];
          } else if (val !== undefined) {
            draft.entities[_id][path].val = val;
          }
        });

        swapBetweenValuesIfNeeded(draft.entities, _id);
        validateEntity(draft.entities, _id);
      })

      break;
    }


    case CREATE_SLICE_GROUP: {
      // Place the given ID node into a new SLICE_GROUP as the first element.
      // Add a default startTime of 4am to the first child. Maybe theres a more hardened 
      // way of doing the startTime intialization...
      const filterId = action.payload;

      const profile = getActiveProfile(draft);
      if (!profile) break;

      const filterLeaf = getLeaf(profile.root, filterId);
      const filterNode = draft.entities?.[filterId];

      if (!filterLeaf || !filterNode) {
        console.warn(`No filter leaf/node found for id ${filterId}`);
        break;
      }

      const { id: groupId, leaf: groupLeaf } = createEntityAndLeaf({
        type: STRUCTURAL_TYPES.SLICE_GROUP,
        tree: [filterLeaf],
      });

      const success = replaceLeaf(
        profile.root,
        filterId,
        groupLeaf
      );

      if (success) {
        draft.entities[filterId].startTime = createEntityVal(getMarketStartString())
        validateEntity(draft.entities, filterId)
        doCreateEmptySlice(draft, { payload: { groupId } });
      } else {
        console.error('Failed to replace leaf', filterId, groupId);
      }

      break;
    }


    case CREATE_OR_GROUP: {
      const {
        id,
        data
      } = action.payload;
      const profile = getActiveProfile(draft);

      if (!profile) {
        console.warn(`No active profile found for leaf ${id}`);
        break;
      }

      const filterLeaf = getLeaf(profile.root, id);

      if (!filterLeaf) {
        console.warn(`No filter leaf found for id ${id}`);
        break;
      }

      const newGroupLeaf = doCreateOrGroup(profile, draft.entities, filterLeaf);

      if (newGroupLeaf) {
        doCreateFilter(profile, draft.entities, newGroupLeaf.id, data);
      }
      break;
    }


    case CREATE_VISUAL_GROUP: {
      const {
        groupId,
        label = 'Group'
      } = action.payload;

      const profile = getActiveProfile(draft);

      if (!profile) {
        console.warn(`No active profile found for leaf ${groupId}`);
        break;
      }

      doCreateVisualGroup(profile, draft.entities, groupId, label);

      break;
    }


    case CREATE_SLICE: {
      doCreateEmptySlice(draft, action);
      break;
    }


    case DELETE_ENTITY: {
      // NOTE: YOU MUST ALSO MODIFY topListLayoutActions updateProfile if this changes!

      const { id, groupId } = action.payload;

      const profile = getActiveProfile(draft);
      if (!profile) {
        console.warn(`No active profile found for leaf ${id}`);
        break;
      }

      doDeleteEntityAndResolveParents(id, groupId, draft.entities, profile.root);

      break;
    }

    case CREATE_FILTER: {
      const { groupId, data } = action.payload;

      const profile = getActiveProfile(draft);
      if (!profile) {
        console.warn(`No active profile found for leaf ${groupId}`);
        break;
      }

      doCreateFilter(profile, draft.entities, groupId, data);

      break;
    }


    case CREATE_CONDITIONAL_AGG: {
      const { groupId, data } = action.payload;

      const profile = getActiveProfile(draft);
      if (!profile) {
        console.warn(`No active profile found for leaf ${groupId}`);
        break;
      }

      doCreateConditionalAgg(profile, draft.entities, groupId, data);

      break;
    }


    case CREATE_STAT: {
      const { groupId, data } = action.payload;

      const profile = getActiveProfile(draft);
      if (!profile) {
        console.warn(`No active profile found for leaf ${groupId}`);
        break;
      }

      doCreateStat(profile, draft.entities, groupId, data);

      break;
    }


    case REORDER_LEAF: {
      const { groupId, sourceIndex, destIndex } = action.payload;

      const profile = getActiveProfile(draft);

      if (!profile) {
        console.warn(`No active profile found for leaf ${groupId}`);
        break;
      }

      let id = groupId;
      if (groupId === 'ROOT') {
        id = profile?.root?.id;
      }

      if (!id) {
        console.warn(`No id found for leaf ${groupId}`);
        break;
      }

      const groupLeaf = getLeaf(profile.root, id);

      if (!groupLeaf) {
        console.warn(`No group leaf found for id ${groupId} (${id})`);
        break;
      }

      if (!groupLeaf?.tree || !groupLeaf?.tree?.length) {
        console.warn(`Leaf ${groupId}.tree is empty or missing. Ignoring.`);
        break;
      }

      if (!(sourceIndex in groupLeaf.tree) || !(destIndex in groupLeaf.tree)) {
        console.warn(`Reorder index old:${sourceIndex} new:${destIndex} out of bounds in tree length:${groupLeaf.tree.length}. Ignoring.`);
        break;
      }

      const leafToMove = groupLeaf.tree.splice(sourceIndex, 1)[0];
      groupLeaf.tree.splice(destIndex, 0, leafToMove);

      break;
    }


    case REPARENT_LEAF: {
      // WARNING: You may need to modify the actual entities depending on which type it is. Slice->OR for example.
      // Ignore for now, because only VISUAL_GROUP calls this 

      const { sourceGroupId, destGroupId, sourceIndex, destIndex } = action.payload;

      const profile = getActiveProfile(draft);

      console.log('REPARENT');

      if (!profile) {
        console.warn(`No active profile found for leaf ${sourceGroupId}`);
        break;
      }

      let sgId = sourceGroupId;
      if (sourceGroupId === 'ROOT') {
        sgId = profile?.root?.id;
      }

      let dgId = destGroupId;
      if (destGroupId === 'ROOT') {
        dgId = profile?.root?.id;
      }

      if (sgId === dgId) {
        console.warn(`Source and destination group ids are the same. Ignoring.`);
        break;
      }

      if (!sgId || !dgId) {
        console.warn(`No id found for leaf ${sourceGroupId} or ${destGroupId}`);
        break;
      }

      const sourceGroupLeaf = getLeaf(profile.root, sgId);
      const destGroupLeaf = getLeaf(profile.root, dgId);

      if (!sourceGroupLeaf || !destGroupLeaf) {
        console.warn(`No group leaf found for id ${sourceGroupId} (${sgId}) or ${destGroupId} (${dgId})`);
        break;
      }

      if (!sourceGroupLeaf?.tree || !sourceGroupLeaf?.tree?.length) {
        console.warn(`Leaf ${sourceGroupId}.tree is empty or missing. Ignoring.`);
        break;
      }

      if (!(sourceIndex in sourceGroupLeaf.tree) || destIndex < 0 || destIndex > destGroupLeaf.tree.length) {
        console.warn(`Reorder index old:${sourceIndex} new:${destIndex} out of bounds in tree length:${sourceGroupLeaf.tree.length}. Ignoring.`);
        break;
      }

      const leafToMove = sourceGroupLeaf.tree.splice(sourceIndex, 1)[0];
      destGroupLeaf.tree.splice(destIndex, 0, leafToMove);

      break;
    }


    default: {
      break;
    }
  }
}

export default filterReducer;


// TODO: Move this to FormSettingsProvider.
export const selectFormType = state => state.formType;


export const selectIsAnyFieldInvalid = createSelector(
  [
    selectActiveProfile,
    state => state.entities,
    state => state.formType,
  ],
  (activeProfile, entities, formType) => {
    // column form...
    if (!entities || formType === FILTER_FORM_TYPES.COLUMN) return false;
    // filter form
    const leaves = getAllDescendants(activeProfile.root);
    return leaves.some(l => {
      const entity = entities[l.id];
      if (!entity) return false;

      return Object.values(entity).some(vals => vals?.err);
    })
  }
)


/**
 
 * Get the SLICE_GROUPs children, used to work with startTimes.
 * 
 * @example
 * const sliceEntities = useParameterizedFormSelector(makeSelectSliceChildEntities, groupId);
 * 
 * @returns {Slice[]} Child entites, or undefined if not a SLICE_GROUP
 */
export const makeSelectSliceChildEntities = () => createSelector(
  [
    selectActiveProfile,
    state => state.entities,
    (_, groupId) => groupId
  ],
  (activeProfile, entities, groupId) => {
    if (!activeProfile) {
      console.error(new Error('No active profile!'))
    }

    const childLeaves = getSliceChildrenLeaves(activeProfile.root, groupId);

    if (childLeaves === undefined) {
      return undefined;
    }

    const childEntites = childLeaves.map(leaf => {
      const entity = entities?.[leaf.id];
      if (!entity) return null;
      return {
        id: leaf?.id,
        startTime: getEntityVal(entity?.['startTime'])
      };
    }).filter(Boolean);

    if (childEntites.length !== childLeaves.length) {
      throw new Error('selectSliceChildEntities: childEntites.length !== childLeaves.length. Missing entities!');
    }

    return childEntites;
  }
)
