import { THEMES } from 'src/constants';

export function restoreSettings() {
  let settings = {
    theme: THEMES.ETT_DARK,
    inititalAppLayoutLoad: true,
    direction: 'ltr'
  };

  try {
    // const storedData = localStorage.getItem('settings');
    //
    // if (storedData) {
    //   settings = JSON.parse(storedData);
    // }
  } catch (err) {
    // If stored data is not a strigified JSON this might fail,
    // that's why we catch the error
  }

  return settings;
}

export function storeSettings(settings) {
  try {
    localStorage.setItem('settings', JSON.stringify(settings));
  } catch (err) {
    console.log('storeSettings:', err);
  }
}
