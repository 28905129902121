import PropTypes from "prop-types";
import _merge from 'lodash/merge';
import React, { createContext, useContext, useMemo } from 'react';
import { PROFILE_PERMISSION_SCOPES, profilePermissionDefaults } from 'src/hooks/useUserPlanPermissions';
import { PREDEF_PREFIX } from 'src/redux/profileSettings/profileSettingsConfig';
import { selectActiveProfile, selectActiveProfileId } from '../reducers/profileMapReducer';
import { useFormSelector } from './FormProvider';
import { STRUCTURAL_TYPES } from "../../mapping/mappingDirections";

/**
 * @template T
 * @typedef {T[keyof T]} ValueOf
 */

/**
 * Some of these features don't apply to every type. eg:
 *  ROOT can't be dragged, or have a Title.
 *  Conditional Aggs don't have child types (yet)
 * @typedef {object} StructuralTypeFeatures
 * @property {string} label - Shown in buttons, default entities, etc.
 * @property {ValueOf<STRUCTURAL_TYPES>} childType - Which entity can this element render and create?
 * @property {boolean} canBeCreated
 * @property {boolean} canBeDeleted
 * @property {boolean} canBeReordered
 * @property {boolean} [showTitle]
 * @property {boolean} [editableTitle]
 * @property {number} [minSiblingCount] - Counts relative to direct parent, not total count
 * @property {number} [maxSiblingCount]
 * @property {boolean} [dndContext] - Defines this as the root for Drag&Drop
 */

// Note: None of this exists on keystats, and we should probably combine
// the two files.

/**
 * Defines rules for how the Aggregate form entities work.
 * NOTE: Everything isn't ironed out. If you try to add nested
 * draggables, for example, things will break.
 *
 * NOTE: Can't figure out min/max count. Per parent or global?
 * Maybe we just add a min/max for Aggregates globally, but nothing else.
 * But then, what happens when the Group is deleted? We have to check all 
 * other nodes first to block the click? This is a mess.
 */
export const defaultStructuralConfigs = {
  [STRUCTURAL_TYPES.ROOT]: {
    label: 'Root',
    dndContext: true,
    childType: STRUCTURAL_TYPES.VISUAL_GROUP,
    canBeCreated: false,
    canBeDeleted: false,
    canBeReordered: false,
    showTitle: false,
    editableTitle: false,
  },
  [STRUCTURAL_TYPES.VISUAL_GROUP]: {
    label: 'Group',
    childType: STRUCTURAL_TYPES.CONDITIONAL_AGG,
    canBeCreated: true,
    canBeDeleted: true,
    showTitle: true,
    editableTitle: true,
    minCount: 1,
    maxCount: 12
  },
  [STRUCTURAL_TYPES.CONDITIONAL_AGG]: {
    label: 'Aggregate',
    childType: null,
    canBeCreated: true,
    canBeDeleted: true,
    // Will always cause the parent group item to render Droppable, and Draggable for each child
    // If we need more complex behavior, (nested, no reparent, etc) then we need a lot more config.
    canBeReordered: true,
    minCount: 1,
    maxCount: 50
  }
};


const defaultFormSettingsValues = {
  permissions: {},
  settings: {},
  state: {},
  profileListKey: '',
  layoutKey: '',
};


const FormSettingsContext = createContext(defaultFormSettingsValues);



/**
 * Stores static settings for the form, not necissarily form state.
 * Note these values can change, but not in response to user input.
 *
 * We also precompute some locked values
 **/
function FormSettingsProvider({
  permissions,
  settings,
  children,
  defaultPermissions = profilePermissionDefaults
}) {
  const value = useMemo(() => {
    return {
      permissions,
      defaultPermissions,
      settings: {
        ...settings,
        structuralTypeFeatures: _merge({}, defaultStructuralConfigs, settings.structuralTypeFeatures || {}),
      },
    }
  }, [permissions, settings, defaultPermissions]);

  return (
    <FormSettingsContext.Provider value={value}>
      {children}
    </FormSettingsContext.Provider>
  );
}


FormSettingsProvider.propTypes = {
  permissions: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  defaultScopes: PropTypes.object,
  children: PropTypes.any,
};


const emptyObj = {};

FormSettingsProvider.defaultProps = {
  permissions: emptyObj,
  settings: emptyObj,
}



export default FormSettingsProvider;

// This belongs in profileReducer.
// Needs to return False when no profiles exist.
// Alternatively, figure out how to remove this from shared components.
export function useIsPredefined() {
  const activeProfile = useFormSelector(selectActiveProfile);

  return activeProfile &&
    (activeProfile?.predefined || activeProfile?.id.startsWith(PREDEF_PREFIX));
}

/**
 * @typedef {Object} Permissions
 * @property {Boolean} value
 * @property {Date} unlocksAt
 **/


/**
 * @param {string[]} scopes
 * @returns {Permissions[]}
 **/
export function useFormPermissions(...scopes) {
  const value = useContext(FormSettingsContext);
  const defaults = value?.defaultPermissions || emptyObj;
  const permissions = value?.permissions || emptyObj;

  return useMemo(() => {
    const errs = [];
    const out = scopes.map(scope => {
      const perm = permissions[scope] || defaults[scope] || {};
      if (perm === undefined) {
        errs.push(scope);
      }
      return perm;
    });

    if (errs.length) {
      throw new Error(`Missing permissions for scopes: ${errs.join(', ')}. Must exist either explicitely, or in defaults passed to FormSettingsProvider.`);
    }

    return out;
  }, [value, ...scopes]);
}


/**
 * Either predefined, or customization disabled. Both mean the user cannot
 * change form values.
 **/
export function useIsEditingDisabled() {
  const isPredefined = useIsPredefined();
  const [{ value: customizationDisabled }] = useFormPermissions(PROFILE_PERMISSION_SCOPES.disabledCustomization);

  return Boolean(isPredefined || customizationDisabled);
}



export function useFormSettings() {
  const context = useContext(FormSettingsContext);
  return context?.settings || emptyObj;
}


export function useFormSetting(key, default_ = undefined) {
  const settings = useFormSettings();
  return (key in settings) ? settings[key] : default_;
}

/**
 * Sort of a half-implemented feature. Ideally we'd define these
 * for all entity types, but with some forms having multiple VISUAL_GROUPS,
 * some entities not having drag and drop, etc, its not worth implementing
 * everything.
 *
 * TODO: I have lots of ideas here:
 *
 * 1) Use this structure to define Labels too, like aggregateEntityLabel.
 * 2) Implement these controls for all entity types
 * 3) Fix the issue with buttons in the parent group making children:
 *      e.g., how do we know the ROOT group should make VISUAL_GROUPS, not Aggregates?
 *
 *    - To fix this, each type gets a createsEntity: entity
 *    - Each one defiens an (overrideable) callback
 *    - Can be null
 * 4) Next problem is Drag&Drop, since nesting is such a pain. I do not know how to solve this.
 *    Maybe we are allowed to define:
 *     - DragProvider entity
 *     - Draggble entity
 *     - Droppable entity
 *
 *    This won't really work for aggregates+nested filters, but its a start.
 *
 * 5) Last, we should marry FilterGrouping and AggregateGrouping. With all these control
 *    ideas, maybe it won't be so bad.
 */


export function useFormSettingStructuralTypeDisabledFeatures(structuralType) {
  return {}
}

/**
 * @param {ValueOf<STRUCTURAL_TYPES>} structuralType
 * @returns {StructuralTypeFeatures}
 */
export function useFormSettingsStructuralTypeFeatures(structuralType) {
  const settings = useFormSettings();
  return settings?.structuralTypeFeatures?.[structuralType] || emptyObj;
}
