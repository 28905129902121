import { EXPRESSION_NAMESPACE, getInitialExpressionState } from '../base/layoutExpressionConfig';
import { LINK_COLORS } from "../base/layoutSchema";
import { PROFILE_CONFIG } from "./profileConfig";
import { ToplistSchemaApi } from "./schema";

/** @typedef {import('../base/layoutExpressionConfig.js').ExpressionColDef} ExpressionColDef*/
/** @typedef {import('../base/layoutExpressionConfig.js').Expression} Expression */



export default function makeInitialState() {
  const expressionState = getInitialExpressionState(EXPRESSION_NAMESPACE.HISTORY_COMP, EXPRESSION_NAMESPACE.REALTIME);

  const {
    components,
    layout,
  } = ToplistSchemaApi.createLayout('Default');

  const layoutId = ToplistSchemaApi.generateId();

  return {
    activeLayout: layoutId,
    layoutTabs: [layoutId],
    links: {},
    tickerSearchLinkColor: LINK_COLORS.white.name,
    layouts: {
      [layoutId]: layout
    },
    components: {
      ...components
    },
    profileMap: {
      [PROFILE_CONFIG.SCANNER_FILTERS.listKey]: PROFILE_CONFIG.SCANNER_FILTERS.predefinedProfiles,
      [PROFILE_CONFIG.SCANNER_COLUMNS.listKey]: PROFILE_CONFIG.SCANNER_COLUMNS.predefinedProfiles,
      [PROFILE_CONFIG.KEY_STATS.listKey]: PROFILE_CONFIG.KEY_STATS.predefinedProfiles,
      [PROFILE_CONFIG.HISTORY_FILTERS.listKey]: PROFILE_CONFIG.HISTORY_FILTERS.predefinedProfiles,
      [PROFILE_CONFIG.HISTORY_COLUMNS.listKey]: PROFILE_CONFIG.HISTORY_COLUMNS.predefinedProfiles,
      [PROFILE_CONFIG.HISTORY_AGGREGATES.listKey]: PROFILE_CONFIG.HISTORY_AGGREGATES.predefinedProfiles,
      [PROFILE_CONFIG.NEWS_COLUMNS.listKey]: PROFILE_CONFIG.NEWS_COLUMNS.predefinedProfiles,
    },
    profileTree: {
      [PROFILE_CONFIG.SCANNER_FILTERS.listKey]: ToplistSchemaApi.generateInitialProfileTree(PROFILE_CONFIG.SCANNER_FILTERS.listKey),
      [PROFILE_CONFIG.SCANNER_COLUMNS.listKey]: ToplistSchemaApi.generateInitialProfileTree(PROFILE_CONFIG.SCANNER_COLUMNS.listKey),
      [PROFILE_CONFIG.KEY_STATS.listKey]: ToplistSchemaApi.generateInitialProfileTree(PROFILE_CONFIG.KEY_STATS.listKey),
      [PROFILE_CONFIG.HISTORY_FILTERS.listKey]: ToplistSchemaApi.generateInitialProfileTree(PROFILE_CONFIG.HISTORY_FILTERS.listKey),
      [PROFILE_CONFIG.HISTORY_COLUMNS.listKey]: ToplistSchemaApi.generateInitialProfileTree(PROFILE_CONFIG.HISTORY_COLUMNS.listKey),
      [PROFILE_CONFIG.HISTORY_AGGREGATES.listKey]: ToplistSchemaApi.generateInitialProfileTree(PROFILE_CONFIG.HISTORY_AGGREGATES.listKey),
      [PROFILE_CONFIG.NEWS_COLUMNS.listKey]: ToplistSchemaApi.generateInitialProfileTree(PROFILE_CONFIG.NEWS_COLUMNS.listKey),
    },
    orderings: {
      expressions: expressionState.orderings
    },
    expressions: expressionState.expressions,
    watchlistRevision: 0, // Watchlists themselves can't be handled by middleware. But this can, allowing us to sort-of sync the two sources.
    watchlists: {
      [PROFILE_CONFIG.WATCHLIST_ROWS.listKey]: [], // Notice, this is an array unlike all others
      [PROFILE_CONFIG.WATCHLIST_ROWS.dataKey]: [],
    },
    ui: {
      showComponentSelectOverlay: false,
    },
    isFetching: {
      initial: true, // all profile stuff
      watchlists: true,
      watchlistData: true,
    },
  }
}
