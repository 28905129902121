import React, { useEffect, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { logout } from 'src/redux/account/accountActions';
import { DOCS_WEBSITE_URL } from 'src/constants';
import { useSnackbar } from 'notistack';
import { useIntercom } from 'react-use-intercom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import LogoE from 'src/components/LogoE';
import NavItem from './NavItem';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  useTheme,
  useMediaQuery,
  makeStyles
} from '@material-ui/core';
import {
  TopListsIcon,
  FinancialsIcon,
  HistoryIcon,
  ChatQuestionMarkIcon,
  SettingsIcon,
  LogOutIcon,
  FilingsIcon
} from 'src/theme/EdgeIcons';


// TODO: Rework nav to not be recursive, so I can keep each navConfig inside one object.

const navConfig = (
  tickerSymbol,
  handleLogout,
  handleIntercom,
) => {
  return [
    [
      {
        title: 'Top Lists',
        icon: TopListsIcon,
        href: `/top-lists`,
      },
      {
        title: 'Financials',
        icon: FinancialsIcon,
        href: `/financials/${tickerSymbol}`
      },
      {
        title: 'Filings',
        icon: FilingsIcon,
        href: `/SEC/${tickerSymbol}`
      },
      {
        title: 'History',
        icon: HistoryIcon,
        href: `/history`
      }
      // {
      //   title: 'Overview',
      //   icon: OverviewIcon,
      //   href: `/overview/${tickerSymbol}`
      // },
      // {
      //   title: 'Insiders',
      //   icon: InsidersIcon,
      //   href: `/insiders/${tickerSymbol}`
      // },
    ],
    [], // for divider
    [
      {
        title: 'Help',
        // href: DOCS_WEBSITE_URL,
        action: handleIntercom,
        icon: ChatQuestionMarkIcon,
        showIfPastDue: true,
        newTab: true
      },
      {
        title: 'Settings',
        href: '/account/general',
        exact: false,
        icon: SettingsIcon,
        showIfPastDue: true,
      },
      {
        title: 'Logout',
        action: handleLogout,
        icon: LogOutIcon,
        showIfPastDue: true,
        isLogout: true,
      }
    ]
  ];
};


function renderNavItems({ items }) {
  return (
    <List disablePadding>
      {items.map(item => {
        return (
          <NavItem
            depth={0}
            exact={item.exact}
            href={item.href}
            action={item.action}
            icon={item.icon}
            propKey={item.title}
            key={item.title}
            info={item.info}
            title={item.title}
            isLogout={item.isLogout}
            newTab={item.newTab}
          />
        );
      })}
    </List>
  );
}


const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    backgroundColor: theme.palette.background.c.paper[500],
    width: theme.drawerWidth
  },
  desktopDrawer: {
    width: theme.drawerWidth,
    height: '100%',
    borderRight: 'none',
    backgroundColor: theme.palette.background.c.paper[500],
  },
  edgeESection: {
    height: theme.topBarHeight,
  },
  topNavCont: {
    flex: 1
  },
  desktopScroll: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  mobileScroll: {

  },
  navItemCont: {
    padding: 0,
    '& .MuiButtonBase-root': {
      '& svg': {
        fontSize: '26px'
      }
    }
  },
  lastNavItemCont: {
    '& .MuiList-root': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    }
  },
  spacerNavItemCont: {
    flex: 1
  },
  logoLink: {
    padding: [[8, 12]]
  }
}));


const selectLastViewedTickerOrDefault = state => {
  if (state.account?.user?.selectedTicker) {
    return state.account.user.selectedTicker;
  }
  if (state.ticker?.symbol) { // TODO: This is for backwards compatibillity
    return state.ticker.symbol;
  }
  return 'AAPL';
}


function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { enqueueSnackbar } = useSnackbar();
  const tickerId = useSelector(selectLastViewedTickerOrDefault);
  const {
    isOpen: isIntercomOpen,
    shutdown: shutdownIntercom,
    hide: hideIntercom,
    showSpace: showSpaceIntercom,
  } = useIntercom();


  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);


  const handleLogout = useCallback(() => {
    try {
      dispatch(logout(shutdownIntercom));
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  }, [logout, shutdownIntercom, enqueueSnackbar]);


  const handleIntercom = useCallback(() => {
    if (isIntercomOpen) {
      hideIntercom()
    } else {
      showSpaceIntercom('home')
    }
  }, [isIntercomOpen, hideIntercom])


  const navlists = useMemo(() => {
    return navConfig(tickerId, handleLogout, handleIntercom);
  }, [tickerId, handleLogout, handleIntercom])


  const content = (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Box
        p={0}
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.edgeESection}
      >
        <RouterLink
          to={`/top-lists`}
          className={classes.logoLink}
        >
          <LogoE size={18} />
        </RouterLink>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        flex="1"
        p={.5}
      >
        {navlists.map((items, i) => {
          const last = i === navlists.length - 1;
          // const secondToLast = i === navlists.length - 2;
          const spacer = items.length === 0;
          return (
            <React.Fragment key={i}>
              <Box className={clsx(
                classes.navItemCont,
                last && classes.lastNavItemCont,
                spacer && classes.spacerNavItemCont
              )}>
                {!spacer && (
                  <List key={i} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    {renderNavItems({ items })}
                  </List>
                )}
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden smUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box
            className={classes.mobileScroll}
            height="100%"
            display="flex"
            flexDirection="column"
          >
            {content}
          </Box>
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          <Box
            className={classes.desktopScroll}
            height="100%"
            display="flex"
            flexDirection="column"
          >
            {content}
          </Box>
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
