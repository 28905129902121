import React from 'react';
import PropTypes from 'prop-types'
import clsx from 'clsx';
import {
  alpha,
  colors,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    // backgroundColor: theme.palette.background.mosaicPanelGradient,
    borderRadius: theme.grid.borderRadius,
    position: 'relative',
    // border: [[1, 'solid', theme.palette.background.lightBorderThree]],
    overflow: 'hidden',
    background: `linear-gradient(180deg, ${alpha(theme.palette.primary.main, .16)} 0%, ${alpha(theme.palette.primary.main, .04)} 50%, ${alpha(theme.palette.primary.main, .08)} 100%)`,
    padding: 1,
    '&:before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      borderRadius: 'inherit',
      padding: 1,
      background: [
        'linear-gradient(to bottom,',
        `${alpha(theme.palette.primary.main, 0.32)} 0%,`,
        `${alpha(theme.palette.primary.main, 0.10)} 50%,`,
        `${alpha(theme.palette.primary.main, 0.16)} 100%)`,
      ].join(' '),
      '-webkit-mask': [
        `linear-gradient(${colors.common.white} 0 0) content-box`,
        `linear-gradient(${colors.common.white} 0 0)`,
      ].join(', '),
      '-webkit-mask-composite': 'xor',
      maskComposite: 'exclude',
      pointerEvents: 'none',
    }
  },
  yellow: {
    border: `1px solid ${alpha(theme.palette.historical.border, .9)}`
  },
}));

function MosaicPanel({
  className,
  variant,
  children
}) {
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.root, variant && classes?.[variant])}>
      {children}
    </div>
  );
}

MosaicPanel.propTypes = {
  clasName: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.any
}


MosaicPanel.defaultProps = {
  loading: false
}


export default MosaicPanel;

