import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  arrow: {
    color: theme.palette.background.dark,
  },
  tooltip: {
    backgroundColor: theme.palette.background.dark,
    padding: `11px 8px`,
    borderRadius: 4,
    textAlign: 'center',
    fontSize: 13,
    color: theme.palette.text.primary,
  },
}));


const usePrimaryStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.background.dark,
    padding: `5px 8px`,
    borderRadius: 4,
    textAlign: 'center',
    fontSize: 13,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.background.lightBorderThree}`,
    boxShadow: theme.shadows[1]
  },
}))


const placementOffsets = {
  'bottom': 'marginTop',
  'top': 'marginBottom',
  'left': 'marginRight',
  'right': 'marginLeft'
}


function DarkTooltip({
  arrow,
  backgroundcolor,
  title,
  children,
  variant,
  placement,
  ...props
}) {
  const primary = usePrimaryStyles();
  const secondary = useStyles();
  const classes = variant === 'secondary' ? secondary : primary;


  return (
    <Tooltip
      arrow={arrow}
      title={title}
      classes={classes}
      placement={placement}
      PopperProps={{ style: { [placementOffsets[placement]]: -12 } }}
      {...props}
    >
      {children}
    </Tooltip>
  );
}


DarkTooltip.propTypes = {
  arrow: PropTypes.bool,
  title: PropTypes.any,
  varaint: PropTypes.oneOf(['primary', 'secondary']),
  placement: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
  children: PropTypes.node,
};


DarkTooltip.defaultProps = {
  arrow: true,
  variant: 'secondary',
  placement: 'bottom',
};

export default DarkTooltip
