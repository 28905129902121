import { Link, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { setTicker } from 'src/redux/ticker/tickerActions';
import { FilingsIcon, FinancialsIcon } from 'src/theme/EdgeIcons';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.background.panelHeader}`,
    backgroundColor: theme.palette.background.panelHeader,
    '& a': {
      display: 'block'
    },
    '& div': {
      display: 'flex',
      alignItems: 'center',
      padding: '2px 8px',
      justifyContent: 'flex-start',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: [[theme.palette.action.hover, '!important']],
      },
      '& svg': {
        fontSize: 13,
        marginLeft: 0,
        marginRight: 4
      }
    },
    '& a:hover': {
      textDecoration: 'none'
    },
    '& .MuiTypography-root': {
      fontSize: 12,
      color: theme.palette.text.primary
    }
  }
}));


function TickerHoverMenu({ className, tickerId, handleSelect }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const currentPath = match.path.split('/').filter(s => s)[0];
  const MENU = [
    // { path: 'overview', label: 'Overview', link: `/overview/${tickerId}`, Icon: OverviewIcon },
    { path: 'financials', label: 'Financials', link: `/financials/${tickerId}`, Icon: FinancialsIcon },
    { path: 'SEC', label: 'Filings', link: `/SEC/${tickerId}`, Icon: FilingsIcon },
    // { path: 'insiders', label: 'Insiders', link: `/insiders/${tickerId}`, Icon: InsidersIcon },
  ];

  return (
    <div className={clsx(classes.root, className)}>
      {!!handleSelect && (
        <div onClick={handleSelect} role="button" className="action-link">
          <Typography variant="body2">Select</Typography>
        </div>
      )}
      {MENU.filter(({ path }) => path !== currentPath).map(({ path, label, link, Icon }) => (
        <Link
          key={path}
          component={RouterLink}
          to={link}
          onClick={() => dispatch(setTicker({ symbol: tickerId }, false))}
        >
          <div>
            <Icon />
            <Typography variant="body2">{label}</Typography>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default TickerHoverMenu;
