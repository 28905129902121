import { combineReducers } from 'redux';
import accountReducer from 'src/redux/account/accountReducer';
import tickerReducer from 'src/redux/ticker/tickerReducer';
import statTables from 'src/redux/statTables/statTablesReducer';
import keyStats from 'src/redux/keyStats/keyStatsReducer';
import tradingViewChart from 'src/redux/tradingViewChart/tradingViewChartReducer';
import newsReducer from 'src/redux/news/newsReducer';
import realtimeReducer from 'src/redux/realtime/realtimeReducer';
import secFilingsReducer from 'src/redux/secFilings/secFilingsReducer';
import exportReducer from 'src/redux/exports/exportReducer';
import profileSettings from 'src/redux/profileSettings/profileSettingsReducer';
import notificationReducer from 'src/redux/notifications/notificationReducer';
import financialsReducer from 'src/redux/financials/financialsReducer';
import globalExpressionReducer from '../expressions/globalExpressionReducer';

import makeToplistLayoutReducer from 'src/redux/layoutV2/toplist/makeReducer';
import makeHistoryLayoutReducer from 'src/redux/layoutV2/history/makeReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  ticker: tickerReducer,
  statTables: statTables,
  keyStats: keyStats,
  tvchart: tradingViewChart,
  news: newsReducer,
  secFilings: secFilingsReducer,
  // history: historyReducer,
  realtime: realtimeReducer,
  exports: exportReducer,
  profileSettings: profileSettings,
  financials: financialsReducer,
  notification: notificationReducer,
  // toplist: topListLayoutReducer,
  // expressions: globalExpressionReducer,
  // historyPageIntermediate: historyPageIntermediateReducer,
  ...makeToplistLayoutReducer(), // toplist
  ...makeHistoryLayoutReducer(), // history
});

export default rootReducer;
