import { getInitialExpressionState, EXPRESSION_NAMESPACE } from "../base/layoutExpressionConfig"
import { PROFILE_CONFIG } from "./profileConfig"
import { HistorySchemaApi } from './schema'
import {
  predefinedLayouts,
  predefinedLayoutTreeItems,
  predefinedLayoutTreeOpenState,
  predefinedComponents,
} from './predefinedLayouts'
import { LINK_COLORS, PREDEF_FOLDER_ID } from "../base/layoutSchema";

const defaultLinks = () => {
  return {
    [LINK_COLORS.white.name]: {
      ticker: 'AAPL',
      historicalDate: undefined,
    }
  }
}


const makeInitialProfileMap = (profileConfigs) => {
  const a = profileConfigs.reduce((acc, cfg) => ({
    ...acc,
    [cfg.listKey]: cfg.predefinedProfiles,
  }), {});
  return a;
}

const makeInitialProfileTree = (profileConfigs) => {
  return profileConfigs.reduce((acc, cfg) => ({
    ...acc,
    [cfg.listKey]: HistorySchemaApi.generateInitialProfileTree(cfg.listKey),
  }), {});
}

const CONFIGS = [
  PROFILE_CONFIG.HISTORY_FILTERS,
  PROFILE_CONFIG.HISTORY_COLUMNS,
  PROFILE_CONFIG.HISTORY_AGGREGATES,
  PROFILE_CONFIG.HISTORY_TIMESERIES,
  PROFILE_CONFIG.HISTORY_CHART_TIMESERIES_TABLE,
  PROFILE_CONFIG.HISTORY_CHART_CATEGORICAL_BAR,
  PROFILE_CONFIG.HISTORY_CHART_MULTI_METRIC_BAR,
  PROFILE_CONFIG.HISTORY_CHART_SCATTER_PLOT,
]


export default function makeInitialState() {

  const expressionState = getInitialExpressionState(EXPRESSION_NAMESPACE.HISTORY_FULL);

  const { layout, components } = HistorySchemaApi._createDefaultLayout({ name: 'New Layout' }, false);

  const layoutId = HistorySchemaApi.generateId();

  return {
    activeLayout: layoutId,
    globalSettings: {
      layoutDrawerOpen: true
    },
    linksByLayout: {
      [layoutId]: defaultLinks(),
      ...Object.keys(predefinedLayouts).reduce((acc, id) => {
        acc[id] = defaultLinks();
        return acc;
      }, {})
    },
    layouts: {
      ...predefinedLayouts,
      [layoutId]: layout,
    },
    layoutTree: {
      items: {
        'root': {
          id: 'root',
          isFolder: true,
          children: [PREDEF_FOLDER_ID, layoutId],
        },
        ...predefinedLayoutTreeItems,
        [layoutId]: {
          id: layoutId,
          label: layout.name
        }
      },
      openState: [...predefinedLayoutTreeOpenState],
    },
    components: {
      ...predefinedComponents,
      ...components
    },
    profileMap: {
      ...makeInitialProfileMap(CONFIGS),
    },
    profileTree: {
      ...makeInitialProfileTree(CONFIGS),
    },
    orderings: {
      expressions: expressionState.orderings
    },
    expressions: expressionState.expressions,
    ui: {
      showComponentSelectOverlay: false,
      layoutTree: {
        newItemIndicators: [],
      }
    },
    isFetching: {
      initial: true, // all profile stuff
    },
  }

}
