import { lazy } from 'react';
import { DEFAULT_TEMPLATE_ID } from 'src/app/ChartingLibrary/constants';
import ComponentSelectPanel from 'src/app/layout/shared/ComponentSelectPanel';
import {
  ColumnsIcon,
  FilterIcon,
  GraphsidewaysIcon,
  HistoryIcon,
  NewsIcon
} from 'src/theme/EdgeIcons';
import { EXPRESSION_NAMESPACE } from '../base/layoutExpressionConfig';
import {
  CHART_DATE_INITIALIZATION_TYPES,
  COMPONENT_TYPES,
  defaultNewsCategories,
  LINK_COLORS,
  SchemaApi
} from '../base/layoutSchema';
import { PROFILE_CONFIG } from './profileConfig';
import { Component } from 'react';


/**  @typedef {import('../base/layoutSchema.js').ComponentMapItem} ComponentMapItem */
/**  @typedef {import('../base/layoutSchema.js').Layout} Layout */
/**  @typedef {import('../base/layoutSchema.js').MosaicNode} MosaicNode */
/**  @typedef {import('../base/layoutSchema.js').ProfileConfigItem} ProfileConfigItem */

export const HISTORY_NAMESPACE = 'history';


export const TIME_SERIES_PERIODS = {
  DAY: 'D',
  WEEK: 'W',
  MONTH: 'M',
  QUARTER: 'Q',
  YEAR: 'Y'
}

export const TIMES_SERIES_PERIOD_OPTIONS = {
  D: 'Day',
  W: 'Week',
  M: 'Month',
  Q: 'Quarter',
  Y: 'Year'
}

/**
 * List of which COMPONENT_TYPES are available for users to select
 *
 * @readonly
 * @type {COMPONENT_TYPES[]}
 **/
export const SELECT_PANEL_COMPONENTS = [
  // COMPONENT_TYPES.HISTORY_FILTERS, - HARD CODED
  COMPONENT_TYPES.CHART,
  COMPONENT_TYPES.NEWS,
  COMPONENT_TYPES.HISTORY_RECORDS,
  COMPONENT_TYPES.HISTORY_MARKETSTATS,
  COMPONENT_TYPES.HISTORY_CHART_TIMESERIES_TABLE,
  COMPONENT_TYPES.HISTORY_CHART_SELECT,
];


/**
 * @readonly
 * @type {COMPONENT_TYPES[]}
 */
export const HISTORY_CHART_SELECT_PANEL_COMPONENTS = [
  COMPONENT_TYPES.HISTORY_TIMESERIES,
  COMPONENT_TYPES.HISTORY_CHART_SCATTER_PLOT,
  COMPONENT_TYPES.HISTORY_CHART_CATEGORICAL_BAR,
  COMPONENT_TYPES.HISTORY_CHART_MULTI_METRIC_BAR,
]


/**
 * @readonly
 * @type {{string: ComponentMapItem}} COMPONENT_MAP
 */
export const COMPONENT_MAP = {
  [COMPONENT_TYPES.SELECT]: {
    title: 'Select Component',
    Component: lazy(() => import('src/app/layout/shared/ComponentSelectPanel')),
    availableComponents: SELECT_PANEL_COMPONENTS,
  },
  [COMPONENT_TYPES.HISTORY_CHART_SELECT]: {
    title: 'Select Chart Type',
    Component: lazy(() => import('src/app/layout/HistoryCharts/HistoryChartSelectPanel')),
    availableComponents: HISTORY_CHART_SELECT_PANEL_COMPONENTS,
    Icon: ColumnsIcon,
  },
  [COMPONENT_TYPES.HISTORY_FILTERS]: {
    title: 'Filters',
    Icon: FilterIcon,
    expressionContextKey: EXPRESSION_NAMESPACE.HISTORY_FULL,
    profileConfigMeta: {
      filterProfileConfig: PROFILE_CONFIG.HISTORY_FILTERS,
    },
    Component: lazy(() => import('src/app/layout/HistoryFilters')),
  },
  [COMPONENT_TYPES.HISTORY_RECORDS]: {
    title: 'Records Table',
    Icon: FilterIcon,
    expressionContextKey: EXPRESSION_NAMESPACE.HISTORY_FULL,
    profileConfigMeta: {
      columnProfileConfig: PROFILE_CONFIG.HISTORY_COLUMNS,
      filterProfileConfig: PROFILE_CONFIG.HISTORY_FILTERS,
    },
    Component: lazy(() => import('src/app/layout/HistoryRecords'))
  },
  [COMPONENT_TYPES.HISTORY_MARKETSTATS]: {
    title: 'Market Stats',
    Icon: HistoryIcon,
    expressionContextKey: EXPRESSION_NAMESPACE.HISTORY_FULL,
    profileConfigMeta: {
      aggregateProfileConfig: PROFILE_CONFIG.HISTORY_AGGREGATES,
      filterProfileConfig: PROFILE_CONFIG.HISTORY_FILTERS,
    },
    Component: lazy(() => import('src/app/layout/HistoryMarketstats'))
  },
  [COMPONENT_TYPES.CHART]: {
    title: 'Chart',
    Icon: GraphsidewaysIcon,
    Component: lazy(() => import('src/app/layout/connectors/HistoryChartConnector')),
  },
  [COMPONENT_TYPES.NEWS]: {
    title: 'News',
    Icon: NewsIcon,
    profileConfigMeta: {
      columnProfileConfig: PROFILE_CONFIG.NEWS_COLUMNS,
      filterProfileConfig: PROFILE_CONFIG.HISTORY_FILTERS,
    },
    Component: lazy(() => import('src/app/layout/News/HistoryNews')),
    // Component: lazy(() => import('src/app/layout/News')),
    noLinkAllowed: true
  },
  [COMPONENT_TYPES.HISTORY_TIMESERIES]: {
    title: 'Time Series Chart',
    Icon: ColumnsIcon,
    expressionContextKey: EXPRESSION_NAMESPACE.HISTORY_FULL,
    profileConfigMeta: {
      timeseriesProfileConfig: PROFILE_CONFIG.HISTORY_TIMESERIES,
      filterProfileConfig: PROFILE_CONFIG.HISTORY_FILTERS,
    },
    Component: lazy(() => import('src/app/layout/HistoryCharts/HistoryTimeSeriesChart'))
  },
  [COMPONENT_TYPES.HISTORY_CHART_TIMESERIES_TABLE]: {
    title: 'Comparison Table',
    Icon: ColumnsIcon,
    expressionContextKey: EXPRESSION_NAMESPACE.HISTORY_FULL,
    profileConfigMeta: {
      timeseriesProfileConfig: PROFILE_CONFIG.HISTORY_CHART_TIMESERIES_TABLE,
      filterProfileConfig: PROFILE_CONFIG.HISTORY_FILTERS,
    },
    Component: lazy(() => import('src/app/layout/HistoryCharts/HistoryTimeSeriesAggregateTable'))
  },
  [COMPONENT_TYPES.HISTORY_CHART_CATEGORICAL_BAR]: {
    title: 'Bar Chart by Category',
    Icon: ColumnsIcon,
    expressionContextKey: EXPRESSION_NAMESPACE.HISTORY_FULL,
    profileConfigMeta: {
      chartProfileConfig: PROFILE_CONFIG.HISTORY_CHART_CATEGORICAL_BAR,
      filterProfileConfig: PROFILE_CONFIG.HISTORY_FILTERS,
    },
    Component: lazy(() => import('src/app/layout/HistoryCharts/HistoryCategoricalBar'))
  },
  [COMPONENT_TYPES.HISTORY_CHART_MULTI_METRIC_BAR]: {
    title: 'Bar Chart',
    Icon: ColumnsIcon,
    expressionContextKey: EXPRESSION_NAMESPACE.HISTORY_FULL,
    profileConfigMeta: {
      chartProfileConfig: PROFILE_CONFIG.HISTORY_CHART_MULTI_METRIC_BAR,
      filterProfileConfig: PROFILE_CONFIG.HISTORY_FILTERS,
    },
    Component: lazy(() => import('src/app/layout/HistoryCharts/HistoryMultiMetricBar'))
  },
  [COMPONENT_TYPES.HISTORY_CHART_SCATTER_PLOT]: {
    title: 'Scatter Plot',
    Icon: ColumnsIcon,
    expressionContextKey: EXPRESSION_NAMESPACE.HISTORY_FULL,
    profileConfigMeta: {
      chartProfileConfig: PROFILE_CONFIG.HISTORY_CHART_SCATTER_PLOT,
      filterProfileConfig: PROFILE_CONFIG.HISTORY_FILTERS,
    },
    Component: lazy(() => import('src/app/layout/HistoryCharts/HistoryScatterPlot'))
  },
};



export const LAYOUT_TEMPLATES = [
  {
    id: 'Default',
    name: 'Default',
  },
];



class HistoryLayoutSchemaApi extends SchemaApi {

  /**
   * Internal
   * @param {keyof COMPONENT_TYPES} type
   * @param {object} overrides
   * @returns {{component: object, componentProfile: object}}
   **/
  _createComponent = (type, overrides = {}) => {
    let component = {};

    switch (type) {
      case COMPONENT_TYPES.SELECT: {
        component = {};
        break;
      }
      case COMPONENT_TYPES.HISTORY_CHART_SELECT: {
        component = {};
        break;
      }
      case COMPONENT_TYPES.HISTORY_FILTERS: {
        component = {
          ...this.addDefaultComponentProfileIds(type, [
            'filterProfileConfig'
          ])
        };
        break;
      }
      case COMPONENT_TYPES.HISTORY_RECORDS: {
        component = {
          order: 'desc',
          orderby: 'day0_date',
          ...this.addDefaultComponentProfileIds(type, [
            'columnProfileConfig'
          ])
        };
        break;
      }
      case COMPONENT_TYPES.HISTORY_MARKETSTATS: {
        component = {
          ...this.addDefaultComponentProfileIds(type, [
            'aggregateProfileConfig'
          ])
        }
        break;
      }
      case COMPONENT_TYPES.CHART: {
        component = {
          selectedTemplateId: DEFAULT_TEMPLATE_ID,
          interval: '1D',
          dateInitializationType: CHART_DATE_INITIALIZATION_TYPES.LINKED_DATE
        };
        break;
      }
      case COMPONENT_TYPES.NEWS: {
        component = {
          categories: defaultNewsCategories,
          link: LINK_COLORS.white.name,
          searchKeywords: [],
          linkedStateClearedAt: new Date().getTime(),
        };
        break;
      }
      case COMPONENT_TYPES.HISTORY_TIMESERIES: {
        component = {
          period: TIME_SERIES_PERIODS.WEEK,
          order: 'desc',
          orderby: 'period',
          ...this.addDefaultComponentProfileIds(type, [
            'timeseriesProfileConfig'
          ])
        };
        break;
      }
      case COMPONENT_TYPES.HISTORY_CHART_TIMESERIES_TABLE: {
        component = {
          period: TIME_SERIES_PERIODS.WEEK,
          ...this.addDefaultComponentProfileIds(type, [
            'timeseriesProfileConfig'
          ])
        }
        break;
      }
      case COMPONENT_TYPES.HISTORY_CHART_CATEGORICAL_BAR: {
        component = {
          dimension: 'exchange',
          ...this.addDefaultComponentProfileIds(type, [
            'chartProfileConfig'
          ])
        };
        break;
      }
      case COMPONENT_TYPES.HISTORY_CHART_MULTI_METRIC_BAR: {
        component = {
          ...this.addDefaultComponentProfileIds(type, [
            'chartProfileConfig'
          ])
        };
        break;
      }
      case COMPONENT_TYPES.HISTORY_CHART_SCATTER_PLOT: {
        component = {
          dimension: 'W',
          ...this.addDefaultComponentProfileIds(type, [
            'chartProfileConfig'
          ])
        };
        break;
      }
      default: {
        throw Error('Invalid component type');
      }
    }

    return {
      component: {
        type,
        link: LINK_COLORS.white.name,
        ...component,
        ...overrides
      },
    };

  }


  /**
   * Create a new layout
   * @param {string} [templateId]
   * @param {object} overrides
   * @returns {import('../base/layoutSchema').CreateLayoutPayload}
   **/
  createLayout = (templateId = null, overrides = {}) => {
    throw new Error('Historical does not support "new" layouts, only predefineds and copies');
  }


  getIdKey = (componentType, configMetaKey) => {
    const cfg = this.componentMap?.[componentType]?.profileConfigMeta?.[configMetaKey];
    if (!cfg) {
      throw new Error(`Could not identify profileConfig for ${componentType}, ${configMetaKey}`)
    }
    return cfg.idKey;
  }


  _createDefaultLayout = (overrides, predefined = true) => {
    const filtersId = this.generateId();
    const recordsId = this.generateId();
    const newsId = this.generateId();
    const chartId = this.generateId();
    const marketstatsId = this.generateId();
    const timeseriesId = this.generateId();


    const scopedComponentIds = {
      [COMPONENT_TYPES.HISTORY_FILTERS]: filtersId
    }

    const components = {
      [filtersId]: {
        ...this._createComponent(COMPONENT_TYPES.HISTORY_FILTERS).component,
        ...(predefined ? { predefined: true } : {})
      },
      [newsId]: {
        ...this._createComponent(COMPONENT_TYPES.NEWS).component,
        ...(predefined ? { predefined: true } : {})
      },
      [chartId]: {
        ...this._createComponent(COMPONENT_TYPES.CHART).component,
        ...(predefined ? { predefined: true } : {})
      },
      [recordsId]: {
        ...this._createComponent(COMPONENT_TYPES.HISTORY_RECORDS).component,
        ...(predefined ? { predefined: true } : {})
      },
      [marketstatsId]: {
        ...this._createComponent(COMPONENT_TYPES.HISTORY_MARKETSTATS).component,
        ...(predefined ? { predefined: true } : {})
      },
      [timeseriesId]: {
        ...this._createComponent(COMPONENT_TYPES.HISTORY_TIMESERIES).component,
        ...(predefined ? { predefined: true } : {})
      }
    }

    const d = + new Date();

    const layout = {
      name: 'New Layout',
      ...(predefined ? { predefined: true } : {}),
      createdAt: d,
      updatedAt: d,
      currentNode: {
        first: {
          first: chartId,
          second: recordsId,
          direction: 'row',
          splitPercentage: 50
        },
        second: {
          first: marketstatsId,
          second: {
            first: newsId,
            second: timeseriesId,
            splitPercentage: 50,
            direction: 'column'
          },
          splitPercentage: 41,
          direction: 'row'
        },
        direction: 'column',
        splitPercentage: 50,
      },
      scopedComponentIds,
      ...overrides
    };

    return {
      layout,
      components,
    };
  }


  _createOtherLayout = (overrides, predefined = true) => {
    const filtersId = this.generateId();
    const recordsId = this.generateId();
    const newsId = this.generateId();
    const chartId = this.generateId();
    const marketstatsId = this.generateId();
    const timeseriesId = this.generateId();

    const scopedComponentIds = {
      [COMPONENT_TYPES.HISTORY_FILTERS]: filtersId
    }

    const components = {
      [filtersId]: {
        ...this._createComponent(COMPONENT_TYPES.HISTORY_FILTERS).component,
        ...(predefined ? { predefined: true } : {}),
      },
      [newsId]: {
        ...this._createComponent(COMPONENT_TYPES.NEWS).component,
        ...(predefined ? { predefined: true } : {}),
      },
      [chartId]: {
        ...this._createComponent(COMPONENT_TYPES.CHART).component,
        ...(predefined ? { predefined: true } : {}),
      },
      [recordsId]: {
        ...this._createComponent(COMPONENT_TYPES.HISTORY_RECORDS).component,
        ...(predefined ? { predefined: true } : {}),
      },
      [marketstatsId]: {
        ...this._createComponent(COMPONENT_TYPES.HISTORY_MARKETSTATS).component,
        ...(predefined ? { predefined: true } : {}),
      },
      [timeseriesId]: {
        ...this._createComponent(COMPONENT_TYPES.HISTORY_TIMESERIES).component,
        ...(predefined ? { predefined: true } : {}),
      }
    }

    const d = + new Date();

    const layout = {
      name: 'Other Layout',
      createdAt: d,
      updatedAt: d,
      ...(predefined ? { predefined: true } : {}),
      currentNode: {
        first: {
          first: marketstatsId,
          second: chartId,
          direction: 'row',
          splitPercentage: 60
        },
        second: {
          first: recordsId,
          second: {
            first: newsId,
            second: timeseriesId,
            splitPercentage: 50,
            direction: 'column'
          },
          splitPercentage: 41,
          direction: 'row'
        },
        direction: 'column',
        splitPercentage: 40,
      },
      scopedComponentIds,
      ...overrides
    };

    return {
      layout,
      components,
    };
  }
}


const scopedComponents = [
  COMPONENT_TYPES.HISTORY_FILTERS
]


export const HistorySchemaApi = new HistoryLayoutSchemaApi({
  profileConfig: PROFILE_CONFIG,
  layoutTemplates: LAYOUT_TEMPLATES,
  componentMap: COMPONENT_MAP,
  scopedComponents
});
