import { current } from 'immer';
import { BATCH_CONFLICT_ACTION, buildParentMap as buildParentMapOriginal } from '../../utility/arborHelpers';
import TreeStateManager from '../../utility/arborDraftTreeManipulation';
import { COPY_PROFILE, deduplicateName } from './profileMapReducer';
import { createSelector } from 'reselect';

export const TREE_VIEWSTATE_TOGGLE_OPEN = '@form-tree-profile/TREE_VIEWSTATE_TOGGLE_OPEN';
export const TREE_CREATE_ITEM = '@form-tree-profile/TREE_CREATE_ITEM';
export const TREE_MOVE_ITEMS = '@form-tree-profile/TREE_MOVE_ITEMS';
export const TREE_RENAME_ITEM = '@form-tree-profile/TREE_RENAME_ITEM';
export const TREE_DELETE_ITEMS = '@form-tree-profile/TREE_DELETE_ITEMS';
export const TREE_DELETE_ITEMS_RECURSIVELY = '@form-tree-profile/TREE_DELETE_ITEMS_RECURSIVELY';
export const TREE_MOVE_ITEMS_WITH_BATCH_CONFLICTS = '@form-tree-profile/TREE_MOVE_ITEMS_WITH_BATCH_CONFLICTS';
export const TREE_CLEAR_NEW_ITEM_INDICATOR = '@form-tree-profile/TREE_CLEAR_NEW_ITEM_INDICATOR';
export const TREE_SET_SELECTION = '@form-tree-profile/TREE_SET_SELECTION';


/**
 * @typedef {object} TreeItem
 * @property {string} id - The unique ID of the item
 * @property {string} [label] - The name of the item (if its a folder). Otherwise name comes from profileMapReducer.
 * @property {boolean} [isFolder] - True if the item is a folder
 * @property {boolean} [isPredefined]
 * @property {string[]} [children] - The IDs of the children
 */


/**
 * Handles the react-arborist section of form state.
 * It maintains a tree of Folders and Profile IDs.
 *
 * Note, this is seperate from the Profile state, which is a flat map of proifles.
 * If you want to delete a profile, you should dispatch 2 actions:
 *  - One for tree
 *  - One for map
 *
 * This allows us to change UI strategies without re-coding the underlying profile logic.
 *
 * @param {Object} draft - The draft to modify
 * @param {import('reduce-reducers').Action} action - The action to apply
 */
export default function profileTreeReducer(draft, action) {
  const manager = new TreeStateManager(
    draft,
    draft.profileTree.items,
    draft.profileTree.openState,
    draft.profileTree.newItemIndicators
  );


  switch (action.type) {

    case TREE_VIEWSTATE_TOGGLE_OPEN: {
      /* NOTE: Not source-of-truth, this just syncs the data for initialOpen */
      const id = action.payload;
      const idx = draft.profileTree.openState.indexOf(id);
      if (idx === -1) {
        draft.profileTree.openState.push(id);
      } else {
        draft.profileTree.openState.splice(idx, 1);
      }
      break;
    }

    case TREE_SET_SELECTION: {
      /* Focuses the tree on a specific item, scroll-to basically */
      const id = action.payload;
      draft.profileTree.selection = id;
      break;
    }

    case TREE_CREATE_ITEM: {
      const { parentId, index, newItem } = action.payload;

      manager.createItem(parentId, index, newItem);

      break;
    }

    case TREE_MOVE_ITEMS: {
      const { dragIds, dragNodes, parentId, parentNode, index } = action.payload;
      dragIds.forEach(id => manager.moveItem(id, parentId, index))
      // NOTE: Does index need to be incremented? Or has the library reversed the order?
      break;
    }

    case TREE_RENAME_ITEM: {
      // NOTE: profileMapReducer also responds to this action
      const { id, name, itemType } = action.payload;
      if (!draft.profileTree.items[id]) break;
      draft.profileTree.items[id].label = name;
      break;
    }

    case TREE_DELETE_ITEMS: {
      // These IDS have already been recursed and collected
      // NOTE: profileMapReducer also responds to this action
      const { ids } = action.payload;
      const parentMap = manager.buildParentMap();
      ids.forEach(id => manager.deleteItem(id, parentMap));
      break;
    }

    case TREE_CLEAR_NEW_ITEM_INDICATOR: {
      const id = action.payload;
      const idx = draft.profileTree.newItemIndicators.indexOf(id);
      if (idx > -1) {
        draft.profileTree.newItemIndicators.splice(idx, 1);
      }
      break;
    }

    case TREE_MOVE_ITEMS_WITH_BATCH_CONFLICTS: {
      // NOTE: profileMapReducer also responds to this action
      const { dragIds, parentId, index, batchActions } = action.payload;
      const parentMap = manager.buildParentMap();

      const moveCounter = { current: 0 }

      batchActions.forEach(batchAction => {
        manager.handleBatchConflictAction(
          action.payload,
          batchAction,
          parentMap,
          moveCounter
        )
      })

      break;
    }

    default: {
      break;
    }
  }
}

const emptyArray = [];

export const selectProfileTreeOpenMap = createSelector(
  [state => state.profileTree.openState],
  openState => {
    return (openState || emptyArray).reduce((acc, curr) => ({ ...acc, [curr]: true }), {});
  }
)

export const selectProfileTreeOpenState = state => state.profileTree.openState || emptyArray;

export const selectProfileTreeItems = state => state.profileTree.items;

export const selectNewItemIndicators = state => state.profileTree.newItemIndicators || emptyArray;


