import ProfileToForm from './profileToForm';
import FormToProfile from './formToProfile'



/**
 * @typedef {Object} ProfileStruct
 * @property {string} id - The ID of the profile
 * @property {string} name - The name of the profile
 * @property {boolean} [predefined] - Optional, if the profile is predefined (uneditable)
 * @property {Object} [filters]
 * @property {Object} [aggregates]
 * @property {Object[]} [columns]
 *
 */

/**
 * @typedef {Object} FormStruct
 * @property {string} id - The ID of the profile
 * @property {string} name - The name of the profile
 * @property {boolean} [predefined] - Optional, if the profile is predefined (uneditable)
 * @property {Leaf} root - The root node of the filter tree
 */

/**
 * @typedef {Object} ProfileNodeBinaryArg
 * @property {'value'|'column'} type - The currently selected type
 * @property {string|number} [value]
 * @property {string} [column]
 */

/**
 * The Node inside a ProfileStruct
 * @typedef {Object} ProfileFilterNode
 * @property {ProfileNodeBinaryArg} left
 * @property {string} operator
 * @property {ProfileNodeBinaryArg[]} right
 * @property {boolean} [allowNull] - If the clause should evaluate true if any dependancy is NULL
 */

/**
 * @typedef {Object} ProfileGroupNode
 * @property {Object[]} [AND]
 * @property {Object[]} [OR]
 * @property {Object[]} [SLICE_GROUP]
 */

/**
 * @typedef {Object} QueryNodeBinaryArg
 * @property {string|number} [value]
 * @property {string} [column]
 * @property {string} [expression]
 * @property {Object} [args] - Expression args
 * @property {string} [label] - Expression label
 * @property {}
 */

/**
 * @typedef {Object} QueryFilterNode
 * @property {QueryNodeBinaryArg} left
 * @property {string} operator
 * @property {QueryNodeBinaryArg|QueryNodeBinaryArg[]} right
 * @property {boolean} [allowNull] - If the clause should evaluate true if any dependancy is NULL
 */



export const FORM = 'FORM';
export const PROFILE = 'PROFILE';
export const QUERY = 'QUERY';


/**
 * Filter, Aggregates, and Stats are nearly the same form, but must be differentiated.
 * Columns are a different form, but here for simplicity
 **/
export const FILTER_FORM_TYPES = {
  AGGREGATE: 'aggregates',
  FILTER: 'filters',
  COLUMN: 'columns',
  KEYSTATS: 'keystats'
}


export const ACTIVE_FILTER_FORM_TYPE_ID_NAME = {
  aggregates: 'activeFiltersId',
  filters: 'activeFiltersId',
  columns: 'activeColumnsId'
}


/**
 * Within the form, the base node must be an Object.
 * Aggregates and Keystats are arrays, so we must wrap them in an object
 * during formToProfile, and reverse on the way out.
 */
export const PROFILE_TYPES_NEEDING_INSERTED_ROOT_NODE = [
  FILTER_FORM_TYPES.AGGREGATE,
  FILTER_FORM_TYPES.KEYSTATS
]


/**
 * Enum for types of nodes
 * @readonly
 * @enum {string}
 **/
export const STRUCTURAL_TYPES = {
  AND: 'AND',
  OR: 'OR',
  /** When the base is an array (aggs), we have to wrap in an object to make rendering work. I hate it here. **/
  ROOT: 'ROOT',
  /** Clientside grouping, like keystats table columns **/
  VISUAL_GROUP: 'VISUAL_GROUP',
  /** Pick one value from group based on time **/
  SLICE_GROUP: 'SLICE_GROUP',
  /** Special aggregates with CASE conditions **/
  CONDITIONAL_AGG: 'CONDITIONAL_AGG',
  /** Base filter node, { left, op, right }**/
  FILTER: 'FILTER',
  /** Base keystats node, { stat, alarm } **/
  STAT: 'STAT'
};

/*
 AND: OR: [1] -> AND: 1 (delete or)
 AND: [] -> AND [] keep
 AND: VISUAL_GROUP: [] -> AND: []
*/


export const STRUCTURAL_TYPE_RULES = {
  [STRUCTURAL_TYPES.AND]: {
    minChildren: 0,
    treeKey: STRUCTURAL_TYPES.AND
  },
  [STRUCTURAL_TYPES.OR]: {
    minChildren: 2,
    treeKey: STRUCTURAL_TYPES.OR
  },
  [STRUCTURAL_TYPES.ROOT]: {
    minChildren: 0,
    treeKey: STRUCTURAL_TYPES.ROOT
  },
  [STRUCTURAL_TYPES.VISUAL_GROUP]: {
    minChildren: 0, // Used to be 1. Is this going to cause problems on other components?
    treeKey: STRUCTURAL_TYPES.VISUAL_GROUP
  },
  [STRUCTURAL_TYPES.SLICE_GROUP]: {
    minChildren: 2,
    onDeleteModifyChildren: [
      { type: 'delete_prop', prop: 'startTime' }
    ],
    deleteGroupIfFirstChildIsDeleted: true,
    treeKey: STRUCTURAL_TYPES.SLICE_GROUP
  },
  [STRUCTURAL_TYPES.CONDITIONAL_AGG]: {
    minChildren: 0,
    treeKey: 'conditions' // TREAT LIKE "AND", SAME FUNCTIONALITY
  },
  [STRUCTURAL_TYPES.FILTER]: {},
  [STRUCTURAL_TYPES.STAT]: {},
}


export const STRUCTURAL_CATEGORIES = {
  GROUP_WITH_ENTITY: 'GROUP_WITH_ENTITY',
  GROUP_WITHOUT_ENTITY: 'GROUP_WITHOUT_ENTITY',
  ENTITY: 'ENTITY'
}


const STRUCTURAL_CATEGORY_DEFINITIONS = {
  GROUP_WITHOUT_ENTITY: [STRUCTURAL_TYPES.AND, STRUCTURAL_TYPES.OR, STRUCTURAL_TYPES.ROOT, STRUCTURAL_TYPES.SLICE_GROUP],
  GROUP_WITH_ENTITY: [STRUCTURAL_TYPES.VISUAL_GROUP, STRUCTURAL_TYPES.CONDITIONAL_AGG],
  ENTITY: [STRUCTURAL_TYPES.FILTER, STRUCTURAL_TYPES.STAT]
}


/** 
 * @param {keyof STRUCTURAL_TYPES} type
 * @returns {keyof STRUCTURAL_CATEGORY_DEFINITIONS}
 **/
export const getNodeTypeCategory = (type) => {
  if (STRUCTURAL_CATEGORY_DEFINITIONS.GROUP_WITH_ENTITY.includes(type)) return STRUCTURAL_CATEGORIES.GROUP_WITH_ENTITY;
  if (STRUCTURAL_CATEGORY_DEFINITIONS.GROUP_WITHOUT_ENTITY.includes(type)) return STRUCTURAL_CATEGORIES.GROUP_WITHOUT_ENTITY;
  if (STRUCTURAL_CATEGORY_DEFINITIONS.ENTITY.includes(type)) return STRUCTURAL_CATEGORIES.ENTITY;
  return null;
}

/**
 * @param {object} node
 * @returns {keyof STRUCTURAL_TYPES}
 **/
export function decideProfileNodeType(node) {
  if (!node || !(typeof node === 'object')) return null;

  if ('operator' in node) return STRUCTURAL_TYPES.FILTER;
  if ('stat' in node) return STRUCTURAL_TYPES.STAT;
  if ('conditional_agg' in node) return STRUCTURAL_TYPES.CONDITIONAL_AGG;
  if (STRUCTURAL_TYPES.ROOT in node) return STRUCTURAL_TYPES.ROOT;
  if (STRUCTURAL_TYPES.VISUAL_GROUP in node) return STRUCTURAL_TYPES.VISUAL_GROUP;
  if (STRUCTURAL_TYPES.AND in node) return STRUCTURAL_TYPES.AND;
  if (STRUCTURAL_TYPES.OR in node) return STRUCTURAL_TYPES.OR;
  if (STRUCTURAL_TYPES.SLICE_GROUP in node) return STRUCTURAL_TYPES.SLICE_GROUP;

  return null;
}




/**
 * Enum for the position of a child node relative to its parent group
 * Position excludes children that are Groups themselves.
 * @readonly
 * @enum {string}
 */
export const NODE_POSITIONS = {
  FIRST: 'FIRST',
  LAST: 'LAST',
  MIDDLE: 'MIDDLE'
};




export const formToProfile = FormToProfile;
export const profileToForm = ProfileToForm;
