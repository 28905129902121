
import { ClickAwayListener, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trash2 } from 'react-feather';
import ActionList from 'src/app/components/ActionList';
import { CheckIcon, CopyIcon, EditIcon, PlusIcon2 } from 'src/theme/EdgeIcons';
import LoopIcon from '@material-ui/icons/Loop';
import { Maximize2 as Maximize2Icon } from 'react-feather';
import onEnter from 'src/utils/onEnter';

const useStyles = makeStyles(theme => ({
  root: {
    '& .ActionList-Item': {
      borderRadius: 0,
      paddingLeft: 8,
      paddingRight: 16,
      '& .ActionList-Leading': {
        color: theme.palette.text.icon
      }
    }
  },
  divider: {
    borderTop: [[1, 'solid', theme.palette.background.lightBorder]],
  },
  errorColor: {
    color: theme.palette.error.main
  }
}));



function DoubleConfirmationWrapper({
  initialItem = null,
  onClose,
  children
}) {
  const [confirmingItem, setConfirmingItem] = useState(initialItem);

  const handleAction = useCallback((
    itemKey,
    action,
    {
      requiresConfirm = false,
      closeOnComplete = true
    }
  ) => {
    if (confirmingItem && confirmingItem !== itemKey) {
      setConfirmingItem(null);
      return;
    }
    if (confirmingItem === itemKey) {
      setConfirmingItem(null);
      action?.();
      closeOnComplete && onClose?.();
      return;
    }
    if (requiresConfirm) {
      setConfirmingItem(itemKey);
    } else {
      action?.();
      onClose?.();
    }
  }, [confirmingItem, onClose]);

  const handleClickAway = useCallback(() => {
    setConfirmingItem(null);
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      {children({ handleAction, confirmingItem })}
    </ClickAwayListener>
  )
}




/**
 * @param {Object} props
 * @param {string} className
 * @param {React.RefObject} treeRef
 * @param {function} onReplace
 * @param {function} onClose
 * @param {*} children
*/
export default function MosaicPanelControlsKebabMenu({
  className,
  onSplit,
  onExpand,
  onReplace,
  onDelete,
  onClose,
  children,
}) {
  const classes = useStyles();
  const firstButtonRef = useRef(null);

  useEffect(() => {
    if (firstButtonRef.current) {
      firstButtonRef.current.focus();
    }
  }, [Boolean(firstButtonRef.current)]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    if (e.key === "Escape") {
      onClose(e);
      return;
    }

    const menuItems = Array.from(e.currentTarget.querySelectorAll('.MuiListItem-root[tabindex="0"]'));
    const currIndex = menuItems.indexOf(document.activeElement);

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      const nextIndex = (currIndex + 1) % menuItems.length;
      menuItems[nextIndex].focus();
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      const prevIndex = (currIndex - 1 + menuItems.length) % menuItems.length;
      menuItems[prevIndex].focus();
    }

    e.stopPropagation();
  }, [onClose]);

  return (
    <DoubleConfirmationWrapper onClose={onClose}>
      {({ handleAction, confirmingItem }) => (
        <div onKeyDown={handleKeyDown} className={className}>
          <ActionList>
            {Boolean(onSplit) && (
              <ActionList.Item
                ref={firstButtonRef}
                tabIndex={0}
                className={classes.kebabItem}
                button
                disableRipple
                onClick={() => handleAction('split', onSplit, { requiresConfirm: false })}
                onKeyDown={onEnter(() => handleAction('split', onSplit, { requiresConfirm: false }))}
              >
                <ActionList.Leading>
                  <PlusIcon2 className={clsx("plusIcon")} />
                </ActionList.Leading>
                Split
              </ActionList.Item>
            )}
            {Boolean(onExpand) && (
              <ActionList.Item
                tabIndex={0}
                className={classes.kebabItem}
                button
                disableRipple
                onClick={() => handleAction('expand', onExpand, { requiresConfirm: false })}
                onKeyDown={onEnter(() => handleAction('expand', onExpand, { requiresConfirm: false }))}
              >
                <ActionList.Leading>
                  <Maximize2Icon className={clsx("expandIcon")} size={18} />
                </ActionList.Leading>
                Expand
              </ActionList.Item>
            )}
            {Boolean(onReplace) && (
              <ActionList.Item
                className={clsx(classes.kebabItem)}
                tabIndex={0}
                button
                disableRipple
                onClick={() => handleAction('replace', onReplace, { requiresConfirm: true })}
                onKeyDown={onEnter(() => handleAction('replace', onReplace, { requiresConfirm: true }))}
              >
                <ActionList.Leading>
                  {confirmingItem === 'replace' ? <CheckIcon className={clsx("checkIcon")} /> : <LoopIcon className={clsx("loopIcon")} />}
                </ActionList.Leading>
                {confirmingItem === 'replace' ? 'Confirm?' : 'Replace'}
              </ActionList.Item>
            )}

            {(typeof children === 'function') && children({
              classes,
              confirmingItem,
              handleAction,
              onClose
            })}

            {Boolean(onDelete) && (
              <ActionList.Item
                className={clsx(classes.kebabItem, classes.errorColor, classes.divider)}
                disableRipple
                tabIndex={0}
                button
                onClick={() => handleAction('delete', onDelete, { requiresConfirm: true })}
                onKeyDown={onEnter(() => handleAction('delete', onDelete, { requiresConfirm: true }))}
              >
                <ActionList.Leading className={classes.errorColor}>
                  {confirmingItem === 'delete' ? <CheckIcon className={clsx("checkIcon")} /> : <Trash2 className={clsx("trashIcon")} />}
                </ActionList.Leading>
                {confirmingItem === 'delete' ? 'Confirm?' : 'Delete'}
              </ActionList.Item>
            )}
          </ActionList>
        </div>
      )}
    </DoubleConfirmationWrapper>
  )
}


