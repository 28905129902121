import {
  getNodeTypeCategory,
  STRUCTURAL_CATEGORIES,
  STRUCTURAL_TYPES,
  STRUCTURAL_TYPE_RULES,
  PROFILE_TYPES_NEEDING_INSERTED_ROOT_NODE,
} from 'src/app/slicedForm/mapping/mappingDirections/index';
import { decideProfileNodeType, FILTER_FORM_TYPES, ACTIVE_FILTER_FORM_TYPE_ID_NAME } from 'src/app/slicedForm/mapping/mappingDirections/index';
import {
  createEntityAndLeaf,
  createEntityValues
} from 'src/app/slicedForm/mapping/formTreeManipulation';

/** @typedef {import('./index.js').ProfileStruct} ProfileStruct */
/** @typedef {import('../../utility/arborHelpers.js').TreeItem} TreeItem */
/**
 * @template T
 * @typedef {T[keyof T]} ValueOf
 */



/**
 * Works on an individual profile.
 * Specifically destructures whatever the content of a profile is into normalized state.
 * Could be columns, filters, aggregates, (+entities if required)
 * @param {ProfileStruct} profile
 * @param {ValueOf<FILTER_FORM_TYPES>} formType
 * @returns {{ entities: {}, profile: {}}}
 */
function normalizeProfileContent([profileId, profile], formType) {
  const contentKeyword = formType; // filters/columns/etc

  let { [contentKeyword]: content, ...rest } = profile;

  if (!content) {
    console.warn(`Profile does not have required attribute for formType, ${formType}, ${profile?.name}`);
    return { entities: {}, profile };
  }

  if (contentKeyword === FILTER_FORM_TYPES.COLUMN) {
    // Columns require no mapping
    return { entities: {}, profile };
  }

  if (PROFILE_TYPES_NEEDING_INSERTED_ROOT_NODE.includes(formType)) {
    // Aggregates have an array as the root node. That does not work with any rendering logic.
    // Add a fake wrapper node to make life worth living.
    content = { [STRUCTURAL_TYPES.ROOT]: content };
  }

  const entities = {};

  // Filters and aggregates are mapped the same way
  const mapppedContent = (function recurse(node) {
    const type = decideProfileNodeType(node);
    const category = getNodeTypeCategory(type);
    const treeKey = STRUCTURAL_TYPE_RULES[type]?.treeKey;

    switch (category) {
      case STRUCTURAL_CATEGORIES.GROUP_WITHOUT_ENTITY: {
        const { [treeKey]: children } = node;
        const { leaf } = createEntityAndLeaf({
          type,
          tree: children.map(recurse),
        });

        return leaf;
      }
      case STRUCTURAL_CATEGORIES.GROUP_WITH_ENTITY: {
        const { [treeKey]: children, ...values } = node;
        const { id, entity, leaf } = createEntityAndLeaf({
          type,
          tree: children?.map(recurse) || [],
          values: createEntityValues(values)
        });

        entities[id] = entity;
        return leaf;
      }
      case STRUCTURAL_CATEGORIES.ENTITY: {
        const { id, entity, leaf } = createEntityAndLeaf({
          type,
          values: createEntityValues(node)
        });

        entities[id] = entity;

        return leaf;
      }
      default: {
        throw new Error(`Unknown node category "${category}<${type}>", node ${JSON.stringify(node)}}`);
      }
    }
  })(content);

  return { entities, profile: { ...rest, root: mapppedContent } };
}


/**
MAJOR TODO:
  I'm getting lost with the whole "content" thing. I think we should:
    - combineReducers instead of reduceReducers
    - filters, columns, profileMap, expressions can all be seperate
      - By having multiple reducers respond to the same action, they no longer need to share state
      - this way, we can have multi-step forms, and not have keys clashing
    - Expressions maybe cannot be combined
    - CHECK if profileMap+profileTree can be seperated

    - Keys like:
    {
      activeProfileID: '',
      profileMap: profileMapReducer,
      profileTree: profileTreeReducer
      filters: filterReducer, -> { entities, content, activeId }
      aggregates: filterReducer -> { entities, content, activeId }
      columns: columnReducer -> { content, activeId }
    }
*/


/**
 * Single function for mapping all types of profiles into SlicedForm state.
 * Note: It probably makes sense to seperate these out, but right now this is fine.
 * @param {object} state
 * @param {{string: ProfileStruct}} state.profileMap
 * @param {{string: import('../../utility/arborHelpers.js').TreeItem}} state.profileTree
 * @param {}
 * @param {ValueOf<FILTER_FORM_TYPES>} formType
 * @returns {{Object}}
 */
export default function profileToForm({
  profileMap,
  profileTree,
  activeProfile,
  expressions,
  ...rest
},
  formType
) {

  // First, turn profileMap into denormalized { [content]: { prof_1: root }, entities: { ent_1... }, profileMap: { prof1: name } }
  // profileMap will have [content] removed from it, as state.activeFilterId, etc, will be the content's base
  const mapped = Object.entries(profileMap).reduce((acc, originalProf) => {
    const id = originalProf[0];
    const { entities, profile } = normalizeProfileContent(originalProf, formType);
    return {
      entities: { ...acc.entities, ...entities },
      profileMap: { ...acc.profileMap, [id]: profile }
    }
  }, { content: {}, entities: {}, profileMap: {} });


  const state = {
    ...rest, // Allow extra properties. This might bite me later...
    formType,
    activeProfile,
    entities: mapped.entities,
    profileMap: mapped.profileMap,
    expressions
  }

  if (profileTree) {
    state.profileTree = {
      items: profileTree.items,
      openState: profileTree.openState,
      newItemIndicators: [] // Not saved to DB
    }
  }

  console.debug('profileToForm INIT', state);
  return state;
}



