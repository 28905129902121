import _cloneDeep from 'lodash/cloneDeep';
import {
  VALUE_TYPES,
  ARRAY_OPS,
  BOOLEAN_OPS,
  DATE_TYPES,
  AGGREGATES,
} from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import {
  getCurrentTradingDay,
  minutesOfDay,
  parseAssumeMarketTime
} from 'src/utils/datetime/date-fns.tz';
import { format } from 'date-fns';
import { decideProfileNodeType, STRUCTURAL_TYPE_RULES, STRUCTURAL_TYPES } from 'src/app/slicedForm/mapping/mappingDirections/index';
import { isRollingKey, getRangeDateStrings } from 'src/app/components/pickers/definitions/staticRangeDefinitions';
import { EXPR_PREFIX } from 'src/redux/expressions/globalExpressionReducer.js';
import { expressionListToMap } from '../shared/reducers/expressionReducer.js';


/**  @typedef {import('./mappingDirections/index.js').FormStruct} FormStruct */
/**  @typedef {import('./mappingDirections/index.js').ProfileNodeBinaryArg} ProfileNodeBinaryArg */
/**  @typedef {import('./mappingDirections/index.js').ProfileFilterNode} ProfileFilterNode */
/**  @typedef {import('./mappingDirections/index.js').ProfileGroupNode} ProfileGroupNode */
/**  @typedef {import('./mappingDirections/index.js').QueryNodeBinaryArg} QueryNodeBinaryArg */
/**  @typedef {import('./mappingDirections/index.js').QueryFilterNode} QueryFilterNode */


/** kludge to get some logging context. We need better strategies for this. **/
var ctx = '';


/**
 * Map a query before sending it into the database. The DB doesn't accept the exact same structure
 * as the frontend. The frontend needs more information and context to work.
 *
 * Changes the following. Note, order can be important.
 *  - Resolves SLICE_GROUPS into a single filter based on date. (SLICE_GROUP[] -> FILTER_NODE{})
 *  - Resolve 'range' rolling dates into real BETWEEN dates
 *  - Insert Expression content into the query, from Redux source
 *  - Modifies aggregates:
 *      - Removes useless nodes like VISUAL_GROUP
 *      - Hard-codes the COUNT IF target. This is the only aggregate that does not follow the typical rules in SQL.
 *  - Replace BETWEEN with two ANDs (col > A AND col <= B)
 *  - Remove outdated node.left.allowNull. (Now exists under node.allowNull, server handles)
 *  - Remove extranious Arg keys, like column, value, type.
 *  - Remove array from right-hand arguments (flatten, node.right[] -> node.right{})
 *
 * @param {object} profile
 * @param {object} [profile.filters] - Recursive filter section
 * @param {object} [profile.columns] - Flat column list
 * @param {object} [profile.aggregates] - Recursive aggregate section
 * @param {object} [profile.sortArgs] 
 * @param {string} profile.sortArgs.order - 'asc' or 'desc'
 * @param {string} profile.sortArgs.orderby - Column name to sort by
 * @param {string} day - yyyy-MM-dd
 * @param {string[]} excludedTickers - Component-Level filter to exclude tickers
 * @param {object[]} expressions - Actual expression content from Redux. Match against ID's from profile.
 * @returns {Object} { filters, columns, aggregates, order } - Mapped to the database format. All are optional, based on input.
 */


export default function profileToQuery(
  profile,
  day,
  excludedTickers = [],
  expressions = [],
) {
  const cloned = _cloneDeep(profile);
  let { filters, columns, aggregates, sortArgs } = cloned;

  const expressionMap = expressionListToMap(expressions);

  const out = {};

  if (filters) {
    ctx = 'filter_args';
    out.filters = addExplicitArgumentsToFilters({ filters, day, excludedTickers });
    ctx = 'filters'
    out.filters = modifyFilters({ filters: out.filters, expressionMap });
  }

  if (aggregates) {
    ctx = 'aggregates_visual';
    let flatAggregates = removeAggregateVisualGroup(aggregates);
    // COUNT(CASE ... THEN 1), we need to remove target.column and replace with target.value = 1
    ctx = 'aggregates_count';
    flatAggregates = hardCodeCountAggregateTarget(flatAggregates);
    // We can re-use modifyFilters, operating on the aggregate as the root node;
    ctx = 'aggregates';
    // If expresisons fail to resolve, remove the agg.
    out.aggregates = flatAggregates
      .map(agg => modifyFilters({ filters: agg, expressionMap }))
      .filter(Boolean);
  }

  if (columns) {
    ctx = 'columns'
    out.columns = modifyColumns({ columns, expressionMap });
  }

  if (sortArgs) {
    ctx = 'order'
    out.order = modifyOrder(sortArgs, expressionMap);
  }

  ctx = '';
  return out;
}


/**
 * agg.target is equal to some column just so the profile has a column to 
 * switch back to. Its not the actual target.
 *
 * The actual target is:
 *  - CNT without conditions: { column: 'ticker' }
 *  - CNT with conditions: { value: 1 }
 *
 * @param {object[]} aggregates
 * @returns {object[]} - The modified aggregates
 **/
function hardCodeCountAggregateTarget(aggregates) {
  return aggregates.map(agg => {
    if (agg?.conditional_agg === AGGREGATES.CNT) {
      return {
        ...agg,
        target: agg?.conditions?.length
          ? { value: 1, type: 'value' }
          : { column: 'ticker', type: 'column' }
      }
    }
    return agg;
  })
}



/**
 * Remove the visual group from the aggregates and flatten.
 * Recursive, can handle nesting.
 * Can handle when there are no visual groups.
 *
 * @param {object[]} aggregates
 * @returns {object[]} - The modified aggregates
 **/
function removeAggregateVisualGroup(aggregates) {
  return aggregates.flatMap((item) => {
    const type = decideProfileNodeType(item);
    const treeKey = STRUCTURAL_TYPE_RULES[type]?.treeKey;

    // If this item is a visual group, merge all its children in place, 
    // but also recursively remove any nested visual groups in those children.
    if (type === STRUCTURAL_TYPES.VISUAL_GROUP) {
      const children = Array.isArray(item[treeKey]) ? item[treeKey] : [];
      return removeAggregateVisualGroup(children);
    }

    // If it's NOT a visual group, keep recursing
    if (treeKey && Array.isArray(item[treeKey])) {
      return [
        {
          ...item,
          [treeKey]: removeAggregateVisualGroup(item[treeKey]),
        },
      ];
    }

    // If no treeKey or children, just return the item as is
    return [item];
  });
}



/**
 * Resolve the orderby argument, and format for query
 * @param {object} sortArgs
 * @param {string} sortArgs.order - 'asc' or 'desc'
 * @param {string} sortArgs.orderby - Column name (or expression) to sort
 * @param {object} expressionMap - Map of expression ID's to their content
 * @returns {object[]} - The formatted order object
 **/
function modifyOrder(sortArgs, expressionMap) {
  const orderItem = {};

  try {
    // only inserts if necessary, otherwise does nothing
    orderItem.order_by = substituteExpression({ column: sortArgs.orderby }, expressionMap);
  } catch (err) {
    // Unknown columns will result in default order being applied serverside
    // Technically, this shouldn't happen. Missing expressions should cause the orderby 
    // to be reset to a clientside default before this point.
    orderItem.order_by = { column: sortArgs.orderby };
  }
  orderItem.order = sortArgs.order.toUpperCase();

  return [orderItem];

}


function addExplicitArgumentsToFilters({ filters, day, excludedTickers }) {
  if (!filters || !Object.keys(filters).length) {
    filters = { AND: [] };
  }

  if (day) {
    try {
      filters.AND.push({
        left: { column: 'day0_date', type: VALUE_TYPES.column },
        operator: BOOLEAN_OPS.EQ,
        right: [{ value: day, type: VALUE_TYPES.value }]
      });
    } catch (err) {
      console.warn(`${ctx} topListFormatQueriesV2() day: Filters.AND doesnt exist in top-level', ${filters}`);
    }
  }
  if (excludedTickers && excludedTickers.length) {
    try {
      filters.AND.push({
        left: { column: 'ticker', type: VALUE_TYPES.column },
        operator: ARRAY_OPS.NIN,
        right: [{ value: excludedTickers, type: VALUE_TYPES.value }]
      })
    } catch (err) {
      console.warn(`${ctx} topListFormatQueriesV2() excludedTickers: Filters.AND doesnt exist in top-level ${filters}`);
    }
  }

  return filters;
}


function modifyColumns({ columns, expressionMap }) {
  if (!columns) return columns;

  return columns.map(col => {
    try {
      return substituteExpression(col, expressionMap);
    } catch (err) {
      console.warn(`${ctx} modifyColumns() error, ${err}`);
      return null;
    }
  }).filter(Boolean);
}



/**
 * SQL's BETWEEN isn't valuable for us. Its double-inclusive.
 * Replace a single BETWEEN clause with two OR clauses.
 *
 * The second clause will assume the first one's type
 *
 * We could do this serverside, but in the future we might want a real BETWEEN serverside.
 * @param {ProfileFilterNode} node
 * @param {Array<BOOLEAN_OPS>} operators - Which operators to replace with AND
 * @returns {ProfileGroupNode} - If transformable, return AND group
 * @throws Error - The node is not transformable
 */
export function replaceBetweenWithAnd(node, operators = [BOOLEAN_OPS.GE, BOOLEAN_OPS.LT]) {
  if (!Array.isArray(operators) || !operators.length === 2) {
    throw new Error(`${ctx} replaceBetweenWithAnd() requires an array of two BOOLEAN_OPS, one for each comparison`);
  }
  if (!node.operator === BOOLEAN_OPS.BTW || !Array.isArray(node.right)) {
    throw new Error(`${ctx} replaceBetweenWithAnd() called on non-BTW node, ${JSON.stringify(node)}`);
  }
  const argList = node.right.slice(0, 2);
  if (!argList[0] || !argList[1]) {
    throw new Error(`${ctx} replaceBetweenWithAnd() called on BTW node with missing arguments. 0:${argList[0]} 1:${argList[1]}`);
  }

  // Extra properties. Right now, just allowNull
  const { left, operator, right, ...rest } = node;

  return {
    AND: [
      {
        ...rest,
        left: { ...node.left },
        operator: operators[0],
        // Using arrays may seem strange, but its for consistency. We remove the arrays later, in flattenRightArgumentArray
        right: [{ ...argList[0] }],
      },
      {
        ...rest,
        left: { ...node.left },
        operator: operators[1],
        right: [{
          ...argList[1],
          // Assume the first one's type
          type: argList[0].type
        }]
      }
    ]
  }
}


export function resolveRollingDateRange(node) {
  if (!decideProfileNodeType(node) === STRUCTURAL_TYPES.FILTER) {
    throw new Error(`${ctx} resolveRollingDateRange() called on non-filter node`);
  }
  if (!node?.dateType === DATE_TYPES.ROLLING) {
    throw new Error(`${ctx} resolveRollingDateRange() called on non-rolling date`);
  }
  let rollingKey = node.right[0]?.value;
  if (!isRollingKey(rollingKey)) {
    throw new Error(`${ctx} resolveRollingDateRange() called on unknown rolling_key value. right[0].value: ${node?.right[0]?.value}, node: ${node}`);
  }
  // TODO: What about different formats? We'd have to pull in colDefs, but thats not a fixed value.
  // We could pass in colDefs on mapping functions? That probably makes the most sense. Ignore for now.

  // Rolling dates always reference TODAY as end date, for now. Ignore BTW, ignore endDate.
  const { startDate } = getRangeDateStrings(rollingKey, 'yyyy-MM-dd');
  const { dateType, ...leftRest } = node.left;
  return {
    ...node,
    left: {
      ...leftRest,
    },
    right: [{
      type: VALUE_TYPES.value,
      column: null,
      value: startDate,
    }]
  }
}


/**
  * Transform the full Arg to the shortened Query version
  * @param {ProfileNodeBinaryArg} side
  * @returns {QueryNodeBinaryArg}
  */
const resolveArg = (side) => {
  if (!side) return {};

  if (side.type === VALUE_TYPES.column) {
    const { type, value, ...rest } = side;
    return rest;
  }
  if (side.type === VALUE_TYPES.value) {
    const { type, column, ...rest } = side;
    return rest;
  }
  console.warn(`${ctx} Arg type not recognized`, JSON.stringify(side));
  return side;
};


/**
 * FILTER entities right-hand arguments look like this client-side:
 *      { column: 'vol', value: 200, type: VALUE_TYPES.value };
 *    Resolve them to a single property, based on 'type':
 *      { value: 200 }
 * @param {ProfileFilterNode} node
 * @returns {QueryFilterNode}
 */
export function resolveNodeBinaryArguments(node) {
  let left = resolveArg(node.left);
  let right;
  if (Array.isArray(node.right)) {
    right = node.right.map(resolveArg);
  } else {
    right = resolveArg(node.right);
  }

  return { ...node, left, right };
}


/**
 * Insert the expression content into the node, if applicable.
 * FROM:
 * { right: { column: 'expr_0' } }
 * TO:
 * { right: {
 *     expression: 'A + B',
 *     args: {A: ..., B: ...},
 *     label: 'My Expression'
 *  } }
 * @param {QueryFilterNode} node
 * @param {Object<str, Object>} expressionMap - Map of expression ID's to their content
 * @returns {QueryFilterNode}
 *
 **/
function insertExpression(node, expressionMap) {
  if (!decideProfileNodeType(node) === STRUCTURAL_TYPES.FILTER) {
    console.warn(`${ctx} insertExpression only accepts FILTER nodes, ignoring`);
    return node;
  }

  let left = substituteExpression(node.left, expressionMap);
  let right;
  if (Array.isArray(node.right)) {
    right = node.right.map(node => substituteExpression(node, expressionMap));
  } else {
    right = substituteExpression(node.right, expressionMap);
  }

  return { ...node, left, right };
}


export function substituteExpression(node, expressionMap) {
  if (!node.column || !(typeof node.column === 'string')) return node;
  if (!node.column.startsWith(EXPR_PREFIX)) return node;

  const exprObject = expressionMap[node.column];

  if (!exprObject) {
    throw Error(`${ctx} Expression ${node.column} not found. Removing node.`);
  }
  if (exprObject?.invalid) {
    throw Error(`${ctx} Expression ${node.column} is invalid. Removing node.`);
  }

  const { column, ...rest } = node;
  return {
    ...rest,
    expression: exprObject?.expression,
    args: exprObject?.args,
    label: exprObject?.name
  }
}



/**
 * Right arguments are arrays. We need them to be flat. pick the first one.
 * Make sure you map BETWEEN to AND before this step, or you will lose data.
 *  node.right = [binaryArg] => node.right = binaryArg
 *  @param {QueryFilterNode} node
 *  @returns {QueryFilterNode}
 */
export function flattenRightArgumentArray(node) {
  if (!decideProfileNodeType(node) === STRUCTURAL_TYPES.FILTER) {
    console.warn(`${ctx} flattenRightArgumentArray only accepts FILTER nodes, ignoring`);
    return node;
  }
  if (node.operator === BOOLEAN_OPS.BTW) {
    console.warn(`${ctx} Found BTW query operator. This probably should have been replaced with AND. Ignoring.`, node);
    return node;
  }
  if (!Array.isArray(node.right)) {
    return node;
  }
  return { ...node, right: node.right[0] }
}


/**
 * Take the current time, and pick the single SLICE that matches.
 * Remove all other slices. It becomes a single filter node.
 * @param {ProfileGroupNode} node - Slice Group node
 * @param {Date} now
 * @return {ProfileFilterNode} - Filer node that it contains, no slice group
 */
export function resolveSliceGroup(node, now) {
  let selectedNode = node.SLICE_GROUP[0];

  for (let i = 0; i < node.SLICE_GROUP.length; i++) {
    const sliceNode = node.SLICE_GROUP[i];
    const nextSliceNode = node.SLICE_GROUP[i + 1];

    if (nextSliceNode === undefined) {
      // last node, this has to be it
      // TODO: what about 00->04am?
      selectedNode = sliceNode;
      break;
    }

    const nowMinutes = minutesOfDay(now);
    const startTimeMinutes = minutesOfDay(parseAssumeMarketTime(sliceNode.startTime, 'HH:mm'));
    const nextStartTimeMinutes = minutesOfDay(parseAssumeMarketTime(nextSliceNode.startTime, 'HH:mm'));

    if (nowMinutes >= startTimeMinutes && nowMinutes < nextStartTimeMinutes) {
      selectedNode = sliceNode;
      break;
    }
  }

  /* eslint-disable-next-line no-unused-vars */
  const { startTime, ...newNode } = selectedNode;
  return newNode;
}



/**
 * Recurse the tree, and apply all modifications
 * @param {Object} args
 * @param {Object} args.filters - The root of the filters tree
 * @param {Date} args.now - localized
 * @param {Object<str, Object>} args.expressionMap - Map of expression ID's to their content
 */
function modifyFilters({
  filters,
  now = getCurrentTradingDay(),
  expressionMap = {}
}) {
  if (!filters) return filters;

  const recurse = (node) => {
    const type = decideProfileNodeType(node);
    const treeKey = STRUCTURAL_TYPE_RULES[type]?.treeKey;

    switch (type) {
      case STRUCTURAL_TYPES.AND:
      case STRUCTURAL_TYPES.OR: {
        const nodes = node[treeKey].map(recurse);
        // If expression is missing, we signify it. Remove those nodes.
        return { [treeKey]: nodes.filter(Boolean) }
      }
      case STRUCTURAL_TYPES.SLICE_GROUP: {
        const newNode = resolveSliceGroup(node, now);
        return recurse(newNode);
      }
      case STRUCTURAL_TYPES.CONDITIONAL_AGG: {
        const { name, label, target: originalTarget, [treeKey]: nodes, ...rest } = node;

        let target;
        try {
          target = substituteExpression(resolveArg(originalTarget), expressionMap);
        } catch (err) {
          console.debug(err)
          return null // signifier to skip this element.
        }

        const children = nodes?.map(recurse).filter(Boolean) || [];

        return {
          ...rest,
          label: name, // backend uses label as alias field
          target,
          ...(children.length ? { [treeKey]: children } : {}) // Save some data
        }
      }
      case STRUCTURAL_TYPES.FILTER: {
        let newNode = node;

        if (newNode?.dateType === DATE_TYPES.ROLLING) {
          try {
            newNode = resolveRollingDateRange(newNode);
            // No new group created, continue
          } catch (err) {
            console.warn(err)
          }
        }

        if (newNode.operator === BOOLEAN_OPS.BTW) {
          try {
            newNode = replaceBetweenWithAnd(newNode);
            // the node is now an AND group. Keep recursing.
            return recurse(newNode)
          } catch (err) {
            console.warn(err)
          }
        }


        if (newNode?.left?.allowNull) {
          try {
            // node.left.allowNull is no longer valid.
            // We use node.allowNull, and handle it serverside
            const { allowNull, ...rest } = newNode.left;
            newNode = {
              ...newNode,
              left: rest
            }
          } catch (err) {
            console.warn(err)
          }
        }

        newNode = resolveNodeBinaryArguments(newNode);

        try {
          newNode = insertExpression(newNode, expressionMap);
        } catch (err) {
          console.debug(err)
          return null; // signifier to skip this element.
        }

        return flattenRightArgumentArray(newNode);
      }
      default: {
        console.warn(JSON.stringify(filters));
        throw new Error(`${ctx} Unknown node type "${type}", node ${JSON.stringify(node)}}. Check logs.`);
      }
    }
  };

  return recurse(filters);
}
