import {
  Button,
  lighten,
  ListItem,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  itemLeaf: {
    display: 'flex',
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5),
    '& .MuiButton-root.Mui-disabled': {
      color: theme.palette.action.disabled
    }
  },
  buttonLeaf: {
    color: theme.palette.text.icon,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    minWidth: 0,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      backgroundColor: theme.palette.action.hover600
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.icon
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.main, .04)
    }
  }
}));



function NavItem({
  title,
  href,
  action,
  isLogout,
  propKey,
  depth,
  icon: Icon,
  className,
  open: openProp,
  info: Info,
  exact = true,
  newTab,
  disabled,
  ...rest
}) {
  const classes = useStyles();

  const renderButton = () => {
    if (action) {
      return (
        <Button
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          disabled={disabled}
          onClick={action}
          title={title}
        >
          {Icon && (
            <Icon className={classes.icon} />
          )}
        </Button>
      )
    }
    if (href && href.includes('http')) {
      return (
        <Button
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          disabled={disabled}
          href={href}
          target={newTab ? "_blank" : "_self"}
          title={title}
        >
          {Icon && (
            <Icon className={classes.icon} />
          )}
        </Button>
      )
    }
    return (
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={NavLink}
        disabled={disabled}
        exact={exact}
        to={href}
        title={title}
      >
        {Icon && (
          <Icon
            className={classes.icon}
            size="20"
          />
        )}
      </Button>
    )
  }




  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={propKey}
      {...rest}
    >
      {renderButton()}
    </ListItem>
  );

}


NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  action: PropTypes.func,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  isLogout: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
