import {
  Box,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import _uniqueId from 'lodash/uniqueId';
import React, { useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import shortUuid from 'short-uuid';
import { useInterval } from 'src/hooks/useInterval';
import TabsBarTabItem from './TabsBarTabItem';
import { useLayoutEffect } from 'react';


/** @typedef {import("./TabsBarTabItem.js").TabItem} TabItem */


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
  },
  item: {
    transition: ['flex-basis 150ms ease'],
    minWidth: 30,
    flexBasis: 360,
    flexGrow: 0,
    flexShrink: 1,
    padding: [[theme.spacing(1), theme.spacing(.5)]],
    '&$leaving': {
      flexBasis: 0,
      minWidth: 0,
    },
    '&$entering': {
      animation: '$entering 150ms ease-out forwards'
    }
  },
  leaving: {},
  entering: {},
  '@keyframes entering': {
    from: { flexBasis: 0, minWidth: 0 },
    to: { flexBasis: 360, width: 30 }
  }
}));


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getListStyle = isDraggingOver => {
  return isDraggingOver ? { pointerEvents: 'none' } : {};
};




/**
 * @param {object} props
 * @param {string} props.className
 * @param {TabItem[]} props.items
 * @param {string} props.selected
 * @param {function(TabItem)} props.onClick
 * @param {function(TabItem)} props.onRemove
 * @param {function(string[])} props.onReorder
 */
function TabsBarTabs({
  className,
  selected,
  items,
  onClick,
  onRemove,
  onReorder
}) {
  const [id] = useState(() => _uniqueId('tabs-bar-'))
  const classes = useStyles();
  const [newItemKeys, setNewItemKeys] = useState([]);
  const prevItemKeysRef = useRef((items || []).map(item => item.key));
  const [placeholders, setPlaceholders] = useState([]);
  const [reset, setReset] = useState(0);

  useLayoutEffect(() => {
    const prevKeys = (prevItemKeysRef.current || []);
    const currentKeys = items.map(i => i.key);

    const addedKeys = currentKeys.filter(k => !prevKeys.includes(k));
    if (addedKeys.length > 0) {
      setNewItemKeys(prev => [...prev, ...addedKeys]);
    }
    prevItemKeysRef.current = items.map(i => i.key);
  }, [items]);

  useInterval(() => {
    // after waiting, now we can remove the placeholder and allow the existing tabs to resize
    // give the leaving placeholder a shrinking animation
    setPlaceholders(prev => prev.map(pl => ({ ...pl, className: clsx(classes.item, classes.leaving) })));
  }, placeholders.length ? 1400 : null, reset);


  const handleRemove = (item) => {
    // Insert a placeholder to prevent the sizes of existing tabs from changing to soon. Like chrome.
    // Give the entering placehodler an entering animation
    setPlaceholders(prev => [{ key: shortUuid.generate(), className: clsx(classes.item) }, ...prev]);
    // Reset the timer if the user deletes more
    setReset(reset => reset + 1);
    onRemove(item);
  };

  const handleLeavingEnd = (key) => {
    // setPlaceholders(prev => prev.filter(pl => pl.key !== key))
    setPlaceholders([]); // Should be fine to just remove all of them
  }

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.source.index === result.destination.index) {
      return;
    }
    const orderedItems = reorder(items, result.source.index, result.destination.index);

    onReorder(orderedItems.map(item => item.key));
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={id} direction="horizontal">
        {(provided, snapshot) => (
          <Box
            className={clsx(className, classes.root)}
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item, index) => (
              <TabsBarTabItem
                className={clsx(
                  classes.item,
                  newItemKeys.includes(item.key) && classes.entering
                )}
                key={item.key}
                item={item}
                index={index}
                active={item.key === selected}
                isDragDisabled={placeholders.length !== 0}
                onClick={onClick}
                onRemove={handleRemove}
              />
            ))}
            {provided.placeholder}
            {placeholders.map(({ key, className }) => (
              <div key={key} className={className} onTransitionEnd={() => handleLeavingEnd(key)} />
            ))}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
}


export default TabsBarTabs;
