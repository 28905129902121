import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';
import PanelIconButton from 'src/app/components/panels/PanelIconButton';
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks';
import { FilterIcon } from 'src/theme/EdgeIcons';
import {
  Box,
  Typography,
  Popover,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  paperRoot: {
    '&.--with-title .MuiPopover-paper': {
      backgroundColor: theme.palette.background.c.paper[300],
    },
    '& .MuiPopover-paper': {
      border: [[1, 'solid', theme.palette.background.lightBorder]],
      backgroundColor: theme.palette.background.c.paper[500],
      borderRadius: theme.grid.borderRadius,
    },
    '& > div:first-of-type': {
      transition: 'backgroundColor 150ms linear !important',
      backgroundColor: ({ backgroundOpacity }) => backgroundOpacity ? `rgba(0, 0, 0, ${backgroundOpacity}) !important` : 'transparent'
    }
  },
  popoverPanel: {
    '& .popover-title': {
      backgroundColor: theme.palette.background.c.paper[600],
      padding: '4px 13px',
      fontWeight: 600,
      fontSize: 13
    },
    '& .popover-body': {
      padding: '12px 13px',
      fontSize: 13,
      '& .sm-filter-popover-form-control': {
        padding: '7px 0',
      }
    }
  }
}));


function SmallFilterWindow({
  className,
  popoverClassName,
  children,
  shouldRenderProps,
  Icon,
  iconText,
  shouldHideIconText,
  backgroundOpacity,
  iconColor,
  disablePortal,
  ButtonComponent,
  buttonProps,
  buttonText,
  noGutters,
  popoverTitle,
  popoverMinWidth,
  popoverOnClose,
  verticalAnchor,
  closeOnEnter,
  disabled,
}) {
  const classes = useStyles({ backgroundOpacity });
  const [id] = useState(() => _uniqueId('small-filter-window-'));
  const popupState = usePopupState({
    variant: 'popover',
    popupId: id
  });

  useEffect(() => {
    if (disabled && popupState.isOpen) {
      handleClose();
    }
  }, [disabled])

  const handleClose = useCallback(() => {
    popoverOnClose && popoverOnClose();
    popupState.close();
  }, [popupState.close]);


  const handleKeyDown = useCallback((event) => {
    if (closeOnEnter && event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      handleClose();
    }
  }, [handleClose]);


  return (
    <Box className={clsx(classes.root, className)}>
      {ButtonComponent
        ? <ButtonComponent {...bindTrigger(popupState)} {...buttonProps}>{buttonText}</ButtonComponent>
        : <PanelIconButton
          {...bindTrigger(popupState)}
          Icon={Icon}
          text={iconText}
          shouldHideIconText={shouldHideIconText}
          iconColor={iconColor}
          disabled={disabled}
        />
      }
      <Popover
        {...bindPopover(popupState)}
        onClose={handleClose}
        transitionDuration={180}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: verticalAnchor,
          horizontal: 'right'
        }}
        disablePortal={disablePortal}
        style={{ minWidth: popoverMinWidth }}
        className={clsx(
          popoverClassName,
          classes.paperRoot,
          popoverTitle && '--with-title'
        )}
      >
        <Box className={classes.popoverPanel} onKeyDown={handleKeyDown}>
          {popoverTitle && <Typography variant="body1" color="textPrimary" className="popover-title">{popoverTitle}</Typography>}
          <Box className="popover-body" style={noGutters ? { padding: 0 } : {}}>
            {/* TODO: This is ugly... */}
            {shouldRenderProps ? children(handleClose) : children}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}

SmallFilterWindow.propTypes = {
  className: PropTypes.string,
  popoverClassName: PropTypes.string,
  Icon: PropTypes.any,
  iconText: PropTypes.string,
  shouldHideIconText: PropTypes.bool,
  iconColor: PropTypes.string,
  disablePortal: PropTypes.bool,
  backgroundOpacity: PropTypes.number,
  ButtonComponent: PropTypes.any,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  buttonProps: PropTypes.object,
  noGutters: PropTypes.bool,
  popoverTitle: PropTypes.string,
  popoverMinWidth: PropTypes.number,
  popoverOnClose: PropTypes.func,
  verticalAnchor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  children: PropTypes.any,
};

SmallFilterWindow.defaultProps = {
  Icon: FilterIcon,
  popoverMinWidth: 228,
  backgroundOpacity: null,
  disabled: false,
  shouldHideIconText: false,
  buttonProps: {},
  disablePortal: false,
  verticalAnchor: 'top'
};

export default SmallFilterWindow;
