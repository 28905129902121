
/** @typedef {import("ag-charts-community").AgAxisLabelFormatterParams} AgAxisLabelFormatterParams */

const smallPrice = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumIntegerDigits: 1,
  minimumFractionDigits: 2,
  maximumFractionDigits: 3
}).format;

const price = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
}).format;

const compactCurrency = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
}).format;

export const compactInteger = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
}).format;

const percentage = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
}).format;


export const fourDecimal = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 4
}).format;


/**
 * Formatting for small prices. Like ohlc.
 * - Less than 2$ will show 3 decimal places (if last decimal significant, 2 otherwise)
 * - Greater than 2$ will show 2 decimal places (always)
 * - No compaction
 * - Commas added
 * - Add dollar sign
 * @param {number} num
 * @returns {string}
 */
export const formatPrice = num => {
  return num < 2 ? smallPrice(num) : price(num);
}


/**
 * Formatting for percentages. Like change_p.
 * - Always show 2 decimal places
 * - No compaction
 * - Commas added
 * - Add percentage
 *
 * @param {number} num
 * @param {boolean} scaled - Is the number already multiplied by 100? (.10 vs 10%)
 * @returns {string}
 */
export const formatPercentage = (num, scaled = true) => {
  return percentage(scaled ? num / 100 : num)
}


/**
 * Formatting for large prices. Like marketcap.
 * - Always show 2 decimal places
 * - Always compact
 * - Right-padded (Add spacing at end of number if there's no compacton character)
 * - Add dollar sign
 * @param {number} num
 * @returns {string}
 */
export const formatLargePrice = num => {
  return padCompactNumber(compactCurrency(num))
}


/**
 * Formatting for large integers. Like volume.
 * - Always show 2 decimal places
 * - Always compact
 * - Right-padded (Add spacing at end of number if there's no compacton character)
 * @param {number} num
 * @returns {string}
 */
export const formatLargeInteger = num => {
  return padCompactNumber(compactInteger(num))
}


export const matchLastCharRegex = /([KMBT])$/i


/**
 * Adds an empty character to the end of compacted strings that don't have a KMBT postfix
 * @example
 *   500.00  <-- space added
 *     1.00K <-- no space
 *   100.41B <-- no space
 *
 * @param {string} str
 * @return {string|null}
 */
export const padCompactNumber = str => {
  const match = str.match(matchLastCharRegex);

  return match ? str : `${str} `
}



// ag-charts


///** @type {Map<string, Map<number, Intl.NumberFormat>>} */
//const chartFormmaterCache = new Map();
//
///**
// * @param {string} key
// * @param {number|undefined} fractionDigits
// * @returns {Intl.NumberFormat|undefined}
// */
//const getCachedFormatter = (key, fractionDigits) => {
//  const cacheKey = `${key}#${fractionDigits}`;
//  const keyedFormatters = chartFormmaterCache.get(key);
//}
//
//
//const setCachedFormatter = (key, fractionDigits, formatter) => {
//  const cacheKey = `${key}#${fractionDigits}`;
//  chartFormmaterCache.set(cacheKey, formatter);
//}
//
//const handleCache = (key, params, args) => { 
//  if (params.fractionDigits === undefined || params.fractionDigits === null) {
//    return params.value;
//  }
//
//  let formatter = getCachedFormatter(key, params.fractionDigits);
//
//  if (formatter) {
//    return formatter;
//  }
//
//  formatter = new Intl.NumberFormat('en-US', {
//    style: 'currency',
//    currency: 'USD',
//    minimumFractionDigits: params.fractionDigits,
//    maximumFractionDigits: params.fractionDigits
//  });
//
//  setCachedFormatter(key, params.fractionDigits, formatter);
//}
//

/**
 * @param {AgAxisLabelFormatterParams} params
 * @returns {string|undefined}
 */
export const formatPriceChart = params => {
  if (params.fractionDigits === undefined || params.fractionDigits === null) {
    return params.value;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: params.fractionDigits,
    maximumFractionDigits: params.fractionDigits
  });
  return formatter.format(params.value);
}


/**
 * @param {AgAxisLabelFormatterParams} params
 * @returns {string|undefined}
 */
export const formatPercentageChart = (params) => {
  if (params.fractionDigits === undefined || params.fractionDigits === null) {
    return params.value;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: params.fractionDigits,
    minimumFractionDigits: params.fractionDigits
  });
  return formatter.format(params.value / 100);
}

/**
 * @param {AgAxisLabelFormatterParams} params
 * @returns {string|undefined}
 */
export const formatIntegerChart = (params) => {
  if (params.fractionDigits === undefined || params.fractionDigits === null) {
    return params.value;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: params.fractionDigits,
    minimumFractionDigits: params.fractionDigits
  });
  return formatter.format(params.value);
}


