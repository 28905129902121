import { makeStyles } from '@material-ui/core';

const useTabsBarStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex'
  },
  tickerSearchFlexItem: {
    flexBasis: 300,
    flexGrow: 0,
    flexShrink: 0,
    border: `2px solid ${theme.palette.background.tab}`,
    borderTop: 0,
    borderBottom: `2px solid ${theme.palette.background.tab}`,
    // borderBottom: 'none',
    [theme.breakpoints.down('md')]: {
      flexBasis: 200
    },
  },
  tabsFlexItem: {
    flex: 1
  },
  tickerSearchCont: {
    paddingLeft: 32,
    width: '20%',
    paddingRight: 130
  },
  menuButton: {
    padding: '7px 9px',
    margin: '0 6px',
    color: `${theme.palette.text.icon} !important`,
  },
  buttonBox: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `2px solid ${theme.palette.background.tab}`
  }
}));


export default useTabsBarStyles;
