import {
  Box,
  Button,
  Typography,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';


const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 7,
    // backgroundColor: theme.palette.primary.marketing,
    background: `linear-gradient(to right, ${theme.palette.primary.marketing300}, 30%, ${theme.palette.primary.marketing500})`,
    '& p': {
      lineHeight: 1.3,
      marginTop: 7,
    }
  },
  icon: {
    color: theme.palette.text.primary,
    fontSize: 36,
  },
  iconCont: {
    width: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));


function MarketingModalCallout({
  className,
  Icon,
  children,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.root, className)}
      p={1}
      mt={1}
      mb={1}
      display="flex"
      justifyContent="center"
      {...rest}
    >
      <Box
        className={classes.iconCont}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Icon className={classes.icon} width={36} />
      </Box>
      <Box flex="1" p={.5}>
        {children}
      </Box>
    </Box>
  );
}


export default MarketingModalCallout;
