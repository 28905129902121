import { alpha } from '@material-ui/core';
import _cloneDeep from 'lodash/cloneDeep';
import _uniqueId from 'lodash/uniqueId';
import { getLeaves } from 'react-mosaic-component';
import shortUuid from 'short-uuid';
import {
  CB_ITEM_NAMES,
  FLAGS as NEWS_CAT_FLAGS
} from 'src/app/components/grid/topListNews/columns/newsConstants';
import { PREDEF_PREFIX } from 'src/redux/profileSettings/profileSettingsConfig';
import { LinkingIcon, NoLinkingIcon } from 'src/theme/EdgeIcons';


/** @typedef {import('src/app/slicedForm/mapping/mappingDirections').ProfileStruct} ProfileStruct */
/** @typedef {import('src/app/slicedForm/shared/reducers/profileTreeReducer').TreeItem} TreeItem */
/** @typedef {import('immer').Draft} Draft */
/** @typedef {import('./layoutActions').LayoutAction} LayoutAction */

/** 
 * A plain reducer, not drafted yet
 * @callback LayoutReducer
 * @param {object} state
 * @param {LayoutAction} action
 * @param {SchemaApi} schemaApi
 * @returns {undefined|object}
 */

/**
 * Immutably add { predefined: true } to all profiles
 * @param {object} profiles
 * @returns {object}
 */
export const addPredefinedKey = (profiles) => {
  return Object.entries(profiles).reduce((acc, [key, val]) => {
    acc[key] = { predefined: true, ...val };
    return acc;
  }, {});
}

export const makeAggNames = (...aggs) => aggs.map(agg => ({ ...agg, name: _uniqueId('agg_') }));

export const col = c => ({ column: c, type: 'column' });
export const val = v => ({ value: v, type: 'value' });


/**
 * @readonly
 * @enum {string} PROFILE_TYPES
 **/
export const PROFILE_TYPES = {
  FILTER: 'FILTER',
  COLUMN: 'COLUMN',
  WATCHLIST_ROWS: 'WATCHLIST_ROWS',
  KEY_STATS: 'KEY_STATS',
  AGGREGATE: 'AGGREGATE'
}


/**
 * Enum for component type ID's.
 * Global to all layouts. An individual layout will utilize a subset
 * of these. 
 * @readonly
 */
export const COMPONENT_TYPES = {
  SELECT: 'select',
  SCANNER: 'scanner',
  NEWS: 'news',
  KEYSTATS: 'keystats',
  HISTORICAL: 'historical',
  CHART: 'chart',
  WATCHLIST: 'watchlist',
  // These share so much in common with realtime, but the components are subtly different...
  // It turns out its just easier to make new components, rather than deal with divergence.
  HISTORY_FILTERS: 'history_filters',
  HISTORY_RECORDS: 'history_records',
  HISTORY_MARKETSTATS: 'history_marketstats',

  // Charting. These are components, but all are selected from a single CHART_SELECT panel component,
  // and should not be selected from the regular SELET panel.
  HISTORY_CHART_SELECT: 'history_chart_select',
  HISTORY_TIMESERIES: 'history_timeseries', // SHOULD BE history_chart_timeseries
  HISTORY_CHART_TIMESERIES_TABLE: 'history_chart_timeseries_table',
  HISTORY_CHART_SCATTER_PLOT: 'history_chart_scatter_plot',
  HISTORY_CHART_CATEGORICAL_BAR: 'history_chart_categorical_bar',
  HISTORY_CHART_MULTI_METRIC_BAR: 'history_chart_multi_metric_bar',
};


/**
 * Holds information about our various User profiles, and their default values
 *
 * @typedef {Object} ProfileConfigItem
 * @property {string} metaKey - The name of the config object. HISTORY_FILTERS.
 * @property {string} listKey - the name of the key in Profile state where the list of profiles is stored
 * @property {string} idKey   - the name of the key in Component state where the individual ID is stored
 * @property {Object[]} predefinedProfiles
 * @property {string} defaultProfileId
 * @property {function} selectReferencedProfiles - Selector to get a mapping of profileID -> [{ layout, component }] for all profiles under this config item.
 * @property {boolean} [nonLayoutState] - Don't process these profiles in layoutActions. Don't save to dynamo, don't add predefineds, don't strip predefineds.
 */


/**
 * Maps COMPONENT_TYPES to relevant React components and render options
 *
 * @typedef {Object} ComponentMapItem
 * @property {string} title - Title of the component
 * @property {React.Component} Component - React component to render
 * @property {React.Component} [Icon] - Icon to display in the component select panel
 * @property {boolean} [noLinkAllowed] - Hide the linking dropdown menu.
 */

/**
 * @typedef {object} MosaicNode
 * @property {string|MosaicNode} first
 * @property {string|MosaicNode} second
 * @property {'row'|'column'} direction
 * @property {number} splitPercentage
 */

/**
 * @typedef {object} Layout
 * @property {string} name
 * @property {MosaicNode} currentNode
 * @property {boolean} [predefined]
 */

/**
 * Response from create/copy layout. May contain profilesByComponent.
 * @typedef {object} CreateLayoutPayload
 * @property {Layout} layout 
 * @property {{string: object}} components
 * @property {{string: object}} [linksByLayout] - color: value
 * property {{string: {string: object}}} [profilesByComponent] - componentId: {profileIdKey: profile}
 */


/**
 * Signifies profiles that share a particular expressionId
 * @typedef {object} ExpressionReferenceBB
 * @property {string} profileConfigLabel
 * @property {string} profileListKey
 * @property {string} profileId
 * @property {string} profileName
 */


/**
 * Signifies a referenence to an entity. Like an Expression or Profile.
 * @typedef {object} LayoutEntityReference
 * @property {string} groupLabel
 * @property {string} groupId
 * @property {string} itemLabel
 * @property {string} itemId
 * @property {boolean} [isCurrent] - Signifies this is the item currently being edited.
 * @property {string[]} [groupPath]
 * @property {function} [onClick] - Maybe in the future we have a "show this" callback
 */


/**
 * In order to reuse components, each component needs to be able to change the PROFILE_CONFIG
 * its selecting data from. This will be provided by COMPONENT_MAP and useComponent() context.
 *
 * The particular names of props will change per component. Chart isn't being supplied Watchlist 
 * for example.
 *
 * History Page News: history.PROFILE_CONFIG.HISTORY_FILTERS
 * Scanner Page News: scanner.PROFILE_CONFIG.SCANNER_FILTERS
 *
 * @typedef {object} ProfileConfigMeta
 * @property {ProfileConfigItem} [columnProfileConfig]
 * @property {ProfileConfigItem} [filterProfileConfig]
 * @property {ProfileConfigItem} [aggregateProfileConfig]
 * @property {ProfileConfigItem} [watchlistProfileConfig]
 * @property {ProfileConfigItem} [keystatsProfileConfig]
 * @property {ProfileConfigItem} [timeseriesProfileConfig]
 */



export const defaultNewsCategories = {
  [CB_ITEM_NAMES.PRESS_RELEASE]: { flag: NEWS_CAT_FLAGS.ALL, data: [] },
  [CB_ITEM_NAMES.OTHER_NEWS]: { flag: NEWS_CAT_FLAGS.ALL, data: [] },
  [CB_ITEM_NAMES.TOPICS]: { flag: NEWS_CAT_FLAGS.ALL, data: [] },
  [CB_ITEM_NAMES.SEC_CATEGORIES]: { flag: NEWS_CAT_FLAGS.ALL, data: [] },
};

export const newsOnlyNewsCategories = {
  [CB_ITEM_NAMES.PRESS_RELEASE]: { flag: NEWS_CAT_FLAGS.ALL, data: [] },
  [CB_ITEM_NAMES.OTHER_NEWS]: { flag: NEWS_CAT_FLAGS.ALL, data: [] },
  [CB_ITEM_NAMES.TOPICS]: { flag: NEWS_CAT_FLAGS.ALL, data: [] },
  [CB_ITEM_NAMES.SEC_CATEGORIES]: { flag: NEWS_CAT_FLAGS.NONE, data: [] },
};

export const secOnlyNewsCategories = {
  [CB_ITEM_NAMES.PRESS_RELEASE]: { flag: NEWS_CAT_FLAGS.NONE, data: [] },
  [CB_ITEM_NAMES.OTHER_NEWS]: { flag: NEWS_CAT_FLAGS.NONE, data: [] },
  [CB_ITEM_NAMES.TOPICS]: { flag: NEWS_CAT_FLAGS.NONE, data: [] },
  [CB_ITEM_NAMES.SEC_CATEGORIES]: { flag: NEWS_CAT_FLAGS.ALL, data: [] },
};


/**
 * @readonly
 */
export const CHART_DATE_INITIALIZATION_TYPES = {
  /** Use the date that is currently selected for globally Linked values */
  LINKED_DATE: 'linked_date',
  /** Use today (most recent trading day) */
  TODAY: 'today',
  /** Use the DatePicker date that the user defined. */
  SELECT_DATE: 'select_date'
};


/** keyword for special watchlist drag drop ordering **/
export const WATCHLIST_MANUAL_ORDER = 'assigned'


/**
 * @typedef {Object} ColorItem
 * @property {string} color
 * @property {string} title
 * @property {React.Component} Icon
 */


/** @type {Object.<string, ColorItem>} */
export const LINK_COLORS = {
  none: {
    color: alpha('#E6E8E5', .4),
    title: 'None',
    name: 'none',
    Icon: NoLinkingIcon
  },
  white: {
    color: '#E6E8E5',
    title: 'On Change',
    name: 'white',
    Icon: LinkingIcon
  },
  blue: {
    color: '#0FBBE0',
    title: 'Blue',
    name: 'blue',
    Icon: LinkingIcon
  },
  green: {
    color: '#1ED26F',
    title: 'Green',
    name: 'green',
    Icon: LinkingIcon
  },
  yellow: {
    color: '#FFE500',
    title: 'Yellow',
    name: 'yellow',
    Icon: LinkingIcon
  },
  red: {
    color: '#EF5350',
    title: 'Red',
    name: 'red',
    Icon: LinkingIcon
  }
};


export const PREDEF_FOLDER_ID = 'fld_predef__root';

const ROOT_TREE_ITEMS = {
  'root': {
    label: 'root',
    children: []
  },
}


/**
 * Deep-clone an object and remove its `predefined` property if it exists.
 *
 * @param {object} obj - The object to clone.
 * @returns {object|undefined} - A cloned object without `predefined`, or undefined if obj was falsy.
 */
function cloneAndStripPredefined(obj) {
  if (!obj) return undefined;
  const clone = _cloneDeep(obj);
  delete clone.predefined;
  return clone;
}



/**
 * Immutably return a new version of layout.currentNode that has its ID's replaced, 
 * specified by idMap.
 *
 * @param {object} node - A node in the layout tree.
 * @param {function} remapId - A callback that takes an old ID and returns a new ID (and handles side effects).
 * @returns {object} - A brand new node with IDs replaced, leaving the original node untouched.
 */
function transformCurrentNode(node, idMapping) {
  if (!node || typeof node !== 'object') {
    return node;
  }

  const newNode = { ...node };

  for (const key of ['first', 'second']) {
    if (!Object.prototype.hasOwnProperty.call(newNode, key)) continue;

    const value = newNode[key];

    if (typeof value === 'string') {
      newNode[key] = idMapping[value];
    }
    else if (typeof value === 'object') {
      newNode[key] = transformCurrentNode(value, idMapping);
    }
  }

  return newNode;
}



/**
 * Abstract class for creating and manipulating Layout Schema.
 * @class {SchemaApi}
 **/
export class SchemaApi {

  constructor({
    profileConfig,
    componentMap,
    layoutTemplates,
    scopedComponents = [],
  }) {

    /** @type {Object.<string, ProfileConfigItem>} */
    this.profileConfig = profileConfig;
    /** @type {Object.<string, ComponentMapItem>} */
    this.componentMap = componentMap;
    /** @type {object[]} */
    this.layoutTemplates = layoutTemplates;

    /** 
     * @type {string[]} 
     * Special component types that follow these rules:
     * 1) There must only be a single component of this type per layout
     * 2) It cannot be created or deleted by the user
     * 3) A reference to its ID will be saved under layout.scopedComponentIds[componentType] = 'id'
     *
     * This allows other components to get a reference to it. Like FilterTopBar on history page.
     */
    this.scopedComponents = scopedComponents;

    if (this.constructor === SchemaApi) {
      throw new Error('Abstract classes can\'t be instantiated.');
    }
    if (!this.profileConfig) {
      throw new Error('Profile Config must be defined on subclass');
    }
    if (!this.layoutTemplates) {
      throw new Error('Layout Templates must be defined on subclass');
    }
    if (!this.componentMap) {
      throw new Error('Component Map must be defined on subclass');
    }
  }


  /**
   * Helper function to construct profile keys on components. Assumes profileConfig
   * is sturctured correctly with idKey and defaultProfileId. It just does the following:
   *
   * component: {
   *   [this.componentMap[componentType].profileConfigMeta.idKey]: this.componentMap[componentType].profileConfigMeta.defaultProfileId
   * }
   * 
   * @throws {Error}
   * @param {string} componentType
   * @param {Array<keyof ProfileConfigMeta>} profileConfigMetaKeys
   * @returns {object}
   */
  addDefaultComponentProfileIds = (componentType, profileConfigMetaKeys) => {
    return profileConfigMetaKeys.reduce((acc, key) => {
      const cfg = this.componentMap?.[componentType]?.profileConfigMeta?.[key];

      if (!cfg) {
        throw new Error(
          `Requested profileConfigMeta key "${key}" for componentType "${componentType}" that does not exist on this.componentMap. ` +
          'This is bad configuration, please check your redux <namespace>/schema.js file'
        )
      }

      if (!('defaultProfileId' in cfg)) {
        console.log(cfg);
        throw new Error(
          `profileConfigItem object for ${componentType}, ${key} has no "defaultProfileId" prop. ` +
          'Either fix your schema.js config, or manually set this in createComponent instead of calling addComponentProfileIds()'
        )
      }
      if (!cfg.idKey) {
        console.log(cfg);
        throw new Error(
          `profileConfigItem object for ${componentType}, ${key} has no "idKey" prop. ` +
          'This is bad configuration, please check your redux <namespace>/schema.js file'
        )
      }
      acc[cfg.idKey] = cfg.defaultProfileId;
      return acc;
    }, {});
  }

  /**
   * Identify the ProfileSettings object based on provided listKey
   * @param {string} profileListKey
   * @returns {ProfileConfigItem|undefined}
   */
  getProfileConfigItem = (profileListKey) => {
    return Object.values(this.profileConfig).find(c => c.listKey === profileListKey);
  }


  /**
   * Create an individual component, protects from accidental scoped components.
   * @param {keyof COMPONENT_TYPES} type
   * @param {object} overrides
   * @returns {{component: object, componentProfile: object}}
   **/
  createComponent = (type, overrides) => {
    if (this.scopedComponents.includes(type)) {
      throw new Error(`Users cannot create scoped components. type = ${type}`)
    }

    return this._createComponent(type, overrides);
  }

  /**
   * Create an individual component, internal
   * @param {keyof COMPONENT_TYPES} type
   * @param {object} overrides
   */
  _createComponent = (type, overrides = {}) => {
    throw new Error('Method _createComponent must be implemented');
  }


  /**
   * Generate short UUIDs for components and layouts
   * @returns {string}
   */
  generateId = () => {
    return shortUuid.generate();
  }

  /**
   * Create a new layout
   * @param {string} [templateId] - The name of a predefined template to use
   * @param {object} overrides
   * @returns {CreateLayoutPayload}
   */
  createLayout = (templateId = null, overrides = {}) => {
    throw new Error('Method createDefaultLayout must be implemented');
  }


  /**
   * Copy an existing layout, and swap ID's to avoid collisions
   * @param {string} oldLayoutId
   * @param {string} newName
   * @param {object} state - Current state of slice from getState()[namespace]
   * @returns {CreateLayoutPayload}
   */
  copyLayout = (oldLayoutId, newName, state) => {
    if (!state.layouts[oldLayoutId] || !state?.components) {
      throw new Error('Cannot copy layout. Missing oldLayout or state.components.');
    }

    const layout = cloneAndStripPredefined(state.layouts[oldLayoutId]);
    layout.name = newName;

    const linksByLayout = _cloneDeep(state?.linksByLayout?.[oldLayoutId]);

    const leafIds = getLeaves(layout.currentNode);

    const idMapping = {}, newComponents = {}

    // Build mapping and clone getLeaves objects
    leafIds.forEach(oldId => {
      const newId = this.generateId();
      idMapping[oldId] = newId;

      newComponents[newId] = cloneAndStripPredefined(state.components[oldId]);

    });

    // Do the same for scopedComponents, which don't live inside getLeaves. Update scopedComponentIds.
    Object.entries(layout.scopedComponentIds).forEach(([componentType, oldId]) => {
      const newId = this.generateId();
      idMapping[oldId] = newId; // Not necissary technically, because idMap only cares about currentNode.

      layout.scopedComponentIds[componentType] = newId;

      newComponents[newId] = cloneAndStripPredefined(state.components[oldId]);
    });

    // Recursively update the layout’s tree, remapping old IDs to new IDs and cloning data.
    layout.currentNode = transformCurrentNode(
      layout.currentNode,
      idMapping,
    );

    const payload = {
      layout,
      components: newComponents,
      ...(linksByLayout ? { linksByLayout } : {}),
    };

    return payload;
  }


  /**
  * Allow mutation of each profile in state.
  * @param {object} draft
  * @param {ProfileCallback} callback
  * @returns {void}
  **/
  forEachProfile = (draft, callback) => {
    Object.keys(draft.profileMap).forEach(profileType => {
      const cfg = Object.values(this.profileConfig).find(c => c.listKey === profileType) || {};
      Object.keys(draft.profiles[profileType]).forEach(profileId => {
        callback({
          profileId,
          profile: draft.profileMap[profileType][profileId],
          cfg,
        });
      })
    })
  }


  /**
   * Allow mutation of each component in state.
   * @param {object} draft
   * @param {ComponentCallback} callback
   * @returns {void}
   **/
  forEachComponent = (draft, callback) => {
    Object.keys(draft.components).forEach(componentId => {
      // TODO: get Layout/Namespace reference?
      callback({
        componentId,
        component: draft.components[componentId]
      });
    });
  }


  /**
   * This is called on the reducer's initialState. It will be overriden if the user has data.
   * Given the default profiles:
   *  1) Put them into the Tree item state, under a predefined folder
   *  2) Generate the openState, where all of them are opened.
   *
   * TODO: We cannot handle non-predefined initial profiles yet.
   * They have to be added to the reducer.profileMap, and added here.
   *
   * @param {string} profileListKey
   * @returns {{items: {string: TreeItem}, openState: string[]}}
   */
  generateInitialProfileTree = (profileListKey) => {
    const cfg = this.getProfileConfigItem(profileListKey);
    const predefItems = this.generateDefaultPredefinedFolder(cfg.predefinedProfiles);

    let items = this.mergePredefinedTreeItemsIntoRoot(ROOT_TREE_ITEMS, predefItems);

    const labelMap = Object.entries(cfg.predefinedProfiles).reduce((acc, [id, profOrLay]) => {
      acc[id] = profOrLay.name;
      return acc;
    }, {});

    items = this.addIdAndLabelToTreeItems(
      items,
      labelMap
    );

    const openState = Object.entries(items).reduce((acc, [id, obj]) => {
      if (obj.isFolder && id !== 'root') {
        acc.push(id);
      }
      return acc;
    }, []);

    return { items, openState };
  }


  /**
   * When adding predefined profiles to state, we must add Items too. If the programmer
   * does not specify a particular structure for these, then use this to create a flat
   * folder containing all predefined items.
   *
   * This folder does not contain ROOT, and must be merged later.
   *
   * @param {{string: ProfileStruct|Layout}} predefinedObjects
   * @returns {{string: TreeItem}}
   */
  generateDefaultPredefinedFolder = (
    predefinedObjects,
    label = 'Predefined Profiles'
  ) => {
    if (!predefinedObjects) return {};

    const baseItems = {
      [PREDEF_FOLDER_ID]: {
        label,
        isFolder: true,
        isPredefined: true,
        children: [],
      }
    }

    return Object.entries(predefinedObjects).reduce((acc, [id, obj]) => {
      if (!obj?.name || !id) {
        throw new Error('predefinedObject does not have correct structure.')
      }

      acc[id] = { isPredefined: obj.predefined, label: obj.name };
      baseItems[PREDEF_FOLDER_ID].children.push(id);

      return acc;
    }, baseItems);
  }


  /****** Below this handles Middleware marshalling and unmarshalling ******/


  /**
   * When initializing a user from the DB, we want to insert the Predefined folder into the user's Tree, at root.children[0]
   * @param {{string: TreeItem}} userItems - Tree items from the DB, or the initial state. Contains ROOT.
   * @param {{string: TreeItem}} predefinedItems - Folder structure for predefineds. Must contain at least 1 root folder with PREDEF_FOLDER_ID
   * @returns {{string: TreeItem}}
   */
  mergePredefinedTreeItemsIntoRoot = (
    userItems,
    predefinedItems = {},
  ) => {
    const rootItems = _cloneDeep({
      ...predefinedItems,
      ...userItems,
    });

    if (Object.keys(predefinedItems).length) {

      if (!predefinedItems[PREDEF_FOLDER_ID]) {
        throw new Error(`Cannot merge predefined items without a root PREDEF_FOLDER_ID folder. Ids: ${JSON.stringify(Object.keys(predefinedItems))}`);
      }

      rootItems['root'].children.unshift(PREDEF_FOLDER_ID);
    }

    return rootItems;
  }


  /**
   * When saving profile tree items, remove:
   * 1) root.children.indexOf(PREDEF_FOLDER_ID)
   * 2) PREDEF_FOLDER_ID
   * 3) All predefined profile items
   * @param {{string: TreeItem}} userItems
   * @returns {{string: TreeItem}}
   */
  removePredefinedTreeItemsFromRoot = (userItems) => {
    return Object.entries(userItems).reduce((acc, [id, item]) => {
      if (id === "root") {
        const children = item.children.filter(c => c !== PREDEF_FOLDER_ID);
        acc[id] = { ...item, children };
      } else if (id !== PREDEF_FOLDER_ID && !item.isPredefined) {
        acc[id] = item;
      }
      return acc;
    }, {});
  }


  /**
  * 1. Profiles/Layouts tree items aren't saved to db with 'label', since the Map object is soure of truth. Add them during seiralization.
  * 2. All items aren't saved with item.id prop, since the map [id]: item holds it. Add it here for easier manipulation on frontend.
  * @param {{string: TreeItem}} items
  * @param {{string: string}} labelMap - Source of truth, probably profileMap or layoutMap, etc.
  * @returns {{string: TreeItem}}
  */
  addIdAndLabelToTreeItems = (
    treeItems = {},
    labelMap = {},
  ) => {
    return Object.entries(treeItems).reduce((acc, [id, val]) => {
      // All items should be given ID
      let newVal = { ...val, id };

      if (id === 'root') {
        // root is special
        newVal.label = 'root';
      } else if (!val.isFolder) {
        // Non-folders need to be given label
        if (!labelMap?.[id] && id.startsWith(PREDEF_PREFIX)) {
          // So for new users, they won't have any profileMap profiles saved in DB. That means the differ will never run,
          // and thus we will not have labelMap items at this point in time. 
          //
          // This is really ugly, but it works because our defaultTreeItems inside the reducer initialState include the labels already. 
          // Then going forwrd, once the user has saved a profile, that profileType will work with labelMap correctly. 
          // But, this is quite bad. 
          // TODO: Gotta fix this somehow, feels like a time bomb. Initialization is a problem currently. 

          if (!id.startsWith(PREDEF_PREFIX)) {
            console.debug('nolabel', { acc, id, val, treeItems, labelMap })
          }
        } else {
          newVal.label = labelMap?.[id]
        }
      }
      return { ...acc, [id]: newVal };
    }, {});
  }


  /** 
   * Reverses the previous mapping before saving to DB.
   * @param {{string: TreeItem}} treeItems
   * @returns {{string: TreeItem}}
   */
  removeIdAndLabelFromTreeItems = (treeItems) => {
    return Object.entries(treeItems).reduce((acc, [id, val]) => {
      // Remove ID and label
      const { label, id: _, ...rest } = val;

      const newVal = { ...rest };

      // Keep label if folder only
      if (val.isFolder) {
        newVal.label = label;
      }

      acc[id] = newVal;
      return acc;
    }, {})
  }


}

