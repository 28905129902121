import {
  alpha,
  colors,
  Dialog,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';


const useStyles = makeStyles(theme => ({
  dialogRoot: {
    backgroundColor: props => props.blur ? alpha(colors.common.black) : 'transparent !important',
    backdropFilter: props => props.blur ? 'blur(2px) saturate(70%)' : 'none',
    '& .MuiDialog-paperScrollPaper': {
      maxWidth: 'calc(100% - 64px)',
      maxHeight: 'calc(100% - 64px)',
      width: props => props.width,
      height: props => props.height,
    }
  },
  modalSharpBorder: {
    '& .MuiDialog-paperScrollPaper': {
      borderRadius: 6,
      boxShadow: theme.shadows[4],
      border: `2px solid ${theme.palette.background.lightBorder}`
    }
  }
}));


/**
 * Modal bound relative to parent, not necissarily full-screen
 * Parent must have position: relative!
 *
 * @component
 */
function ContainedModal({
  className,
  open,
  width,
  height,
  sharpBorder,
  blur,
  onClose,
  disablePortal,
  children
}) {
  const classes = useStyles({ width, height, blur });

  return (
    <Dialog
      className={clsx(className, classes.dialogRoot, sharpBorder && classes.modalSharpBorder)}
      maxWidth="md"
      disableAutoFocus
      disableEnforceFocus
      disableScrollLock
      disablePortal={disablePortal}
      fullWidth
      hideBackdrop={blur}
      transitionDuration={0}
      style={{ position: 'absolute' }}
      open={open}
      onClose={onClose}
    >
      {children}
    </Dialog>
  );
}


ContainedModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  opacity: PropTypes.number,
  children: PropTypes.any,
  disablePortal: PropTypes.bool,
};


ContainedModal.defaultProps = {
  open: false,
  width: 450,
  opacity: 0,
  height: 'unset',
  disablePortal: true
};

export default ContainedModal;



