import _merge from 'lodash/merge';
import {
  alpha,
  darken,
  lighten,
  createTheme as createMuiTheme
} from '@material-ui/core';
import typography from './typography';
import { strongShadows } from './shadows';
import insertBaselineCssVariablesFromTheme from './insertBaselineCssVariablesFromTheme';
import { THEMES } from '../constants';
import { AGGREGATES } from 'src/app/slicedForm/FilterForm/definitions/inputEnums';



const planTagColors = {
  'blue': '#A5B4FC',
  'yellow': '#FFD700',
  'orange': '#FB790B',
  'orange2': '#FF773D',
}

const planTagColor = planTagColors['blue'];


export const STATIC_ELEMENT_HEIGHTS = {
  panelHeader: 32,
  tableHeader: 26,
  tickerTitleBar: 88,
  tickerTabs: 38,
  paginationBar: 33,
  tableCell: 30.16,
  dashboardChart: 556,
  historyChart: 514
}

const GRID_SIZE = 5;
const DATA_FONT_SIZE = 13;

const baseConfig = {
  direction: 'ltr',
  typography,
  drawerWidth: 48,
  mobileTopBarHeight: 40,
  topBarHeight: 48, // Just a reference, not hard coded
  grid: {
    containerHorizontalSpacing: 1.6,
    containerVerticalSpacing: 1,
    gutterSpacing: 1,
    borderRadius: 4,
    agGridSize: GRID_SIZE,
  },
  fontSize: {
    icon: '1.5rem',     // 24px
    title: '1.0625rem', // 17px
    tab: '0.9375rem',   // 15px
    button: '.875rem',  // 14px
    largerLabel: '.875rem', // 14px
    label: '.8125rem',  // 13px
    // data: '.75rem',     // 12px
    // data: '.8125rem',     // 13px
    data: `${DATA_FONT_SIZE}px`

  },
  props: {},
  overrides: {
    MuiCssBaseline: {
      '@global': {
        ':root': {
          '--fontSize-icon': '1.5rem',
          '--fontSize-title': '1.0625rem',
          '--fontSize-tab': '0.9375rem',
          '--fontSize-button': '.875rem',
          '--fontSize-label': '.8125rem',
          // '--fontSize-data': '.8125rem',
          '--fontSize-data': `${DATA_FONT_SIZE}px`,
          '--borderRadius': '4px',
          '--ettFontFamily': typography.fontFamily,
          '--ettMonoFontFamily': typography.monoFontFamily,
          '--scrollbarWidth': '8px',
          '--scrollbarHeight': '8px',
          '--spacing': '4px',
          '--ett-ag-grid-size': `${GRID_SIZE}px`
        },
        '.tv_chart, .tv_chart iframe': {
          backgroundColor: 'var(--palette-background-tvChart) !important'
        },
        '.floating-icon-cont': {
          height: '16px',
          width: '22px',
          flexShrink: 0,
          flexBasis: '22px',
          position: 'relative',
          overflow: 'visible'
        },
        '.floating-icon-cont.--small': {
          width: '12px',
          flexBasis: '12px'
        },
        '.expression-icon-list-item': {
          fontSize: '18px !important',
          color: 'var(--palette-text-secondary)',
          marginLeft: '-4px',
          marginRight: '1px'
        },
        '.floating-icon-cont .expression-icon-list-item': {
          position: 'absolute',
          top: 'calc(50% - 1px)',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        },
        '.ett-mosaic-scrollbar::-webkit-scrollbar': {
          width: 'var(--scrollbarWidth)',
          height: 'var(--scrollbarHeight)'
        },
        '.modal-horizontal-scrollbar-sm::-webkit-scrollbar': {
          width: 'var(--scrollbarWidth)',
          height: 'var(--scrollbarHeight)'
        },
        '::-webkit-scrollbar': {
          width: 'var(--scrollbarWidth)',
          height: 'var(--scrollbarHeight)',
          backgroundColor: 'var(--palette-scrollbar-background)',
        },
        '::-webkit-scrollbar-track': {
          background: 'var(--palette-scrollbar-background)'
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: 'var(--palette-scrollbar-thumbInactive)',
          borderRadius: '6px',
          border: '3px solid var(--palette-scrollbar-background)'
        },
        '::-webkit-scrollbar-thumb:hover': {
          backgroundColor: 'var(--palette-scrollbar-thumbHover)'
        },
        '::-webkit-scrollbar-corner': {
          backgroundColor: 'var(--palette-scrollbar-background)'
        }
      }
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    },
    MuiTab: {
      root: {
        textTransform: 'capitalize',
        fontSize: '0.95rem'
      }
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: STATIC_ELEMENT_HEIGHTS.paginationBar
      }
    }
  }
};



const edgeColors = {
  dark: {
    surfaces: {
      paper: {
        100: '#384268',
        200: '#2D3552',
        300: '#272F4A',
        400: '#232B44',
        500: '#20263B',
        550: '#1e2337',
        600: '#1c2133',
      },
      paperAlt: {
        300: '#363D53',
        500: '#252A3E',
      },
      default: {
        400: '#171A25',
        500: '#141823'
      },
    },
    primary: {
      400: '#8097FC',
      500: '#677FE2',
    },
    secondary: {
      100: '#A9C5EB',
      300: lighten('#36465F', .15),
      500: '#36465F'
    },
    text: {
      primary: "#F2F4F7",
      secondary: '#AEBACF',
      icon: '#D9D9D9',
      positive: '#29A683',
      negative: '#E76869',
      inverted: '#000000',
      // These need to be updated
      label: '#ADADAD',
      gray: '#ADADAD',
      planTag: planTagColor,
      orange: planTagColors['orange']
    },
    aggregates: {
      [AGGREGATES.CNT]: '#96989B',
      [AGGREGATES.SUM]: '#C48744',
      [AGGREGATES.MIN]: '#C85351',
      [AGGREGATES.MAX]: '#499958',
      [AGGREGATES.SD_POP]: '#7D5BAC',
      [AGGREGATES.AVG]: '#447ABA',
      [AGGREGATES.MED]: '#B760A4',
    },
  },
}

// Object.entries(edgeColors.dark.surfaces.paper).forEach(([key, value]) => {
//   edgeColors.dark.surfaces.paper[key] = darken(value, .1);
// })


const edgeTheme = {
  name: THEMES.ETT_DARK,
  focus: {
    outline: {
      outline: 'none',
      boxShadow: `0 0 0 2px ${alpha(edgeColors.dark.primary[500], .4)} inset`
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        'color-scheme': 'dark',
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: edgeColors.dark.text.secondary
        },
      }
    }
  },
  palette: {
    type: 'dark',
    action: {
      // active: edgeColors.dark.secondary[500],
      active: 'rgba(255, 255, 255, 0.54)',
      highlight: opacity => alpha('#FFFFFF', opacity),
      lowlight: opacity => alpha('#000000', opacity),
      hover: 'rgba(255, 255, 255, 0.05)',
      hover600: 'rgba(255, 255, 255, 0.07)',
      paperHover600: '#303752', // simulates .07 hover over paper
      selected: 'rgba(255, 255, 255, 0.10)',
      disabled: 'rgba(255, 255, 255, 0.26)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      focus: 'rgba(255, 255, 255, 0.12)'
    },
    primary: {
      main: edgeColors.dark.primary[500],
      marketing500: '#4F46E5',
      marketing300: '#9333ea',
    },
    secondary: {
      main: edgeColors.dark.secondary[500],
      // light: edgeColors.dark.secondary[300],
    },
    default: {
      main: edgeColors.dark.surfaces.paper[200],
      contrastText: edgeColors.dark.text.primary
    },
    scrollbar: {
      background: 'transparent',
      thumbInactive: lighten(edgeColors.dark.secondary[500], .2),
      thumbHover: lighten(edgeColors.dark.secondary[500], .55),
      // thumbInactive: 'rgba(230, 229, 232, .4)',
      // thumbHover: 'rgba(230, 229, 232, .7)',
    },
    background: {
      default: edgeColors.dark.surfaces.default[500],
      paper: edgeColors.dark.surfaces.paper[300],
      c: {
        ...edgeColors.dark.surfaces,
      },
      // mosaicPanelGradient: `linear-gradient(180deg, rgba(128, 151, 252, 0.16) 0%, rgba(128, 151, 252, 0.04) 50%, rgba(128, 151, 252, 0.08) 100%)`,
      border: '1px solid rgba(128, 151, 252, 0.32)',
      dark: edgeColors.dark.surfaces.default[500],
      paperAlt: edgeColors.dark.surfaces.paperAlt[500],
      paperAlt_darker1: darken(edgeColors.dark.surfaces.paperAlt[500], .10),
      paperAlt_highlight: lighten(edgeColors.dark.surfaces.paper[200], .10),
      paperAlt_highlightTwo: '#343853',
      paperAlt_lowlight: lighten('#2E333F', .03),
      marketingModalDark: edgeColors.dark.surfaces.default[500],
      marketingModalLight: edgeColors.dark.surfaces.paper[500],
      tab: edgeColors.dark.surfaces.paper[500],
      fixedTableHeader: edgeColors.dark.surfaces.paper[100],
      fixedTableHeaderBorder: 'transparent',
      lightBorder: lighten(edgeColors.dark.surfaces.paper[300], .10),
      lightBorderTwo: '#515151',
      lightBorderThree: '#414141',
      gridBorder: '#4B5476',
      // panelHeader: 'transparent',
      panelHeader: edgeColors.dark.surfaces.paper[600],
      panelBorder: edgeColors.dark.surfaces.paper[300],
      negative: edgeColors.dark.text.negative,
      positive: edgeColors.dark.text.positive,
      tvChart: edgeColors.dark.surfaces.default[400],
      ssr: '#FF6D6C',
      darkBlue: edgeColors.dark.surfaces.paper[500],
      agFinancialNegative: '#F23645',
      agFinancialPositive: '#089981',
      backdrop: 'rgba(0,0,0, .35)',
    },
    historical: {
      icon: '#DFCF00',
      border: '#A59900',
    },
    alarms: {
      less: '#EF5350',
      between: '#9CB537',
      greater: '#26A69A'
    },
    snackbar: {
      error: '#84323A',
      success: '#1E6E45',
    },
    aggregates: {
      ...edgeColors.dark.aggregates
    },
    text: {
      ...edgeColors.dark.text,
    },
    typography,
    shadows: strongShadows
  }
}


const themeConfigs = [
  edgeTheme,
  {
    name: THEMES.ONE_DARK,
    focus: {
      outline: {
        outline: 'none',
        boxShadow: `0 0 0 2px ${alpha('#8a85ff', .4)} inset`
      }
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          'color-scheme': 'dark',
        }
      },

    },
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        highlight: opacity => alpha('#FFFFFF', opacity),
        lowlight: opacity => alpha('#000000', opacity),
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        historyFilterSelected: '#22293b',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      scrollbar: {
        background: 'transdarent',
        thumb: 'rgba(230, 229, 232, .7)',
        thumbInactive: 'rgba(230, 229, 232, .4)',
      },
      background: {
        default: '#282C34',
        light: '#2d313b',
        dark: '#1C2531',
        paper: '#293142',
        paperAlt: '#222B3E',
        paperAlt_darker1: '#242A37',
        paperAlt_highlight: '#3B4150',
        paperAlt_highlightTwo: '#343853',
        paperAlt_lowlight: lighten('#2E333F', .03),
        financialsGridRowGroup: '#1D2534',
        marketingModalDark: '#111827',
        marketingModalLight: '#1F2937',
        tab: '#1c1c1c',
        fixedTableHeader: '#444964',
        fixedTableHeaderBorder: '#7F7F7F',
        lightBorder: '#363D48',
        lightBorderTwo: '#515151',
        lightBorderThree: '#414141',
        panelHeader: '#181F2A',
        panelBorder: '#2A2E36',
        negative: '#EF5350',
        positive: '#26A69A',
        tvChart: '#14151a',
        ssr: '#FF6D6C',
        darkBlue: '#1f2739',
        agFinancialNegative: '#F23645',
        agFinancialPositive: '#089981',
        // sparklineNegative: "#873B2A",
        // sparklinePositive: "#246E4C",
        backdrop: 'rgba(0,0,0, .35)'
      },
      historical: {
        icon: '#DFCF00',
        border: '#A59900',
      },
      primary: {
        main: '#8a85ff',
        darker: '#6131FF',
        marketing500: '#4F46E5',
        marketing300: '#9333ea',
        opacity100: alpha('#8a85ff', .04),
      },
      secondary: {
        main: '#8a85ff',
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb',
        negative: '#f75419',
        positive: '#1ED26F',
        icon: '#E6E8E5',
        label: '#777A82',
        gray: '#ADADAD',
        planTag: planTagColor,
        orange: planTagColors['orange']
      },
      chart: {
        gridLines: '#42464D'
      },
      alarms: {
        less: '#EF5350',
        between: '#9CB537',
        greater: '#26A69A'
      },
      snackbar: {
        error: '#84323A',
        success: '#1E6E45',
      },
      aggregates: {
        [AGGREGATES.CNT]: '#B1B8C5',
        [AGGREGATES.SUM]: '#E5C3F6',
        [AGGREGATES.MIN]: '#F9C1C1',
        [AGGREGATES.MAX]: '#D2F1BF',
        [AGGREGATES.SD_POP]: '#7BBCB1',
        [AGGREGATES.AVG]: '#A4D2FE',
        [AGGREGATES.MED]: '#EDE2BB',
      }
    },
    shadows: strongShadows,
  }
];

export function createTheme(settings = {}) {
  let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  // themeConfig = insertBaselineCssVariablesFromTheme(themeConfig);

  const theme = createMuiTheme(
    _merge(
      {},
      baseConfig,
      themeConfig,
      { direction: settings.direction }
    )
  );

  console.log('THEME', theme);

  return insertBaselineCssVariablesFromTheme(theme);
}
