import { argHasSpecificExpression, deleteEntity, filterOrAggNodeArgContains, TreeNode } from "src/app/slicedForm/mapping/profileTreeManipulation";
import { PREDEF_PREFIX } from "src/redux/profileSettings/profileSettingsConfig";
import { PROFILE_TYPES } from "./layoutSchema";


/** @typedef {import('./layoutSchema.js').MosaicNode} MosaicNode */

export const intIdExists = (id) => id || id === 0;


export const isPredefined = profile => {
  return profile && (profile.predefined || profile?.id?.startsWith(PREDEF_PREFIX));
};


/**
 * Remove falsey values from an object. No recursion.
 * @param {object} draft
 * @param {string[]} keys
 * @returns {object}
 */
export const clearFalsey = (obj, keys) => {
  keys.forEach(key => {
    if (!obj[key]) {
      delete obj[key];
    }
  });
  return obj;
}


/** 
 * @param {object} nextProfiles
 * @param {object} oldProfiles
 * @returns {string[]} - The deleted IDs.
 **/
export const getRemovedObjetKeys = (nextProfiles, oldProfiles) => {
  const newProfileIds = Object.keys(nextProfiles);
  const oldProfileIds = Object.keys(oldProfiles);
  return oldProfileIds.filter(x => !newProfileIds.includes(x));
}

/**
 * @param {object[]} array
 * @param {string} idKey
 * @returns {[object, string[]]}
 **/
export const arrayToMapAndOrder = (array, idKey = 'id') => {
  const order = [];
  const map = array.reduce((acc, curr) => {
    order.push(curr[idKey]);
    return { ...acc, [curr[idKey]]: curr };
  }, {});

  return [map, order];
}



/**
 * Remove references of deleted expressions from column profiles.
 * @param {object} profile - draft
 * @param {string[]} removedProfileIds
 * @returns {boolean} - True if a change was made
 **/
export const deleteExpressionsFromColumns = (profile, removedExpressionIds, cfg) => {
  if (!profile.columns || !Array.isArray(profile.columns)) {
    return false;
  }
  if (isPredefined(profile)) {
    return false;
  }

  const filteredColumns = profile.columns.filter(col => !removedExpressionIds.includes(col.column));
  if (filteredColumns.length >= profile.columns.length) {
    return false;
  }

  profile.columns = filteredColumns;
  return true;
};


/**
 * Remove references of deleted expressions from filters or aggregates.
 * @param {object} profile - draft
 * @param {string[]} removedProfileIds
 **/
export const deleteExpressionsFromFilters = (profile, removedExpressionIds, cfg) => {
  // Recursive is hard. Use TreeNode to help.
  const { type } = cfg;
  const root = type === PROFILE_TYPES.FILTER ? 'filters' : 'aggregates';

  if (!profile[root]) {
    return false;
  }
  if (isPredefined(profile)) {
    return false;
  }

  const tree = TreeNode.fromProfile(profile[root]);

  if (!tree) {
    return false;
  }

  let hasChanged = false;

  tree.forEach(node => {
    if (filterOrAggNodeArgContains(node, arg => argHasSpecificExpression(arg, removedExpressionIds))) {
      deleteEntity(node)
      hasChanged = true;
    }
  });

  if (hasChanged) {
    profile[root] = tree.toProfile();
    return true;
  }

  return false;
};



/**
 * Checks if anything within the nodes have changed,
 * ignoring splitPercentage.
 * @param {MosaicNode} prev
 * @param {MosaicNode} next
 * @returns {boolean}
 */
export const checkCurrentNodeIsEqual = (prev, next) => {
  if (typeof prev === 'string' && typeof next === 'string') {
    return prev === next;
  }

  const isPrevObj = prev && typeof prev === 'object';
  const isNextObj = next && typeof next === 'object';
  if (typeof prev === 'string' && isNextObj) {
    return false;
  }
  if (typeof next === 'string' && isPrevObj) {
    return false;
  }

  if (isPrevObj && isNextObj) {
    if (prev.direction !== next.direction) {
      return false;
    }
    const sameFirst = checkCurrentNodeIsEqual(prev.first, next.first);
    if (!sameFirst) return false;

    const sameSecond = checkCurrentNodeIsEqual(prev.second, next.second);
    if (!sameSecond) return false;

    return true;
  }

  return false;
}
