import React from 'react';
import clsx from 'clsx';
import {
  Typography,
  makeStyles,
  ListItemIcon,
  ListItemSecondaryAction, Box,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    '--webkit-box-pack': 'center',
    '--webkit-box-align': 'center',
    '& .MuiIconButton-root': {
      padding: 3,
      borderRadius: theme.grid.borderRadius,
      '&:focus-visible': {
        ...theme.focus.outline
      }
    },
    '&.MuiListItemIcon-root': {
      minWidth: 26
    },
    '& .MuiCheckbox-root svg': {
      fontSize: 21
    },
    '& .dragHandle': {
      padding: 3
    },
    '& .dragHandle svg': {
      fontSize: 19
    },
    '& .MuiSvgIcon-root': {
      minWidth: 10,
      '&.lockIcon': {
        fontSize: 15
      },
      '&.editIcon': {

      },
    },
    '& .trashIcon': {
      width: 20
    }
  },
  leading: {
    marginRight: 6,
  },
  trailing: {
    marginLeft: 6,
    fontWeight: 'initial',
  }
}));


const LeadingItem = ({
  className,
  placeholder,
  children,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <ListItemIcon
      className={clsx(
        classes.root,
        classes.leading,
        className,
        'ActionList-Leading',
      )}
      {...rest}
    >
      {Boolean(placeholder) && (
        <div style={{ width: 24 }}></div>
      )}
      {children}
    </ListItemIcon>
  )
}



export const Leading = React.memo(LeadingItem)
Leading.displayName = 'ActionList.Leading'


const TrailingItem = ({
  className,
  placeholder,
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.root,
        classes.trailing,
        'ActionList-Trailing',
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

export const Trailing = React.memo(TrailingItem)
Trailing.displayName = 'ActionList.Trailing'


