const typography = {
  fontSize: 13,
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  monoFontFamily: '"Roboto Mono", "Roboto", "Helvetica", "Arial", sans-serif',
  body2: {
    fontSize: '.8125rem',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em'
  },
  h1: {
    fontWeight: 500,
    fontSize: 33,
    letterSpacing: '-0.24px'
  },
  h2: {
    fontWeight: 500,
    fontSize: 26,
    letterSpacing: '-0.22px'
  },
  h2Label: {
    fontWeight: 500,
    fontSize: 26,
    letterSpacing: '-0.22px',
    paddingTop: 12,
    paddingBottom: 12
  },
  h3: {
    fontWeight: 500,
    fontSize: 21,
    letterSpacing: '-0.05px'
  },
  h4: {
    fontWeight: 500,
    fontSize: 18,
    letterSpacing: '-0.05px'
  },
  h5: {
    fontWeight: 500,
    fontSize: 15,
    letterSpacing: '-0.04px'
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.04px'
  },
  overline: {
    fontWeight: 500
  }
};


export default typography;
